import {Component, ViewEncapsulation} from "@angular/core";
import {FormService} from "ngx-satoris";
import {EnrollService} from "src/app/shared/services/enroll.service";

@Component({
  selector: 'app-activation-step-form-user-local-inputs',
  template: `
<app-input type="date" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.DOC_EXPIRY_DATE" label="form.expiryDate" icon="calendar-days" class="mt_24"></app-input>
<app-input [formGrp]="enroll.form" [formCtrlName]="enroll.fc.BIRTH_PLACE" label="form.birthPlace" icon="location-arrow" class="mt_24"></app-input>
<app-input type="number" [formGrp]="enroll.form" [formCtrlName]="enroll.fc.HEIGHT" label="form.height" icon="ruler-vertical" class="mt_24"></app-input>
<app-input [formGrp]="enroll.form" [formCtrlName]="enroll.fc.PROFESSION" label="form.profession" icon="briefcase" class="mt_24"></app-input>  
`,
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepFormUserLocalInputsComponent {
  constructor(public forms: FormService,
              public enroll: EnrollService) {}
}