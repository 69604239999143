import {Component, NgZone, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../../shared/services/api.service';
import {EnrollService} from '../../../../../shared/services/enroll.service';
import {DevicesService, LangService, LoaderService} from 'ngx-satoris';
import {environment} from '../../../../../../environments/environment';
import {MRZType} from '../../../../../shared/models/enroll';
import getCountryISO2 from 'country-iso-3-to-2';
import {PrivacyScreen} from '@capacitor-community/privacy-screen';

declare const SatorisOcr: any;
declare const CameraPreview: any;
declare const cordova: any;

@Component({
  selector: 'app-activation-step-scan-mrz-scan',
  templateUrl: './activation-step-scan-mrz-scan.component.html',
  styleUrls: ['./activation-step-scan-mrz-scan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepScanMrzScanComponent {

  regionName = new Intl.DisplayNames([this.lang.readLangString.toLowerCase()], {
    type: 'region'
  });
  SatorisOcr: typeof SatorisOcr;
  isFlash = true;
  warningColor = false;
  confidence = 0;
  optionsCamera = {
    x: window.innerWidth / 2 - (window.innerWidth * 0.85) / 2,
    y: 160, //window.innerHeight / 2 - (window.innerWidth * 0.5525) / 2
    width: window.innerWidth * 0.85,
    height: window.innerWidth * 0.5525,
    camera: 'back',
    toBack: false,
    tapPhoto: false,
    tapFocus: true,
    previewDrag: false,
    storeToFile: false,
    disableExifHeaderStripping: true
  };

  constructor(public api: ApiService,
    public enroll: EnrollService,
    private loader: LoaderService,
    private lang: LangService,
    private devices: DevicesService,
    private zone: NgZone) {
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.disable();
  }

  ngOnInit() {
    this.loader.loading(true);
    if(!this.devices.isDevices('cordova')) {
      this.loader.loading(true, {type: 'error', message: 'error.no_cordova'}).then(() => this.enroll.navigate('previous'));
      return;
    }

    SatorisOcr.init(SatorisOcr.MRZType[this.enroll.MRZType], environment.templateBytes, environment.templateFactor, () => {
      setTimeout(() => CameraPreview.startCamera(this.optionsCamera, () => CameraPreview.setFlashMode(CameraPreview.FLASH_MODE.TORCH, () => this.takeSnapshot())), 350);
    }, () => {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('error.ocr')});
    });
  }

  ngOnDestroy() {
    if(this.devices.isDevices('cordova') && environment.production) PrivacyScreen.enable();
    return new Promise(resolve => CameraPreview.stopCamera(() => SatorisOcr.reset(() => SatorisOcr.teardown(resolve))));
  }

  /**
   * Return the dimension of an img (width and height)
   * @param {string} imageURL
   * @param {any} callBack
   */
  checkImgDimension(imageURL : string, callBack : any ) {
    const image = new Image();
    image.src = imageURL;
    image.onload = () => {
      callBack({width: image.width, height: image.height});
    };
  }

  /**
   * Take a snapshot of the camera, and launch satoris check. If fail, relaunch the function with new check
   */
  takeSnapshot() {
    CameraPreview.takeSnapshot({quality: 10}, (base64PictureData: string) => {
      this.loader.loading(false);
      // eslint-disable-next-line prefer-destructuring
      this.checkImgDimension('data:image/jpeg;base64, ' + base64PictureData[0], ({width, height} : {width:number, height: number}) => {
        SatorisOcr.check(Array.from(Uint8Array.from(atob(base64PictureData), (c) => c.charCodeAt(0))), cordova.platformId.toUpperCase() !== 'IOS', 0, width, height * 0.55, height * 0.7, environment.ocrType, (obj: any) => {
          this.zone.run(() => {
            if(this.devices.isDevices('cordova-ios')) try {
              obj.ocr = atob(obj.ocr);
            } catch {
              this.loader.loading(true, {type: 'error', message: obj.err || obj.ocr});
            }
            this.enroll.form.controls[this.enroll.fc.MRZ].setValue(this.mrzProcessing(JSON.parse(obj.ocr)));

            if(this.enroll.MRZType === MRZType.TD1) {
              this.enroll.navigate('next'); //TODO add conditionnal skip based on country
            } else if(this.enroll.MRZType === MRZType.TD3) {
              this.enroll.navigate('next');
            }
          });
        }, (obj: any) => {
          this.zone.run(() => {
            this.warningColor = !!obj.err;
            this.confidence = obj.confidence;
          });
          this.takeSnapshot();
        });
      });
    });
  }

  /**
   * Toggle flash light
   */
  toggleFlash() {
    CameraPreview.getFlashMode((flashmode : string) =>{
      if(flashmode === 'torch'){
        CameraPreview.setFlashMode(CameraPreview.FLASH_MODE.OFF);
        this.isFlash = false;
      } else {
        CameraPreview.setFlashMode(CameraPreview.FLASH_MODE.TORCH);
        this.isFlash = true;
      }
    });
  }


  mrzProcessing(mrzDatas : any) : any {
    const newDatas = {...mrzDatas};
    newDatas.first_name = newDatas.first_name.replaceAll('<', ' ');
    newDatas.last_name = newDatas.last_name.replaceAll('<', ' ');
    newDatas.global_name = (newDatas.first_name + ' ' + newDatas.last_name).replace(/</g, '').toLowerCase();
    newDatas.country = this.regionName.of(getCountryISO2(newDatas.country));
    newDatas.document_number = newDatas.document_number.replaceAll('<', '');
    this.enroll.form.controls[this.enroll.fc.DATE_OF_BIRTH].setValue(newDatas.birth_date);
    this.enroll.form.controls[this.enroll.fc.DOC_EXPIRY_DATE].setValue(newDatas.expiry_date);
    this.enroll.form.controls[this.enroll.fc.DOC_NUMBER].setValue(newDatas.document_number);
    newDatas.optional_data && this.enroll.form.controls[this.enroll.fc.NNI].setValue(newDatas.optional_data.replaceAll('<', ''));
    return newDatas;
  }
}
