import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {FormService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-ask-nni',
  templateUrl: './activation-step-ask-nni.component.html',
  styleUrls: ['./activation-step-ask-nni.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepAskNniComponent {
  constructor(public api: ApiService,
              public enroll: EnrollService,
              public forms: FormService) {}
}
