import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-intro',
  templateUrl: './activation-step-intro.component.html',
  styleUrls: ['./activation-step-intro.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepIntroComponent implements OnInit, OnDestroy {
  constructor(public api: ApiService, public enroll: EnrollService, private nav: NavigateService) {}

  ngOnInit() {
    this.enroll.currentConfigIndex = 0;
    this.enroll.currentStepIndex = 0;
    this.enroll.currentChoiceIndex = undefined;
    this.enroll.setConfig();

    this.enroll.setForm();

    if(this.api.userInfo.nationalNumber && this.api.userRole.isCustomer) {
      this.api.setPrincipalMode = true;
    }
  }

  ngOnDestroy() {
    this.api.setPrincipalMode = false;
  }

  back() {
    this.nav.to(this.api.setPrincipalMode ? 'user' : this.api.userRole.isCustomer ? 'activation' : 'manage-user');
    this.api.setPrincipalMode = false;
  }
}
