import {CountryName, countriesSO} from '../app/shared/models/countries';
import {ServiceInterface} from '../app/shared/models/services';
import {ServiceMessage} from '../app/shared/models/message';
import {ClaimIdCard, ClaimSelfAlt, ClaimSelfDetails, ClaimSelfSubAddress, ClaimSelfSubType, ClaimType, CountryClaim} from '../app/shared/models/claim';
import {FormGroup, Validators} from '@angular/forms';
import {EnrollForm, EnrollStep, FC, StepConfig} from 'src/app/shared/models/enroll';
import {I18nLang} from 'ngx-satoris';

export const environment = {
  production: false,
  appVersion: [1, 6, 1],
  baseUri: 'https://myoneciplus-api-test.oneci.ci/api/v1/',
  urlDiscriminant: '.ci',
  pushPublicKey: 'BJoOgLqv0k3x5gELVY9EDOOqKSIwtOP-2xdkse9G74WexSmpPahPPAF577C5KpjiIlqurdK8-rq7LEZKxUwirSg',
  activationLocations: [
    {name: 'ONECI Vallon', pos: {lat: 5.3674167794902345, lng: -3.987619662410561}, info: '<strong>Adresse Postale :</strong>Abidjan, Cocody, Rue J109,<br /><strong>Téléphone :</strong> (+225) 27 20 23 96 60'},
    {name: 'ONECI le plateau', pos: {lat: 5.332829876737427, lng: -4.021714808088595}, info: '<strong>Adresse Postale :</strong> BP V168 Abidjan 19, Boulevard Botreau Roussel, Abidjan-Plateau<br /><strong>Téléphone :</strong> (+225) 27 20 30 79 00 / 27 20 25 45 59<br /><strong>Call Center :</strong> (+225) 27 20 23 96 60 / 27 20 30 79 40 / 1340<br /><strong>Fax :</strong> (+225) 27 20 24 29 13<br /><strong>Email :</strong> mailto:demandeenrolement@oneci.ci / mailto:standard_accueil@oneci.ci / mailto:reclamationCNI@oneci.ci<br /><strong>Horaires :</strong> 08h00 à 12h00 / 13h00 à 17h00'}
  ],
  activationMap: {
    center: {lat: 5.3674167794902345, lng: -3.987619662410561},
    zoom: 13,
    maxZoom: 18
  },
  theme: {
    logo: 'assets-countries/logo.svg',
    background: 'assets-countries/home.png',
    header_background: 'assets-countries/header_background.png',
    country_flag: 'assets-countries/flag.svg'
  },
  ocrType: 0,
  templateBytes: <number[]>undefined,
  templateFactor: 1,
  country: CountryName.CIV,
  supportedLanguages: [I18nLang.EN, I18nLang.FR],
  phonePattern: /^(01|05|07)\d{8}$/,
  center: 'centre ONECI',
  name: 'MyONECI+',
  services: [
    {
      title: 'service.birth_certificate',
      icon: 'baby',
      url: 'https://civc-app-test.semlex-ci.com/#/birth',
      message: [ServiceMessage.DISABLE_MENU, ServiceMessage.IDENTITY],
      active: true
    },
    {
      title: 'service.declaration_loss_theft',
      icon: 'file',
      url: 'https://civc-app-test.semlex-ci.com/#/declaration-of-loss',
      message: [ServiceMessage.DISABLE_MENU, ServiceMessage.IDENTITY],
      active: true
    }
  ] as ServiceInterface[],
  countryClaims: [
    {
      claimType: ClaimType.ID_CARD,
      selectedClaims: JSON.stringify([ClaimType.IDENTITY, ClaimType.ID_CARD, ClaimType.ADULT]),
      icon: 'id-card',
      isoMode: true,
      userPage: true
    },
    {
      claimType: ClaimType.RESIDENT_CARD,
      selectedClaims: JSON.stringify([ClaimType.RESIDENT_CARD, ClaimType.ADULT, ClaimType.IDENTITY]),
      icon: 'house',
      isoMode: false,
      userPage: true
    },
    {
      claimType: ClaimType.RNPP,
      selectedClaims: JSON.stringify([ClaimType.RNPP, ClaimType.ADULT, ClaimType.IDENTITY]),
      icon: 'id-card',
      isoMode: false,
      userPage: true
    },
    {
      claimType: ClaimType.ADDITIONAL_INFO_ADDRESS,
      selectedClaims: JSON.stringify([ClaimType.ADDITIONAL_INFO_ADDRESS, ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL, ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]),
      icon: 'id-card',
      isoMode: false,
      userPage: false
    },
    {
      claimType: ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL,
      selectedClaims: JSON.stringify([ClaimType.ADDITIONAL_INFO_ADDRESS, ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL, ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]),
      icon: 'credit-card',
      isoMode: false,
      userPage: false
    },
    {
      claimType: ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR,
      selectedClaims: JSON.stringify([ClaimType.ADDITIONAL_INFO_ADDRESS, ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL, ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]),
      icon: 'bus',
      isoMode: false,
      userPage: false
    }
  ] as CountryClaim[],
  selfClaims: {
    [ClaimSelfAlt.CONTACT]: {
      label: 'personnalData.contact',
      icon: 'address-book',
      skipTo: '',
      [ClaimType.ADDITIONAL_INFO_CONTACT_EMAIL]: {
        isProof: false,
        metadataValue: [{
          label: ClaimSelfSubType.EMAIL,
          icon: 'envelope',
          subType: 'email',
          validators: [Validators.email, Validators.required]
        }]
      },
      [ClaimType.ADDITIONAL_INFO_CONTACT_PHONE_NR]: {
        isProof: true,
        metadataValue: [{
          label: ClaimSelfSubType.PHONE_NR,
          icon: 'phone',
          subType: 'tel',
          validators: [Validators.pattern(/0[0-9]{9}/), Validators.required]
        }]
      }
    },
    [ClaimType.ADDITIONAL_INFO_ADDRESS]: {
      label: 'personnalData.DYN.' + ClaimSelfSubType.ADDRESS,
      icon: 'location-dot',
      isProof: true,
      skipTo: '/' + ClaimType.ADDITIONAL_INFO_ADDRESS,
      metadataValue: [
        {
          label: ClaimSelfSubAddress.ADDRESSLINE1,
          icon: 'house',
          subType: 'text',
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[A-Za-z0-9_. -éèêëÉÈÊËáàâäÁÀÂÄóòôöÓÒÔÖ]*$/)]
        },
        {
          label: ClaimSelfSubAddress.ADDRESSLINE2,
          icon: 'house',
          subType: 'text',
          validators: [Validators.minLength(2), Validators.maxLength(50)]
        },
        {
          label: ClaimSelfSubAddress.POSTALCODE,
          icon: 'envelope',
          subType: 'number',
          validators: [Validators.pattern(/^\d{5}$/)]
        },
        {
          label: ClaimSelfSubAddress.CITY,
          icon: 'city',
          subType: 'text',
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z\s-]+$/)]
        },
        {
          label: ClaimSelfSubAddress.COUNTRY,
          icon: 'globe',
          subType: 'select',
          selectOptions: countriesSO,
          validators: [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z\s-]+$/)]
        },
        {
          label: ClaimSelfSubAddress.REGION,
          icon: 'flag',
          subType: 'text',
          validators: [Validators.minLength(2), Validators.maxLength(50), Validators.pattern(/^[a-zA-Z\s-]+$/)]
        }
      ]
    }
  } as ClaimSelfDetails,
  stepsConfig: [
    {
      type: 'STEP',
      steps: [
        EnrollStep.VOUCHER,
        EnrollStep.ASK_DEVICE_NAME,
        EnrollStep.DRAW_SIGNATURE,
        EnrollStep.ASK_CAN,
        EnrollStep.NFC_SCAN_ID_CARD,
        EnrollStep.DESC_TAKE_FACE_PICTURE,
        EnrollStep.TAKE_FACE_PICTURE,
        EnrollStep.NFC_SCAN_ID_CARD
      ]
    }
  ] as StepConfig[],
  stepsConfigAdmin: [
    {
      type: 'STEP',
      steps: [
        EnrollStep.QR_SCAN,
        EnrollStep.VOUCHER,
        EnrollStep.ASK_DEVICE_NAME,
        EnrollStep.DRAW_SIGNATURE,
        EnrollStep.ASK_CAN,
        EnrollStep.FINGERPRINT,
        EnrollStep.NFC_SCAN_ID_CARD
      ]
    }
  ] as StepConfig[],
  stepsConfigSuperAdmin: [
    {
      type: 'STEP',
      steps: [
        EnrollStep.QR_SCAN,
        EnrollStep.VOUCHER
      ]
    },
    {
      type: 'CHOICE',
      steps: [
        [
          EnrollStep.ASK_DEVICE_NAME,
          EnrollStep.DRAW_SIGNATURE,
          EnrollStep.ASK_CAN,
          EnrollStep.FINGERPRINT,
          EnrollStep.NFC_SCAN_ID_CARD
        ],
        [
          EnrollStep.FORM_USER,
          EnrollStep.DRAW_SIGNATURE,
          EnrollStep.DESC_TAKE_FACE_PICTURE,
          EnrollStep.TAKE_FACE_PICTURE,
          EnrollStep.CONFIRM
        ]
      ]
    }
  ] as StepConfig[],
  controlsToCheckAdmin: [
    FC.FIRST_NAME,
    FC.MIDDLE_NAME,
    FC.LAST_NAME,
    FC.DATE_OF_BIRTH,
    FC.GENDER,
    FC.COUNTRY,
    FC.NATIONALITY,
    FC.PERSONAL_NUMBER,
    FC.DOC_NUMBER,
    FC.BIRTH_PLACE,
    FC.HEIGHT,
    FC.PROFESSION
  ] as FC[],
  enrollForm: {
    [FC.VOUCHER]: '',
    [FC.DEVICE_NAME]: '',
    [FC.CAN]: '',
    [FC.PIN]: '',
    [FC.NFC]: '',
    [FC.SIGNATURE]: '',
    [FC.FACE_PICTURE]: '',
    [FC.DEVICE_XY]: {
      x: '',
      y: ''
    }
  } as EnrollForm,
  enrollFormAdmin: {
    [FC.VOUCHER]: '',
    [FC.DEVICE_NAME]: '',
    [FC.FIRST_NAME]: '',
    [FC.MIDDLE_NAME]: '',
    [FC.LAST_NAME]: '',
    [FC.DATE_OF_BIRTH]: '',
    [FC.DOC_EXPIRY_DATE]: '',
    [FC.BIRTH_PLACE]: '',
    [FC.GENDER]: '',
    [FC.HEIGHT]: '',
    [FC.PROFESSION]: '',
    [FC.COUNTRY]: '',
    [FC.DOC_NUMBER]: '',
    [FC.PERSONAL_NUMBER]: '',
    [FC.NATIONALITY]: '',
    [FC.CAN]: '',
    [FC.NFC]: '',
    [FC.SIGNATURE]: '',
    [FC.MRZ]: '',
    [FC.FACE_PICTURE]: '',
    [FC.USER_ID]: '',
    [FC.DEVICE_XY]: {
      x: '',
      y: ''
    },
    [FC.FINGERPRINT]: {
      finger: '',
      image: ''
    }
  } as EnrollForm,
  PRODUCT_SUB_KEY_IOS: '', //subappbase
  PRODUCT_SUB_KEY_ANDROID: '', //subapp
  debounce_pin: 10, // in seconds,
  supportSource: 'https://glpi-app.satoris.lu/install.js?project=myoneciplus&lang=fr&position=bottomRight&btnVisible=false',
  eulaLink: 'https://www.oneci.ci/terms-of-use',
  privacyLink: 'https://www.oneci.ci/privacy-policy'
};

export const completeIdCardAdmin = (serialized: ClaimIdCard, form: FormGroup) => {
  serialized.date_of_birth = new Date(form.controls[FC.DATE_OF_BIRTH].value.trim()).toISOString().split('T')[0];
  serialized.place_of_birth = form.controls[FC.BIRTH_PLACE].value.trim();
  serialized.height = Number(form.controls[FC.HEIGHT].value.trim());
  serialized.profession = form.controls[FC.PROFESSION].value.trim();
};
