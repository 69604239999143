import {Injectable} from '@angular/core';
import {Claim, ClaimScan, ClaimType, UserScan, UserScanData} from '../models/claim';
import {Place} from '../models/place';
import {ApiService} from './api.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {parseError} from '../utils/string';

@Injectable({
  providedIn: 'root'
})
export class ScanService {

  userScanData: UserScan;
  claimScanData: ClaimScan[];
  claims: Claim[];
  placeControlClaims: ClaimType[];
  scanDone: boolean;
  externalScanId: number;
  selectedPlace: Place;
  adminSelectedPlace: Place;

  constructor(private api: ApiService,
    private nav: NavigateService,
    private lang: LangService,
    private loader: LoaderService) {
  }

  setUserScanValue(scanValue: string): Promise<UserScan> {
    const split = scanValue.split('.');
    const token = split.length > 3 ? split[0] + '.' + split[1] + '.' + split[2] : scanValue;
    return this.checkJwtValidity(token).then(validDatas => {
      if(!validDatas) {
        this.nav.to('user');
        setTimeout(() => {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('qr.invalid')});
        }, 100);
      } else {
        return {
          data: validDatas,
          xy: split.length > 3 ? JSON.parse(atob(split[3])) : undefined
        };
      }
    });
  }

  hasControl(type: ClaimType): boolean {
    return this.placeControlClaims && this.placeControlClaims.includes(type);
  }

  setQrCode(data: any, stringify = false) {
    const qrCode = stringify ? JSON.stringify(data) : data;
    return 'did:' + qrCode;
  }

  checkQrCode(str: string) {
    const codeCheck = str.startsWith('did:');
    return codeCheck ? str.split('did:')[1] : false;
  }

  checkJwtValidity(jwt: string): Promise<UserScanData> {
    this.loader.loading(true);
    return this.api.badge(jwt).catch(() => undefined).finally(() => this.loader.loading(false));
  }

  scanned(str: string, navTo: string, allowBadges = false, extraState?: any) {
    const checkQr = this.checkQrCode(str);
    if(!checkQr) {
      this.nav.to('user');
      setTimeout(()=>{
        this.loader.loading(true, {type: 'error', message: 'qr.invalid'});
      }, 100);
    } else {
      if(!this.scanDone) {
        this.scanDone = true;
        try {
          const scanValue = JSON.parse(checkQr) || {};
          if(scanValue.service && scanValue.requestId && this.api.userInfo.server.callbacks[scanValue.service] && this.api.userRole.isCustomer) {
            if(scanValue.nni && scanValue.nni !== this.api.userInfo.nationalNumber) {
              this.nav.to('user');
              setTimeout(() => {
                this.loader.loading(true, {type: 'warn', message: 'api.postClaim.badNni'});
              }, 100);
            } else {
              this.nav.to('scan-share', undefined, {queryParams: scanValue});
            }
          } else if(scanValue[0]?.id && scanValue[0].uuid && this.api.userRole.isCustomer) {
            this.nav.to('user');
            setTimeout(() => {
              this.loader.loading(true, {type: 'warn', message: 'activation_code.no_scan'});
            }, 100);
          } else if(scanValue[0]?.id && scanValue[0].uuid && !this.api.userRole.isCustomer && this.api.userPlaceId) {
            this.claimScanData = scanValue;
            this.loader.loading(true);
            return this.api.place(this.api.userPlaceId).then((place: Place) => {
              //Check place permissions to read §claims and filter claims;
              this.placeControlClaims = place.controlClaims;
              const placeCanControlClaim = scanValue.find((p: ClaimScan) => this.placeControlClaims.includes(p.type));
              if(!placeCanControlClaim) {
                this.nav.to('user');
                setTimeout(() => {
                  this.loader.loading(true, {type: 'error', message: this.lang.transform('error.DYN.place.no_control')});
                }, 500);
              }
              return Promise.all(scanValue.map((p: ClaimScan) => this.placeControlClaims.includes(p.type) ? this.api.readClaim(p.id, p.uuid) : undefined)).then((claims: Claim[]) => {
                this.claims = claims;
                this.loader.loading(false);
                this.nav.to(navTo, undefined, {state: extraState});
              }).catch(err => {
                const error = parseError(err);
                this.nav.to('user');
                setTimeout(() => {
                  this.loader.loading(true, {
                    type: 'error',
                    message: error.tradError
                  });
                }, 500);
              });
            }).catch(err => {
              this.loader.loading(true, {
                type: 'error',
                message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.place.error')
              });
            });
          } else if(allowBadges && scanValue.data) {
            this.userScanData = scanValue;
            if(scanValue?.data?.exp) {
              const current_time = Math.floor(Date.now() / 1000);
              if(current_time > scanValue.data.exp) {
                setTimeout(()=>{
                  this.loader.loading(true, {type: 'error', message: this.lang.transform('client.extended.uuidExpired.server')});
                }, 500);
                return this.nav.to('user');
              } else {
                this.nav.to(navTo, undefined, {state: {...extraState, uniqueId: this.userScanData.data.uid}});
              }
            } else {
              setTimeout(()=>{
                this.loader.loading(true, {type: 'error', message: this.lang.transform('qr.invalid')});
              }, 500);
              return this.nav.to('user');
            }
          } else {
            this.loader.loading(true, {type: 'error', message: 'activation_code.badge_error'});
          }
        } catch (e) {
          this.nav.to('user');
          setTimeout(()=>{
            this.loader.loading(true, {type: 'error', message: 'qr.invalid'});
          }, 100);
        }
      } else {
        this.loader.loading(true, {type: 'error', message: 'global.error'});
      }
    }
  }

  getClaimNames(claimTypes: ClaimType[]): string {
    return claimTypes.map(c => this.lang.transform('claim.DYN.' + c)).join(', ');
  }
}
