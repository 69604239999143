import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../../shared/services/api.service';
import {EnrollService} from '../../../../../shared/services/enroll.service';

@Component({
  selector: 'app-activation-step-scan-mrz-choice',
  templateUrl: './activation-step-scan-mrz-choice.component.html',
  styleUrls: ['./activation-step-scan-mrz-choice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepScanMrzChoiceComponent {

  constructor(public api: ApiService, public enroll: EnrollService) {}

}
