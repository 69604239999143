/* tslint:disable */
export const en_strings = {
  'form.personal_number': 'Personal number',
  'activation.step.descFaceCheck.DYN': 'We will now verify your identity using facial recognition.<br><br> Please be ready to take a <b>well-centered</b> photo of your face on the next screen.<br><br> Ensure that your face is <b>fully visible, well-lit, and unobstructed.</b>',
  'activation.step.descFaceCheck.DYN.admin': 'We will now verify the person’s identity using facial recognition.<br><br> On the next screen, please take a <b>well-centered</b> photo of their face.<br><br> Make sure their face is <b>fully visible, well-lit, and unobstructed.</b>',
  'activation.step.descFaceCheck.title': 'Identity Verification',
  'err.VOUCHER.pattern': 'Voucher format is invalid',
  'setup_pro_account_done.dialog': 'Please check that the recipient has received the email or noted the login details before closing this window.',
  'news.new.error': 'An error occurred while deleting the article. Please try again.',
  'news.new.deleted': 'Article deleted successfully.',
  'news.new.delete': 'Are you sure you want to delete this article?',
  'global.delete': 'Delete',
  'manageNews.update_article': 'Update the article',
  'scanner.processing': 'Processing received data',
  'scan_share.processing': 'Communicate with external service to share your data',
  'placeSettings.img.success': 'The image of the place has been successfully updated.',
  'form.image': 'Image',
  'err.image.required': 'Image is required',
  'placeSettings.title': 'Place settings',
  'workerAdmin.settings': 'Settings',
  'version.update.title': 'Update available',
  'version.update.desc': 'A new version of the application is available. Please update to continue using the application.',
  'version.update.now': 'Update now (unavailable)',
  'version.update.later': 'Later',
  'scannedBadge.no_name': 'Unknown name',
  'scannedBadge.no_place': 'Unknown place',
  'scannedBadge.permissions.0': '<b>Creation:</b> Can create a document.',
  'scannedBadge.permissions.1': '<b>Consumption:</b> Can control documents.',
  'scannedBadge.permissions.2': '<b>Control:</b> Can check user information.',
  'scannedBadge.permissions.3': '<b>Administer:</b> Can manage locations and users.',
  'scannedBadge.claim.IDENTITY': 'National n°',
  'scannedBadge.claim.ID_CARD': 'ID card',
  'scannedBadge.claim.DRIVING_LICENSE': 'Driving license',
  'scannedBadge.claim.ADULT': 'Majority',
  'scannedBadge.claim.VACCINATION': 'Vaccination',
  'scannedBadge.claim.HEALTH_CARD': 'Health card',
  'scannedBadge.claim.PASSPORT': 'Passport',
  'scannedBadge.claim.TRANSPORT_CARD': 'Transport card',
  'scannedBadge.claim.RESIDENT_CARD': 'Resident card',
  'scannedBadge.claim.CEDEAO_CARD': 'CEDEAO card',
  'scannedBadge.claim.ALIEN_CARD': 'Alien card',
  'scannedBadge.claim.ADDITIONAL_INFO_CONTACT_EMAIL': 'Email',
  'scannedBadge.claim.RNPP': 'RNPP',
  'scannedBadge.claim.SOTRA_CARD': 'SOTRA card',
  'scannedBadge.claim.MUGEFCI_CARD': 'MUGEFCI card',
  'scannedBadge.claim.ADDITIONAL_INFO_ADDRESS': 'Address',
  'scannedBadge.claim.ADDITIONAL_INFO_HEALTH': 'Health',
  'scannedBadge.claim.ADDITIONAL_INFO_BANK': 'Bank',
  'scannedBadge.claim.ADDITIONAL_INFO_CONTACT_PHONE_NR': 'Phone n°',
  'scannedBadge.member_of': 'member of',
  'scannedBadge.reading': 'Authorized to read',
  'scannedBadge.permissions': 'Permissions',
  'error.DYN.place.no_control': 'The place your account is currently linked to does not allow you to control this type of claim.',
  'err.VOUCHER.minlength': 'Voucher code is too short',
  'pin.redirectDYN.sign-in-pro_change-password': 'To change your password',
  'ask.signinpro.reset.password': 'Do you want to create a new password for your pro account?',
  'ask.worker.remove': 'Are you sure you want to deconnect this account?',
  'user.notFound.DYN.server': 'Your account was not found. Try Again.',
  'user.otpExpired.DYN.server': 'Your code has expired. Please log in again',
  'user.otpTooSoon.DYN.server': 'You have tried too many times. Please wait a few seconds before trying again.',
  'err.phoneCIV.required': 'Phone number is required',
  'err.phoneCIV.pattern': 'Phone number must be composed of 10 digits and start with 01, 05 or 07',
  'err.FINGERPRINT.required': 'You need to choose a finger',
  'user.wrongOtp.DYN.server': 'Your code is incorrect. Please try again.',
  'userPermission.timeout': 'Your internet connection is too slow, we were unable to retrieve this user\'s data. Would you like to try again?',
  'co.unstable': 'You are currently in offline mode. It is possible that your connection is too unstable or that you no longer have internet. Certain features are therefore not available.',
  'no.connection.server': 'You are currently offline. Please try again later.',
  'advantage1': 'Unlock your card visuals',
  'advantage2': 'Share your documents',
  'advantage3': 'Access advanced services',
  'alreadyAccount': 'Already have an account ?',
  'autoKickUser': 'You cannot remove yourself from this place.',
  'cancel': 'Cancel',
  'change': 'Change',
  'checkKickUser': 'Are you sure you want to remove this user from this place?',
  'confirm': 'Confirm',
  'createAccount': 'Create an account',
  'date': 'Date',
  'duplicate key value violates unique constraint "place_pkey"': 'Place ID is already in use. Please modify it',
  'explainHelpPersonnal': 'Below you can find the legend of the various statutes of the requests of your personal data :',
  'infinite': 'Infinite',
  'issuerExpired': 'Your sharing token has expired. We are busy renewing it. Please try again later...',
  'issuerExpiredNoInternet': 'Your sharing token has expired. Please reconnect to the internet to retrieve a new one.',
  'name': 'Name',
  'nan': 'Not available',
  'nfc-explain': 'Please position your phone on your card as indicated above to scan the NFC chip. Once detected, be sure not to move your card or your phone.',
  'noAllClaimSelf': 'No personal data recorded or in the process of validation.',
  'none': 'None',
  'notEnrolledBiometric': 'You have not yet enabled biometric authentication on your device. Please do so in order to continue.',
  'noVerify': 'Rejected',
  'ok': 'ok',
  'personnalDatas': 'Personal data',
  'price': 'Price',
  'proof': 'Proof',
  'reset': 'Reset',
  'retry': 'Retry',
  'skip': 'Skip',
  'update': 'UPDATE',
  'uptodate': 'You\'re up to date !',
  'verify': 'Checked',
  'waiting': 'On hold',
  '11.DYN.title': 'Contact email',
  '15.DYN.title': 'Contact address',
  '18.DYN.title': 'Contact number',
  'account.admin': 'Admin account',
  'account.pro': 'Pro account',
  'account.removed': 'The account has been successfully removed.',
  'account.super_admin': 'Super admin account',
  'accountPro.expired': 'Your pro account has expired. Please log in again.',
  'accountValid.desc': 'Your account has been created but now needs to be activated.',
  'accountValid.email.desc': 'A confirmation email has been sent to you. Click on the link inside to start using your account.',
  'accountValid.phone.desc': 'A confirmation SMS has been sent to you. Please enter the code it contains to start using your account.',
  'accountValid.title': 'Account activation',
  'activate.self': 'Activate yourself your account',
  'activation_code.badge_error': 'Can\'t get data for QR code',
  'activation_code.no_scan': 'Your are not able to scan another citizen.',
  'activation.code_info': 'Activate in',
  'activation.code.info.desc': 'Go to your local center with your own unique and secure activation QR code in order to create your account and start using your digital ID card.',
  'activation.code.info.title': 'Enrollment in ',
  'activation.code.scan': 'Generate the code',
  'activation.desc': 'Your account has been created but now needs to be activated by one of our partners. Please, follow the instructions below.',
  'activation.step.admin.done.desc': 'Account has been successfully validated. The user\'s digital identity card is now available on his account.',
  'activation.step.ask_can.title': 'Enter your CAN number',
  'manageNewsArticles.title': 'All articles',
  'news.new.updated': 'Article updated successfully.',
  'news.new.no_lang': 'The article has not been into this language yet. You may translate it now.',
  'form.thumbnail': 'Thumbnail',
  'articles.search_error': 'Error searching articles. Please try again later.',
  'global.edit': 'Edit',
  'article.status.visible': 'Visible',
  'article.status.not_visible': 'Not visible',
  'article.type.INTERNAL_PRO': 'Internal Pro feature',
  'article.type.INTERNAL_ADMIN': 'Internal Admin feature',
  'article.type.GOVERNMENT_NEWS': 'Government news',
  'article.type.LEGAL_UPDATE': 'Legal update',
  'article.type.REPORTS_AND_ANALYSIS': 'Reports and analysis',
  'article.type.INTERVIEWS': 'Interviews',
  'article.type.LOCAL_NEWS': 'Local news',
  'article.type.INTERNATIONAL_NEWS': 'International news',
  'article.type.OPINIONS_AND_EDITORIALS': 'Opinions and editorials',
  'article.type.SPECIAL_REPORTS': 'Special reports',
  'article.type.GUIDES_AND_TUTORIALS': 'Guides and tutorials',
  'article.type.PRACTICAL_TIPS': 'Practical tips',
  'article.type.FAQ': 'FAQ',
  'article.type.GOVERNMENT_CAMPAIGNS': 'Government campaigns',
  'article.type.ENVIRONMENT_AND_SUSTAINABILITY': 'Environment and sustainability',
  'article.type.CULTURAL_EVENTS': 'Cultural events',
  'article.type.CULTURAL_HERITAGE': 'Cultural heritage',
  'article.type.SPORTS_NEWS': 'Sports news',
  'article.type.POP_CULTURE': 'Pop culture',
  'news.new.success': 'Article created successfully. Would you like to translate it now to another available language?',
  'news.publish': 'Publish',
  'news.fromTo': 'Visibility period',
  'err.to.required': 'End date is required',
  'err.from.required': 'Start date is required',
  'err.articleType.required': 'Article type is required',
  'err.language.required': 'Language is required',
  'article.language_choice': 'Article language',
  'form.articleType': 'Article type',
  'articleType.1': 'Internal feature',
  'articleType.2': 'Internal article',
  'articleType.3': 'External feature',
  'articleType.4': 'External article',
  'global.save': 'Save',
  'news.save_publish': 'Save and publish',
  'err.link.pattern': 'Link is not valid',
  'err.link.required': 'Link is required',
  'form.link': 'URL link',
  'news.contentIsLink': 'This article is a link to an external website.',
  'news.content': 'Content',
  'err.title.required': 'Title is required',
  'err.subtitle.required': 'Subtitle is required',
  'err.thumbnail.required': 'Thumbnail is required',
  'err.content.required': 'Content is required',
  'form.title': 'Title',
  'form.subtitle': 'Subtitle',
  'news.thumbnail': 'Article image',
  'news.preview': 'Preview of the thumbnail',
  'global.select_image': 'Select an image',
  'manageNews.new_article': 'New article',
  'manageNews.articles': 'All articles',
  'user.manage_news': 'Manage news',
  'subscription.noProduct': 'No product to subscribe to was found. Please try again.',
  'subscription.error': 'An error occurred while opening the subscription page. Please try again.',
  'info.getPin.valid': 'PIN code successfully retrieved. You can now redo NFC scan to continue.',
  'question.getPin.confirm': 'Have you read and note your PIN correctly?',
  'err.getPin.invalidImage': 'The photo sent is not valid. Please try again?',
  'err.checkPin': 'Unable to retrieve your PIN',
  'activation.step.choice.ask_pin.nfc_scan_id_card': 'If you have a card PIN, please use it to continue. Otherwise, you can get your <strong>initial</strong> card PIN by face authentication.<br/><br/><strong>Attention:</strong> if card PIN once has been changed it cannot be retrieved.',
  'activation.step.choice.ASK_PIN': 'I have a card PIN',
  'activation.step.choice.NFC_SCAN_ID_CARD': 'I forgot my card PIN',
  'err.getPin.noMatch': 'Unfortunately, there were no matches found. Please try again.',
  'err.activationCodeMap.unableToGetYourLocation': 'Unable to get your location.',
  'err.activationCodeMap.notSupported': 'Geolocation is not supported by this browser.',
  'err.fingerprint.captureTimeout': 'The fingerprint scan took too long. Please try again.',
  'client.extended.noFingerAuth': 'Fingerprints do not match, check if the finger is selected correctly and try scanning again',
  'fingerprint.read.self.form.desc': 'Please choose a finger and click on the Continue button to start the verification procedure.',
  'fingerprint.read.self.form.desc2': 'Please put the selected finger in the scanning area and wait a few seconds for the process to complete.',
  'activation.step.fingerprint.title': 'Fingerprint verification',
  'fingerprint.init.init_failed': 'Fingerprint initialization failed. Please restart the application.',
  'err.fingerprint.noDevicePresentYet': 'No fingerprint device was found. Please restart the application.',
  'err.fingerprint.sdkNotInitialized': 'Fingerprint was not initialized. Please restart the application.',
  'err.fingerprint.noCordova': 'Only available on phone.',
  'form.finger': 'Finger to scan',
  'activation.step.fingerprint.fingerOption.DYNunknown': 'Unknown',
  'activation.step.fingerprint.fingerOption.DYNrightThumb': 'Right thumb',
  'activation.step.fingerprint.fingerOption.DYNrightIndex': 'Index finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYNrightMiddle': 'Middle finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYNrightRing': 'Right ring finger',
  'activation.step.fingerprint.fingerOption.DYNrightLittle': 'Little finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYNleftThumb': 'Left thumb',
  'activation.step.fingerprint.fingerOption.DYNleftIndex': 'Index finger of the left hand',
  'activation.step.fingerprint.fingerOption.DYNleftMiddle': 'Middle finger of the left hand',
  'activation.step.fingerprint.fingerOption.DYNleftRing': 'Left ring finger',
  'activation.step.fingerprint.fingerOption.DYNleftLittle': 'Little finger of the left hand',
  'otp.pattern': 'The code must be composed of 6 digits only.',
  'user.otpTooSoon.server': 'You have tried too many times. Please wait a few seconds before trying again.',
  'err.password.mustNotMatch': 'The new password must be different from the old one',
  'err.oldPassword.customPatternRequirements': 'Old password must contain at least 3 of the following: uppercase letter, lowercase letter, number, special character',
  'err.password.customPatternRequirements': 'Password must contain at least 3 of the following: uppercase letter, lowercase letter, number, special character',
  'more.setPrimaryDevice': 'Set as primary device',
  'form.deviceName': 'Device name',
  'activation.step.ask_deviceName.desc': 'Please enter a name for this device. This name will be used to identify this device in the list of devices.',
  'activation.step.ask_deviceName.title': 'Device name',
  'activation.step.ask_nni.desc': 'Please enter your <b>national identity number<b> below:',
  'activation.step.ask_nni.title': 'National Identity Number',
  'activation.step.ask_pin.title': 'Pin code',
  'activation.step.choice': 'This process includes a choice depending on your situation or the documents you have. <br><br>Choose the option that suits you best below',
  'activation.step.choice.DYN.ASK_PIN': 'I have a card PIN',
  'activation.step.choice.DYN.ask_pin.nfc_scan_id_card': 'If you have a card PIN, please use it to continue. Otherwise, you can get your <strong>initial</strong> card PIN by face authentication.<br/><br/><strong>Attention:</strong> if card PIN once has been changed it cannot be retrieved.',
  'activation.step.choice.DYN.DOC_NUMBER': 'Manually enter data',
  'activation.step.choice.DYN.img.ask_device_name.form_user': 'assets-countries/choice.svg',
  'activation.step.choice.DYN.ask_device_name.form_user': 'Choose an activation method',
  'activation.step.choice.DYN.ASK_DEVICE_NAME': 'Activation using fingerprint-device',
  'activation.step.choice.DYN.VOUCHER': 'Activate subscription',
  'activation.step.voucher.title': 'Activate the subscription',
  'activation.step.voucher.desc': 'Enter a voucher number to set up your account',
  'form.voucher': 'Voucher code',
  'qr.userNotRead': 'Cannot read data of account scanned',
  'qr.voucherNotOk': 'This voucher could not be used, please try again',
  'activation.step.choice.DYN.ASK_FORM_USER': 'Activation in manual mode',
  'activation.step.choice.DYN.FORM_USER': 'Activation in manual mode',
  'activation.step.choice.DYN.img.scan_mrz_scan.doc_number': 'assets-countries/choice.svg',
  'activation.step.choice.DYN.NFC_SCAN_ID_CARD': 'I don\'t know my PIN code',
  'activation.step.choice.DYN.SCAN_MRZ_SCAN': 'Scan the MRZ code',
  'activation.step.choice.DYN.scan_mrz_scan.doc_number': 'Please scan the MRZ code of your document (series of <b>letters<b> and <b>numbers<b> with <b><<<<b> at the back of the document) or enter some information map manually?',
  'activation.step.choice.title': 'Choose your activation method',
  'activation.step.confirm.fc.DYN.PROFESSION': 'Profession',
  'activation.step.confirm.fc.DYN.BIRTH_PLACE': 'Birth place',
  'activation.step.confirm.fc.DYN.HEIGHT': 'Height',
  'activation.step.confirm.fc.DYN.FIRST_NAME': 'First name',
  'activation.step.confirm.fc.DYN.MIDDLE_NAME': 'Middle name',
  'activation.step.confirm.fc.DYN.LAST_NAME': 'Last name',
  'activation.step.confirm.fc.DYN.GENDER': 'Gender',
  'activation.step.confirm.fc.DYN.NATIONALITY': 'Nationality',
  'activation.step.confirm.fc.DYN.COUNTRY': 'Country',
  'activation.step.confirm.fc.DYN.PERSONAL_NUMBER': 'Personal number',
  'activation.step.confirm.fc.DYN.DISTRICT': 'District',
  'activation.step.confirm.fc.DYN.ISSUE_PLACE': 'Issue place',
  'activation.step.confirm.fc.DYN.ISSUE_DATE': 'Issue date',
  'activation.step.confirm.fc.DYN.SUB_LOCATION': 'Sub-location',
  'activation.step.confirm.fc.DYN.DIVISION': 'Division',
  'activation.step.confirm.fc.DYN.LOCATION': 'Location',
  'activation.step.confirm.fc.DYN.OCCUPATION': 'Occupation',
  'activation.step.confirm.fc.DYN.CAN': 'CAN number',
  'activation.step.confirm.fc.DYN.PIN': 'PIN code',
  'activation.step.confirm.fc.DYN.USER_ID': 'User ID',
  'activation.step.confirm.fc.DYN.DEVICE_XY': 'Secure device',
  'activation.step.confirm.fc.DYN.DATE_OF_BIRTH': 'Date of birth',
  'activation.step.confirm.fc.DYN.DEVICE_NAME': 'Device name',
  'activation.step.confirm.fc.DYN.DOC_EXPIRY_DATE': 'Expiration date',
  'activation.step.confirm.fc.DYN.DOC_NUMBER': 'Document number',
  'activation.step.confirm.fc.DYN.FACE_PICTURE': 'Face picture',
  'activation.step.confirm.fc.DYN.FINGERPRINT': 'Fingerprint',
  'activation.step.confirm.fc.DYN.MRZ': 'Code MRZ',
  'activation.step.confirm.fc.DYN.NFC': 'NFC Scan',
  'activation.step.confirm.fc.DYN.NNI': 'National identity number',
  'activation.step.confirm.fc.DYN.SIGNATURE': 'Signature',
  'activation.step.confirm.title': 'Summary of steps',
  'activation.step.date_of_birth.desc': 'Please enter your <b>date of birth<b> below:',
  'activation.step.date_of_birth.title': 'Date of birth',
  'activation.step.doc_exp_date.desc': 'Please enter the <b>expiration date<b> of your document below:',
  'activation.step.doc_exp_date.title': 'Expiration date',
  'activation.step.doc_number.desc': 'Please enter your <b>document number<b> below:',
  'activation.step.doc_number.title': 'Document number',
  'activation.step.done.desc': 'You have successfully activated your account.',
  'activation.step.done.title': 'Activation completed',
  'activation.step.draw_signature': 'Draw your signature below',
  'activation.step.draw_signature.admin': 'Please draw your signature below or upload an image of your signature,',
  'activation.step.draw_signature.title': 'Your signature',
  'activation.step.fingerprint.fingerOption.DYN.leftIndex': 'Index finger of the left hand',
  'activation.step.fingerprint.fingerOption.DYN.leftLittle': 'Little finger of the left hand',
  'activation.step.fingerprint.fingerOption.DYN.leftMiddle': 'Middle finger of the left hand',
  'activation.step.fingerprint.fingerOption.DYN.leftRing': 'Left ring finger',
  'activation.step.fingerprint.fingerOption.DYN.leftThumb': 'Left thumb',
  'activation.step.fingerprint.fingerOption.DYN.rightIndex': 'Index finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYN.rightLittle': 'Little finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYN.rightMiddle': 'Middle finger of the right hand',
  'activation.step.fingerprint.fingerOption.DYN.rightRing': 'Right ring finger',
  'activation.step.fingerprint.fingerOption.DYN.rightThumb': 'Right thumb',
  'activation.step.fingerprint.fingerOption.DYN.unknown': 'Unknown',
  'activation.step.intro.DYN': 'Welcome to the process of activating your <b>{country}<b> card, please follow the steps below to activate your card.<br><br> If you have any questions, please open the Help Center. Thanks!',
  'activation.step.intro.DYN.admin': 'Welcome to the activation process of the <b>{country}</b> card for your client. Please follow the next steps to proceed with the activation. If you encounter any difficulties, don\'t hesitate to consult the Help Center. Thank you!',
  'activation.step.intro.resetAccount.title': 'New primary device',
  'activation.step.intro.title': 'Activate your account',
  'activation.step.nfc_scan_id_card.title': 'Nfc scan',
  'activation.step.scan_mrz': 'Document scanning',
  'activation.step.scan_mrz_choice.desc': 'Please choose the document you wish to scan below:',
  'activation.step.scan_mrz_choice.title': 'Choosing the document to scan',
  'activation.step.scan_mrz_nfc.desc': 'Please <b>lay your document on the back of your phone<b> and wait for the NFC scan to complete.',
  'activation.step.scan_mrz_nfc.title': 'NFC document scan',
  'activation.step.scan_mrz_nfc.title2': 'NFC scan result',
  'activation.step.scan_mrz_scan.desc': 'The camera will open automatically.</br></br>Please <b>place your document<b> in the frame with the MRZ code visible (series of <b>letters<b> and <b>numbers<b> with <b><<<<b> at the back of the document) and wait for the scan to complete.',
  'activation.step.scan_mrz_scan.title': 'Scan your document',
  'activation.step.scan_mrz.title': 'Scan your document',
  'activation.title': 'Activation',
  'activity.claim.type.DYN0': 'Identity shared',
  'activity.claim.type.DYN1': 'ID card shared',
  'activity.claim.type.DYN2': 'Driving licence shared',
  'activity.claim.type.DYN3': 'Majority shared',
  'activity.claim.type.DYN5': 'Health card shared',
  'activity.claim.type.DYN7': 'Transport card shared',
  'activity.claim.type.DYN15': 'Sharing of contact address',
  'activity.claim.type.DYN18': 'Sharing of contact phone number',
  'activity.claim.type.DYN13': 'Sharing of SOTRA card',
  'activity.getActivities.error': 'Can\'t get events',
  'activity.type.DYN0': 'Account created',
  'activity.type.DYN1': 'Account confirmed',
  'activity.type.DYN10': 'Read iData',
  'activity.type.DYN11': 'Certificate revoked',
  'activity.type.DYN12': 'Pin changed',
  'activity.type.DYN3': 'Password changed',
  'activity.type.DYN4': 'Login',
  'activity.type.DYN6': 'iData created',
  'activity.type.DYN7': 'iData revoked',
  'activity.type.DYN8': 'iData fetched',
  'activity.type.DYN9': 'iData shared',
  'addPlaceMember.desc': 'Add new member to ',
  'addPlaceMember.title': 'Add new member',
  'address.DYN.title': 'Contact address',
  'addressLine1.DYN.title': 'Contact address',
  'admin.changePerm': 'Are you sure you want to change the rights of this user?',
  'admin.disconnect': 'You have been successfully disconnected from your pro account. You have been redirected to your citizen account.',
  'admin.noAdminPerm': 'You cannot remove your own Administrator rights from this place.',
  'admin.reconnect': 'Please log back into your pro account to continue.',
  'all.personnalDatas': 'All your personal data',
  'allAccounts.disconnected': 'All your accounts have been successfully disconnected.',
  'allShare.title': 'Sharing choice',
  'already.otp.DYN.0': 'Your two-factor authentication via email is already activated. Please choose another option.',
  'already.otp.DYN.1': 'Your two-factor authentication via phone is already activated. Please choose another option.',
  'api.createClaim.error': 'Can\'t get claim',
  'api.createClaim.missingData': 'Missing data to create user, please try again',
  'api.listPlaces.error': 'Can\'t get list of places',
  'api.place.error': 'Error during getting info about place',
  'api.places.error': 'Can\'t register user in place',
  'api.postClaim.badNni': 'The service is expecting another citizen than yourself.',
  'api.postClaim.error': 'Could not post back the result to the service',
  'api.postClaim.success': 'The service has received your information',
  'api.registerAdmin.error': 'Can\'t register admin in place',
  'api.searchPlace.error': 'Can\'t get search results',
  'api.setPin.error': 'Unable to set PIN',
  'api.shareClaim.err_wrong_doc': 'The requested document is not your presented document',
  'api.shareClaim.error': 'Can\'t share cliam ',
  'api.shareClaim.error_not_closed': 'The connection is not cleanly closed',
  'api.shareClaim.noClaim': 'Unable to share. This is because it\'s not the device that you initially enrolled with. If you wish to do it on this device, you will need to re-enroll.',
  'api.shareClaim.receiverClose': 'Receiver closed the trasaction',
  'api.user_info.error': 'Can\'t get data about user',
  'approveClaimSelf.success': 'The proof has been approved.',
  'ask.approveClaimSelf': 'If applicable, please enter the expiration date of proof.',
  'ask.changeCustomer': 'Are you sure you want to go back to your citizen account?',
  'ask.disconnect.allAccounts': 'Are you sure you want to disconnect all yours accounts?',
  'ask.disconnectAllDevices': 'Are you sure you want to disconnect all other devices?',
  'ask.faceID.unset': 'Are you sure you want to desactivate your FaceID?',
  'ask.fingerprint.unset': 'Are you sure you want to disable your fingerprint?',
  'ask.help': 'Need help ?',
  'ask.logoutAllDevices': 'Do you want to disconnect all other devices connected to your account?',
  'ask.signout': 'Are you sure you want to logout ?',
  'ATTESTATION_RNPP.DYN.select': 'Attestation RNPP',
  'badge.title': 'My badge',
  'biometric.dismissed': 'You have cancelled your biometric authentication. Please try again.',
  'biometric.faceID.setError': 'An error occurred while activating Face ID, please try again.',
  'biometric.faceID.setSuccess': 'Face ID has been successfully activated !',
  'biometric.maybeNoExist': 'Your device does not support biometric authentication, or you have not set it up.',
  'biometric.notAvailable': 'Your device does not support biometric authentication. Please enable it if possible.',
  'biometricANDROID.unregister.success': 'Fingerprint authentication successfully disabled!',
  'biometricIOS.unregister.success': 'Face ID authentication disabled successfully!',
  'bluetooth.desc.disabled': 'In order to share, you need to activate your Bluetooth.',
  'bluetooth.desc.noPermissions': 'In order to proceed with the sharing, you need to give permissions to use your bluetooth',
  'bluetooth.disabled': 'In order to proceed with the sharing, you need to activate your Bluetooth',
  'btn.retry': 'Retry',
  'call.timeout': 'Your internet connection is too slow. The server call has timed out. Please try again.',
  'camera.desc.noPermission': 'In order to proceed with the sharing, you need to give permissions to use your Camera',
  'camera.turnFlashOff': 'Turn flash off',
  'camera.turnFlashOn': 'Turn flash on',
  'card.activating': 'Your cards are being created. Please wait...',
  'card.generator.card': 'Choose a document',
  'cardGenerator.title': 'Card generator',
  'change-password-done.desc': 'Your new password has been successfully set. Please log in again.',
  'change-password-done.pro.desc': 'The new password for your pro account has been successfully set. Please add your pro account again.',
  'change-password.newpass_error': 'Cannot set a new password',
  'change-password.wrong_oldpass': 'Old password is incorrect',
  'change.fromAdmin': 'Your pro account has been successfully removed. You have been redirected to your citizen account.',
  'change.lang.already.set': 'This language is already selected.',
  'changePin.desc': 'You may change your secret code at any time. You will need to enter your current secret code followed by your new secret code.',
  'changePin.title': 'Change the secret code',
  'checkBox_label.DYN.ALIEN_CARD': 'Foreign card',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT': 'Personal contact information',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT_EMAIL': 'Contact email',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT_PHONE_NR': 'Contact phone number',
  'checkBox_label.DYN.ADDITIONAL_INFO_ADDRESS': 'Personal address information',
  'checkBox_label.DYN.ADDITIONAL_INFO_HEALTH': 'Personal health information',
  'checkBox_label.DYN.ADDITIONAL_INFO_BANK': 'Personal banking information',
  'checkBox_label.DYN.IDENTITY': 'Identity',
  'checkBox_label.DYN.ID_CARD': 'ID Card',
  'checkBox_label.DYN.DRIVING_LICENSE': 'Driving Licence',
  'checkBox_label.DYN.ADULT': 'Adult Check',
  'checkBox_label.DYN.PASSPORT': 'Passport',
  'checkBox_label.DYN.TRANSPORT_CARD': 'Transport card',
  'checkBox_label.DYN.RNPP': 'RNPP Certification',
  'checkBox_label.DYN.SOTRA_CARD': 'SOTRA Card',
  'checkBox_label.DYN.MUGEFCI_CARD': 'MUGEFCI Card', 'choose_account.title': 'Mange accounts',
  'checkBox_label.DYN.VACCINATION': 'Vaccination card',
  'checkBox_label.DYN.HEALTH_CARD': 'Health card', 'choose_place.exchange_error': 'Can\'t change place',
  'checkBox_label.DYN.RESIDENT_CARD': 'Resident Card',
  'checkBox_label.DYN.CEDEAO_CARD': 'CEDEAO Card',
  'checkBox_label.DYN.ADDITIONAL_INFO': 'Personal Information', 'choose-lang.desc': 'Please choose the language which will be used for this application.',
  'choose-lang.title': 'Language preference',
  'chooseAccount.disconnectAll': 'Disconnect all accounts',
  'chooseAccount.edit': 'Edit mode',
  'chooseAccount.exit_edit': 'Exit edit mode',
  'chooseAccount.new_account': 'Add a new account',
  'claim.DYN.1': 'Identity card',
  'claim.DYN.10': 'Foreign identity card',
  'claim.DYN.11': 'E-mail de contact',
  'claim.DYN.12': 'Attestation RNPP',
  'claim.DYN.13': 'SOTRA card',
  'claim.DYN.14': 'Mugef-CI card',
  'claim.DYN.15': 'Contact address',
  'claim.DYN.18': 'Contact phone',
  'claim.DYN.2': 'Driving license',
  'claim.DYN.5': 'Health card',
  'claim.DYN.6': 'Passport',
  'claim.DYN.7': 'Transport card',
  'claim.DYN.8': 'Resident card',
  'claim.DYN.9': 'CEDEAO card',
  'claimSelf.RefusalTitle': 'Reason for refusal',
  'client.badState': 'Not in a good state',
  'client.body.auth': 'You could not be authenticated',
  'client.body.missing': 'The request has been missing parameters',
  'client.body.notFound': 'Resource not found',
  'client.extended.alreadyExecuted': 'Operation has already been fully executed',
  'client.extended.badPayload': 'API call not well formed',
  'client.extended.badSigState': 'User signature state does not allow operation',
  'client.extended.cannotAcceptDocument': 'There seems to be a problem with your document. Please retry. If the problem persists, please contact our support team.',
  'client.extended.crlRevoked.DYN.server': 'The scanned credential has been revoked. Try Again.',
  'client.extended.customerExists.DYN.server': 'The scanned ID card is already in use. Please try again.',
  'client.extended.emailAccountExist.DYN.server': 'This email address is already in use.',
  'client.extended.invalidUuid.DYN.server': 'The scanned QR code is invalid. Please try again.',
  'client.extended.largeUpload': 'Too large upload. Ask our support for the premium version for unlimited uploads.',
  'client.extended.locked': 'User is locked',
  'client.extended.lockedTenant': 'Company is locked, contact your administrator',
  'client.extended.mainAccountExists': 'An account exists with the email being invited. Please log in as this email to follow the invite. You don\'t own the account? Contact us!',
  'client.extended.missingResource': 'Resource not found or available',
  'client.extended.missingResourceLogin.DYN.server': 'Either the account does not exist, or the password is incorrect, or the account is not verified',
  'client.extended.needAbovePlan': 'You cannot go to this billing plan now, contact suppoprt',
  'client.extended.noEnroll': 'Cannot self enroll, please contact us',
  'client.extended.notActiveToken': 'Your session has expired. Please log in again.',
  'client.extended.notAllSigned': 'All parties have not yet signed the minutes',
  'client.extended.notArchived': 'Cannot update an archived resource',
  'client.extended.notSignable': 'This item cannot be signed now. Is the file available yet? Is it your turn to sign?',
  'client.extended.notVerified': 'Cannot do if not verified yet',
  'client.extended.noVirtual': 'Cannot apply on signature session',
  'client.extended.otherIdentity.DYN.server': 'You must use the same identity card you registered with. Please try again with the correct card.',
  'client.extended.overlaps': 'Range overlaps with other folios or is invalid',
  'client.extended.pendingEmail': 'Cannot log in, email not verified yet',
  'client.extended.phoneAccountExist.DYN.server': 'This phone number is already in use.',
  'client.extended.pinNotReady': 'Too many attempts, try again in a few seconds.',
  'client.extended.processed': 'Already processed',
  'client.extended.requireOtpLogin': 'Your account is secured with a validation code.',
  'client.extended.sequenceDupped': 'Sequence duplicated',
  'client.extended.smartIdNoCert': 'Cannot retrieve signing certificate',
  'client.extended.tooEarly': 'The request comesin too early',
  'client.extended.userBound': 'User is already bound',
  'client.extended.uuidExpired.DYN.server': 'The scanned QR code has expired. Please try again.',
  'client.extended.wrongAuth': 'Not authorized to perform action',
  'client.extended.wrongFace': 'The face on the photo does not match the face on the document.',
  'client.extended.wrongGeo': 'Your geolocation was evaluated to a non allowed country',
  'client.password': 'Bad password on protected resource',
  'client.puzzle': 'Client signature was wrong...',
  'close.expire': 'Warning, your session will expire in 3 minutes. Do you want to extend it?',
  'close.expire.offline': 'You will be logged out of your pro account in 3 minutes. Being offline, you can reconnect if you do not find the internet during this time.',
  'connect.back': 'Back to login',
  'connectPro.back': 'Back to adding pro account',
  'create_place.control': 'Control permissions',
  'create_place.create': 'Creation permissions',
  'create_place.createPlace.error': 'Can\'t create place',
  'create_place.errorDuplicate': 'This place already exists under the same ID. Please choose another.',
  'create_place.setAdmin.desc': 'The place has been successfully created. You may now assign a user as administrator of this place',
  'create_place.setAdmin.title': 'Set a place admin',
  'create_place.title': 'Create new place',
  'create_place.updatePlace.error': 'Can\'t update place',
  'current.open': 'Opening in progress...',
  'current.open.lowBandWidth': 'Opening in progress...</br> (low bandwidth)',
  'desktop.noNNi': 'Your account is not activated, please activate your account first via the mobile app before logging in via the website.',
  'device.noNfc': 'This feature is only available with a device with NFC technology. Please try again with another device.',
  'devicePermissionsDYN.BLUETOOTH.title': 'Bluetooth permission required',
  'devicePermissionsDYN.CAMERA.title': 'Permission needed: Camera',
  'devicePermissionsDYNBLUETOOTH.desc1': 'It is required that you grant bluetooth permissions to the app in order for it to work properly. Please activate permissions.',
  'devicePermissionsDYNCAMERA.desc1': 'It is required that you grant camera permissions to the app in order for it to work properly. Please activate permissions.',
  'devicePermissionsDYNNFC.desc1': 'It is required that you grant NFC permissions to the app in order for it to work properly. Please activate permissions.',
  'devicePermissionsDYNNFC.title': 'NFC permission required',
  'disconnect.allOtherDevices': 'Disconnect all other devices',
  'email.DYN.title': 'Contact email',
  'err.accountValidated': 'Your account is already validated',
  'err.addMeetingQuestions': 'Could not add questions to the meeting',
  'err.addMeetingUser': 'Cannot add user',
  'err.address.changeRequired': 'You must fill in the address fields to continue.',
  'err.address.incomplete': 'All address fields must be filled',
  'err.address.invalidType': 'Invalid document type uploaded for address',
  'err.address.requiredPdf': 'Pdf document required for address',
  'err.addressLine1.minlength': 'Address line 1 too short',
  'err.addressLine1.required': 'Line 1 of address is required',
  'err.addressLine2.minlength': 'Address line 2 too short',
  'err.addressLine2.required': 'Line 2 of address is required',
  'err.addTag': 'Error creating tag',
  'err.addTagValue': 'Error crating tag value',
  'err.addTranslation': 'Error adding translation',
  'err.bindMeeting': 'Cannot claim session',
  'err.BIRTH_DATE.required': 'Birth date is required',
  'err.BIRTH_PLACE.pattern': 'Birth place doesn\'t match the pattern',
  'err.BIRTH_PLACE.required': 'Birth place is required',
  'err.birthDate.required': 'Birth date is required',
  'err.camera.notfound': 'No camera\'s found',
  'err.CAN.minlength': 'Can number is too short',
  'err.CAN.pattern': 'CAN Nr. doesn\'t match the pattern',
  'err.CAN.required': 'Can number is required',
  'err.cancelMeeting': 'Could not cancel meeting',
  'err.canNr.pattern': 'CAN Nr. doesn\'t match the pattern',
  'err.canNr.required': 'CAN nr. is required',
  'err.certificate.required': 'Certificate is required',
  'err.city.minLength': 'City name too short',
  'err.city.pattern': 'Invalid city format',
  'err.city.required': 'City is required',
  'err.closeKyc': 'Cannot statute on KYC',
  'err.code.required': 'Email code is required',
  'err.commentMeeting': 'Cannot add comment',
  'err.complete2FA': 'Error completing 2FA process',
  'err.confirm': 'Cannot confirm email',
  'err.consumeDelegation': 'Cannot consume delegation',
  'err.consumeLogin': 'Error consuming login',
  'err.consumeLoginWeak': 'Cannot login',
  'err.contact': 'Error contacting server for sending message',
  'err.countries.required': 'Country is required',
  'err.country.required': 'Country required',
  'err.COUNTRY.required': 'Country is required',
  'err.DATE_OF_BIRTH.max': 'The date of birth has to be in the past',
  'err.DATE_OF_BIRTH.min': 'The date of birth is too old',
  'err.DATE_OF_BIRTH.required': 'Date of birth is required',
  'err.DEVICE_NAME.required': 'Device name is required',
  'err.DISTRICT.required': 'District is required',
  'err.DIVISION.required': 'Division is required',
  'err.DOC_EXPIRY_DATE.max': 'The document expiry date is too far in the future',
  'err.DOC_EXPIRY_DATE.min': 'The document expiry date has to be in the future',
  'err.DOC_EXPIRY_DATE.required': 'Expiry date is required',
  'err.DOC_NUMBER.required': 'Document number is required',
  'err.docNr.pattern': 'Document Nr. doesn\'t match the pattern',
  'err.docNr.required': 'Document Nr. is required',
  'err.email.email': 'Email must have a correct format: email@mail.com',
  'err.email.invalidFileType': 'Incorrect file format uploaded for the contact email.',
  'err.email.required': 'Email is required',
  'err.email.requiredPdf': 'Missing PDF document for the contact email',
  'err.eraseMeeting': 'Cannot erase meeting',
  'err.err': 'Error',
  'err.err.DYN.server': 'Your connection to the server has been lost. Please wait for the server to become available again in order to continue.',
  'err.expiryDate.required': 'Expiry date is required',
  'err.exportTenant': 'Cannot get company data',
  'err.fileSize': 'File exceeded the size limit: ',
  'err.fileType': 'Invalid file type ',
  'err.FIRST_NAME.pattern': 'First name doesn\'t match the pattern',
  'err.FIRST_NAME.required': 'First name is required',
  'err.GENDER.required': 'Gender is required',
  'err.getTenantElements': 'Cannot get paid elements',
  'err.HEIGHT.required': 'Height is required',
  'err.id.required': 'ID is required',
  'err.identifier.pattern': 'Invalid identifier',
  'err.identifier.required': 'Identifier is required',
  'err.info': 'Error retrieving user info',
  'err.ISSUE_DATE.required': 'Issue date is required',
  'err.ISSUE_PLACE.required': 'Issue place is required',
  'err.lang.required': 'Language is required',
  'err.LAST_NAME.pattern': 'Last name doesn\'t match the pattern',
  'err.LAST_NAME.required': 'Last name is required',
  'err.LOCATION.required': 'Location is required',
  'err.loginWeak: Could not validate user': 'Unable to validate user.',
  'err.loginWeak.DYN.server': 'Your connection to the server has been lost. Please wait for the server to become available again in order to continue.',
  'err.MIDDLE_NAME.pattern': 'Middle name doesn\'t match the pattern',
  'err.MIDDLE_NAME.required': 'Middle name is required',
  'err.mrz.required': 'MRZ is required',
  'err.name.required': 'Name is required',
  'err.NATIONALITY.pattern': 'Nationality doesn\'t match the pattern',
  'err.NATIONALITY.required': 'Nationality is required',
  'err.NFC.required': 'NFC is required',
  'err.nni.required': 'National identity number is required',
  'err.NNI.required': 'National Identity Number is required',
  'err.old.required': 'Old password is required',
  'err.oldPassword.minlength': 'Your password must contain at least 10 characters.',
  'err.oldPassword.required': 'Your old password is required.',
  'err.otp.pattern': 'Wrong validation code format',
  'err.otp.required': 'Verification code (OTP 1) is required',
  'err.otp2.required': 'Verification code (OTP 2) is required',
  'err.password.maxlength': 'Password must have between 10 and 30 characters',
  'err.password.minlength': 'Password must have between 10 and 30 characters',
  'err.password.required': 'Password is required',
  'err.password2.minlength': 'Password must have between 6 and 20 characters',
  'err.password2.mustMatch': 'Passwords do not match',
  'err.password2.required': 'Password retype is required',
  'err.PERSONAL_NUMBER.required': 'Personal number is required',
  'err.phone_nr.pattern': 'Invalid phone number',
  'err.phone_nr.required': 'Phone number required',
  'err.phone.invalidFileType': 'Incorrect file format uploaded for the contact phone number.',
  'err.phone.pattern': 'The phone number must be 10 digits long and start with 01, 05, or 07',
  'err.phone.required': 'The phone number is required',
  'err.phone.requiredPdf': 'Missing PDF document for the contact phone number',
  'err.PIN.minlength': 'Pin code is too short',
  'err.PIN.required': 'Pin code is required',
  'err.place.required': 'Place is required',
  'err.postalCode.pattern': 'Invalid postal code',
  'err.postalCode.required': 'Postal code required',
  'err.PROFESSION.pattern': 'Profession doesn\'t match the pattern',
  'err.PROFESSION.required': 'Profession is required',
  'err.proofPdf.required': 'Proof required',
  'err.rcs.required': 'Company nr. is required',
  'err.rearm': 'Cannot resend confirmation email',
  'err.region.minLength': 'Region name too short',
  'err.region.pattern': 'Invalid region format',
  'err.region.required': 'Region required',
  'err.register': 'Could not create account',
  'err.requiredField': 'A required field is empty, please try again',
  'err.reset': 'Cannot ask for reset code',
  'err.resetPassword': 'Cannot reset password',
  'err.resetQuestion': 'Cannot invalidate votes on question',
  'err.setPassword': 'Cannot set credentials',
  'err.setPicture': 'Cannot set picture',
  'err.setPin': 'Cannot set credentials',
  'err.signature.required': 'Signature is required',
  'err.SIGNATURE.required': 'Signature is required',
  'err.street.required': 'Street name is required',
  'err.streetNr.required': 'Street number is required',
  'err.streetNumber.required': 'Street nr. is required',
  'err.SUBLOCATION.required': 'Sublocation is required',
  'err.tenantLang.required': 'Language is required',
  'err.terms.required': 'Terms and conditions must be accepted',
  'err.tva.required': 'TVA number is required',
  'err.unlockPin': 'Cannot unlock PIN',
  'err.zip.pattern': 'Invalid postal code',
  'err.zip.required': 'Postal code is required',
  'error.basic': 'An unexpected error has occurred',
  'error.nfc': 'An error occurred while reading the NFC chip. You might have entered false data in the steps before. Please check your data and try again.',
  'error.no_cordova': 'This feature is not available on your device',
  'error.noPlace': 'You must first choose a location in order to continue.',
  'exp.pro.session': 'Your session has expired. You have been redirected to your citizen account.',
  'exp.session': 'Your session has expired, please log in again.',
  'exp.session.offline': 'You will be logged out of your pro account in 3 minutes. You can reconnect to it once your internet is back.',
  'explain.personnal.change': 'Your verified data has been modified. It is awaiting validation or has been refused by one of our agents. You can still share your validated data.',
  'explain.personnal.noVerify': 'Your data has not been verified and has been rejected by one of our agents. Please modify it and try again.',
  'explain.personnal.verify': 'Your data is verified and can be shared.',
  'explain.personnal.waiting': 'Your data has not been verified and is awaiting validation by one of our agents.',
  'external.down': 'External server gone away',
  'faqExternalUrl.DYN.role.0': 'https://www.notion.so/satoris/Guide-utilisateur-Citoyen-8345b59ac872401d9cc43385aa39807e?pvs=4',
  'faqExternalUrl.DYN.role.1': 'https://www.notion.so/satoris/Guide-utilisateur-Professionnel-126ab2a1eb9143a0819b7c810f32b2e3?pvs=4',
  'faqExternalUrl.DYN.role.2': 'https://www.notion.so/satoris/Guide-utilisateur-Admin-8af486c469084a059b9d208f7c60d22c?pvs=4',
  'faceID.biometric.notGranted': 'You must authorize the use of your Face ID in order to continue',
  'faceID.button.set': 'Enable faceID',
  'faceID.button.unset': 'Disable faceID',
  'faceID.notGranted.desc': 'You must authorize the use of your Face ID in order to continue',
  'faceID.set.desc': 'Your FaceID is activated. However if you want to disable this feature, you can do so by clicking on the button below.',
  'faceID.unset.desc': 'Your FaceID is currently disabled. If you want to enable this feature to use it instead of your secret code, you can do so by clicking the button below.',
  'field.maybeError': 'Field comprising an error :',
  'fingerprint.button.set': 'Enable fingerprint',
  'fingerprint.desc': 'You can use your fingerprint instead of your secret code. If you wish, please place your finger on the fingerprint reader of your phone to link it.',
  'fingerprint.notGranted.desc': 'You need to grant permission to use your fingerprint to continue. Please authorize it and try again.',
  'fingerprint.set.desc': 'Your fingerprint is enabled. However, if you want to disable this feature, you can do so by clicking the button below.',
  'fingerprint.unset.desc': 'Your fingerprint is currently disabled. If you want to enable this feature to use it instead of your passcode, you can do so by clicking the button below.',
  'fingerprints.button.set': 'Use fingerprints',
  'fingerprints.button.unset': 'Unset fingerprints',
  'fingerprints.dialog.getSecret': 'Use fingerprints instead of PIN',
  'fingerprints.dialog.setSecret': 'Use biometrics instead of PIN',
  'fingerprints.register.error': 'Fingerprints were not registered.',
  'fingerprints.register.success': 'You\'ve successfully registered your fingerprints.',
  'fingerprints.unregister.success': 'Fingerprints were unregistered.',
  'forgotPassword.title': 'Forgot password',
  'forgotPin.desc': 'Did you forget your PIN or is your account locked after too many unsuccessful attempts? This security is essential for protecting your account. You can reset your PIN by clicking the button below.',
  'forgotPin.title': 'Forgot PIN',
  'form.address': 'Address',
  'form.birth_place': 'Place of birth',
  'form.birthDate': 'Birth date',
  'form.birthPlace': 'Birth place',
  'form.can': 'CAN N°',
  'form.center': 'Center',
  'form.city': 'City',
  'form.code': 'Code',
  'form.connectIpRange': 'IP range',
  'form.connectUaPattern': 'UA pattern',
  'form.country': 'Country',
  'form.country_of_birth': 'Country of birth',
  'form.dateOfBirth': 'Date of birth',
  'form.dateOfIssue': 'Date of issue',
  'form.district': 'District',
  'form.division': 'Division',
  'form.doc_nr': 'Document number',
  'form.docExpiryDate': 'Expiration date',
  'form.docNr': 'Document nr.',
  'form.docNumber': 'Document number',
  'form.driving_privileges': 'Driving privileges',
  'form.DYN.addressLine1': 'Address line 1',
  'form.DYN.addressLine2': 'Address line 2',
  'form.DYN.city': 'City',
  'form.DYN.countries': 'Country',
  'form.DYN.country': 'Country',
  'form.DYN.country.BEL': 'Belgium',
  'form.DYN.country.CIV': 'Ivory Coast',
  'form.DYN.country.GMB': 'Gambia',
  'form.DYN.country.KEN': 'Kenya',
  'form.DYN.country.LUX': 'Luxembourg',
  'form.DYN.country.UTO': 'Utopia',
  'form.DYN.country.ZWE': 'Zimbabwe',
  'form.DYN.email': 'Email',
  'form.DYN.phone_nr': 'Phone number',
  'form.DYN.postalCode': 'Postal code',
  'form.DYN.region': 'Region',
  'form.email': 'Email',
  'form.expiryDate': 'Expiry date',
  'form.father_name': 'Father\'s name',
  'form.firstName': 'First name',
  'form.gender': 'Gender',
  'form.gender.DYN.female': 'Female',
  'form.gender.DYN.male': 'Male',
  'form.gmt': 'Timezone',
  'form.gmtShiftMinutes.DYN-360': '-6 (NewYork, ...)',
  'form.gmtShiftMinutes.DYN-540': '-9 (San Fransisco, ...)',
  'form.gmtShiftMinutes.DYN0': '+0 (London, ...)',
  'form.gmtShiftMinutes.DYN60': '+1 (Luxembourg, Paris, Bruxelles, ...)',
  'form.height': 'Height in cm',
  'form.id': 'ID',
  'form.identifier': 'User ID',
  'form.issue_date': 'Issue date',
  'form.issueDate': 'Issue date',
  'form.issuePlace': 'Issue place',
  'form.issuer': 'Issuer',
  'form.issuing_authority': 'Issuing authority',
  'form.issuing_country': 'Issuing country',
  'form.issuingAuthority': 'Issuing authority',
  'form.lang': 'Language',
  'form.lang.DYN.EN': 'English',
  'form.lang.DYN.FR': 'Français',
  'form.lang.DYN.IT': 'Italiano',
  'form.lang.DYN.JA': 'Japanese',
  'form.lang.DYN.DE': 'Deutsch',
  'form.lang.DYN.CN': 'Chinese',
  'form.lang.DYN.RU': 'Russian',
  'form.lastName': 'Last name',
  'form.location': 'Location',
  'form.middleName': 'Middle name',
  'form.mother_name': 'Mother\'s name',
  'form.mrz': 'MRZ',
  'form.name': 'Name',
  'form.nationality': 'Nationality',
  'form.nationality.DYN.BEL': 'Belgian',
  'form.nationality.DYN.CIV': 'Ivorian',
  'form.nationality.DYN.GMB': 'Gambian',
  'form.nationality.DYN.KEN': 'Kenyan',
  'form.nationality.DYN.LUX': 'Luxembourgish',
  'form.nationality.DYN.UTO': 'Utopian',
  'form.nationality.DYN.ZWE': 'Zimbabwean',
  'form.nni': 'National Identity Number',
  'form.occupation': 'Occupation',
  'form.password': 'Password',
  'form.password_new': 'New password',
  'form.password_new2': 'Confirm new password',
  'form.password_old': 'Old password',
  'form.password2': 'Retype password',
  'form.personalNr': 'Personal nr.',
  'form.phoneNumber': 'Phone number',
  'form.pin': 'PIN code',
  'form.place': 'Place',
  'form.placeOfBirth': 'Place of birth',
  'form.plan.DYN0': 'Max 5 users',
  'form.plan.DYN1': 'Max 15 users',
  'form.plan.DYN2': 'Max 30 users',
  'form.plan.DYN3': 'Max 50 users',
  'form.plan.DYN4': 'Custom',
  'form.profession': 'Profession',
  'form.rcs': 'RCS nr.',
  'form.residence_adr': 'Residence address',
  'form.sms': 'Mobile phone number',
  'form.ssn': 'Social security number',
  'form.street': 'Street name',
  'form.streetNr': 'Street nr.',
  'form.sublocation': 'Sublocation',
  'form.subLocation': 'Sublocation',
  'form.support_document': 'Support document',
  'form.support_document_date': 'Support document date',
  'form.tenantLang.DYN0': 'English',
  'form.tenantLang.DYN1': 'French',
  'form.title_of_signing_authority': 'Title of signing authority',
  'form.town_of_residence': 'Town of residence',
  'form.tva': 'TVA nr.',
  'form.updatePlace': 'The information of the place has been modified.',
  'form.updatePlace.ask': 'Are you sure you want to modify the information of this place?',
  'form.user': 'User',
  'form.zip': 'Zip code',
  'generator.card': 'Card',
  'generator.country': 'Country',
  'getPin.faceCheck': 'We have found the following code for your identity card: <u><b>{pin}</b></u>. <br> Please keep it carefully for future use. <br> This code corresponds to your original code, therefore not applicable if you have modified it in the meantime.',
  'global.abo': 'Subscription',
  'global.accept': 'Accept',
  'global.approve': 'Approve',
  'global.back': 'Back',
  'global.clear': 'Clear',
  'global.close': 'Close',
  'global.confirm': 'Confirm',
  'global.continue': 'Continue',
  'global.disconnect': 'Disconnect',
  'global.error': 'An error occur, please retry',
  'global.eula': 'Terms of Service',
  'global.hello': 'Hello',
  'global.help_center': 'Help center',
  'global.more': 'More',
  'global.next': 'Next',
  'global.NNI': 'National number',
  'global.no': 'No',
  'global.no_data_available': 'No data available',
  'global.no_filtered_data': 'Zero results, try to reset filter',
  'global.or': 'Or',
  'global.privacy': 'Privacy Policy',
  'global.refresh': 'Refresh',
  'global.refusal': 'Refuse',
  'global.refuse': 'Refuse',
  'global.restore': 'Restore your subscription from account',
  'global.search': 'Search',
  'global.share': 'Share',
  'global.subscribe': 'Purchase a subscription',
  'global.upload': 'Upload',
  'global.uploaded': 'Image uploaded',
  'global.yes': 'Yes',
  'header.back': 'Back',
  'header.disconnect': 'Sign out',
  'identity_card.rotate': 'Rotate for bigger card',
  'iframe.ask.return': 'Are you sure you want to leave this service? Your changes will be lost.',
  'iframe.offline': 'You just lost your internet connection. Please wait until your connection is restored to continue or return to your main page.',
  'internal.db': 'Database outreach',
  'isClaimSelf.share': 'Sharing your personal data',
  'issuerAuth.expired.get': 'Your sharing token has expired. We will get it back. Please try again later...',
  'issuerAuth.recover': 'We are recovering your sharing token. Please wait...',
  'issuerAuth.recoverNoInternet': 'An error occurred while retrieving your share token. Please reconnect to the internet to try again.',
  'just.activatedAccount': 'Your account has been activated. You can now connect.',
  'jwt.extend': 'Extend',
  'localisation.desc.disabled': 'In order to proceed with the sharing, you need to activate your Location',
  'localisation.desc.noPermissions': 'In order to proceed with the sharing, you need to give permissions to use your location',
  'localisation.disabled': 'In order to proceed with the sharing, you need to activate your Location.',
  'localisation.title': 'Location permission required',
  'login.ask_otp.email': 'Please enter the 6-digit code you received by email.',
  'login.ask_otp.sms': 'Please enter the 6-digit code you received by sms.',
  'login.in': 'Identifier',
  'login.invalid': 'You have attempted to log in with a professional account not linked to your citizen account. Please reconnect using your personal account and enter the correct credentials.',
  'login.sign_in': 'Sign in',
  'lost.connection': 'Offline',
  'manage.alreadyOpen': 'This request has already been opened by another agent less than 5 minutes ago.Do you still want to continue?',
  'manage.personnal.pending': 'Pending verification',
  'manage.user.activation': 'Activation of a user',
  'manage.user.personnal-data': 'Personal data management',
  'manage.user.set_pro_account': 'Set a Pro account',
  'manage.user.title': 'Manage user',
  'managePlace.new_place': 'New place',
  'managePlace.search_place': 'Search place',
  'managePlace.set_place_admin': 'Set place admin',
  'managePlace.title': 'Manage place',
  'managePopup.helper': 'This page allows you to manage the personal data of users.If you see that the icon of a request is red, it is that it has already been opened by another agent below 5 minutes.',
  'manageSelf.noDate': 'Not open',
  'meeting.typeDYN0': 'Meeting',
  'meeting.typeDYN5': 'Draft',
  'modifyPersonnal.DYN.APPROVED.ask': 'Are you sure you want to change this approved data?',
  'modifyPersonnal.DYN.CHANGING.ask': 'Are you sure you want to modify this data? It will replace the previously validated one.',
  'modifyPersonnal.DYN.PENDING.ask': 'Are you sure you want to change this data? You\'ll lose your current request.',
  'modifyPersonnal.DYN.REJECTED.ask': 'Are you sure of your corrections?',
  'modifyPersonnal.noChange': 'You have not changed any data, only proof has been changed. Are you sure ?',
  'modifyPersonnal.refusal.void': 'Please change the requested corrections to continue.',
  'modifyPersonnal.void': 'Please make at least one change in order to continue.',
  'more.change_password': 'Change password',
  'more.change_pin': 'Change the secret code',
  'more.delete_user': 'Delete user',
  'more.delete_user_sure': 'Are you sure you wish to delete the current user. You\'ll be able to create a new account using its id card.',
  'more.language': 'Language',
  'more.purchasedSubscription': 'Subscription active',
  'more.purchasedSubscriptionLong': 'Your subscription is active and is set to auto-renew on the payment method you used initially. Should you want to modify your subscription, go to the subscriptions page of your mobile apps store.',
  'more.purchaseSubscription': 'Purchase a subscription',
  'more.security': 'Security',
  'more.security.faceid': 'Face ID',
  'more.security.faceID': 'Face ID',
  'more.security.fingerprint': 'Fingerprints',
  'more.security.otp': 'Two-factor authentication',
  'more.security.otp.email': 'Email Authentication',
  'more.security.otp.sms': 'Phone Authentication',
  'more.title': 'More',
  'mrzType.DYN.TD1': 'ID card',
  'mrzType.DYN.TD3': 'Passport',
  'MUGEFCI_CARD.DYN.select': 'Mugef-CI card',
  'nearest.activation.point': 'Nearest location',
  'news.modal': 'New feature',
  'news.title': 'News',
  'nfc.demo': 'NFC demo, please wait 5 seconds...',
  'nfc.error.icao': 'An error occurred while reading your NFC card. Your card was no longer detected during the scanning process. Please position your phone correctly on your card as indicated in the illustration.',
  'nfc.read.adminActivate': 'An error occurred while registering the user, please try again.',
  'nfc.read.confirmSc.error': 'An error has occurred. Either your PIN is incorrect, or your phone has lost the NFC connection with the card. Please re-enter your PIN and reposition your card as shown in the image.',
  'nfc.read.nfc_notfound': 'NFC not found, please activate it on your settings',
  'nfc.read.noMorePinTries': 'It looks like you entered the wrong pin too many times. Please go to a Oneci center to unlock your card.',
  'nfc.read.self': 'Scan NFC of ID card',
  'nfc.read.self.canError': 'An error has occurred. Either your CAN number is incorrect, or your phone has lost the NFC connection with the card. Please re-enter your NAC and reposition your card as shown in the image.',
  'nfc.read.self.form.desc': 'Please enter the CAN N° written on the bottom right corner of the ID card.',
  'nfc.read.self.pin.desc': 'Confirm your self enrollment by entering your ID card\'s PIN code.',
  'nfc.read.self.scan': 'Start the process',
  'nfc.read.self.scan.pinRemain': 'Remaining PIN tries',
  'no.connection': 'To access this page, you must be connected to the internet. Please log in again',
  'no.connection.signIn': 'You need internet to be able to sign in for the first time. Please try again later.',
  'no.personnalData': 'You currently have no personal data recorded or in the process of validation.',
  'noIDinQR.error': 'There is no ID in this QR-code',
  'notif.meetingIn.body': 'You\'ve been registered as involved in a new meeting to come on the platform.',
  'notif.meetingIn.title': 'New meeting incoming',
  'notif.meetingStart.body': 'A meeting in which you have a stake has just begun!',
  'notif.meetingStart.title': 'Meeting in progress',
  'notif.meetingStop.body': 'Formal processes of just closed meeting are on their way.',
  'notif.meetingStop.title': 'Meeting closed',
  'notif.recvMandate.body': 'You have received a proxy right on a meeting! Review it if you don\'t want to accept.',
  'notif.recvMandate.title': 'Proxy received',
  'notif.signIn.body': 'You\'ve been invited to sign a document',
  'notif.signIn.title': 'Invitation to sign a document',
  'notif.signTransfer.body': 'You\'ve been invited to sign an electronic document',
  'notif.signTransfer.title': 'Invitation to sign an electronic document',
  'notif.transferIn.body': 'You\'ve been invited to read a document',
  'notif.transferIn.title': 'Invitation to read a document',
  'otp.actual.DYN.0': 'Your current two-factor authentication is set to email',
  'otp.actual.DYN.1': 'Your current two-factor authentication is set to phone',
  'otp.desc.email': 'For email-based two-factor authentication, your primary email address will be used. As a reminder, your primary email is: ',
  'otp.desc.explain': 'To enhance your account\'s security, use two-factor authentication. You can choose to receive a one-time password (OTP) either via email or phone.',
  'otp.desc.sms': 'For phone-based two-factor authentication, please enter your mobile phone number. A one-time password (OTP) will be sent to you via SMS. Enter this code in the provided field to validate your two-factor authentication.',
  'otp.email.changePassword.error': 'Your password change was not successful. Make sure that the unique code received by email is correct.',
  'otp.email.success': 'Your email two-factor authentication has been successfully activated.',
  'otp.login.fail': 'Your code is incorrect. Try Again.',
  'otp.resend.email': 'Resend link by email',
  'otp.resend.phone': 'Resend code by SMS',
  'otp.sms.error': 'Your two-factor authentication via phone could not be activated. Please ensure that the unique code received via SMS is correct.',
  'otp.sms.success': 'Your phone two-factor authentication has been successfully activated.',
  'otp.successResend.email': 'Your link has been successfully resend.',
  'otp.successResend.sms': 'Your code has been resend successfully.',
  'passwordChanged.desc': 'Your new password has been set and you may now sign in.',
  'passwordChanged.title': 'Password has been changed',
  'passwordProChanged.desc': 'Your pro account password has been successfully changed.',
  'perm.settings.always': 'Open permissions',
  'perm.settings.once': 'Activate',
  'permission.perm1': 'Create',
  'permission.perm2': 'Consume',
  'permission.perm3': 'Control',
  'permission.perm4': 'Admin',
  'persoData.approved': 'This data has been verified.',
  'persoData.changing': 'Your modification is being validated.',
  'persoData.pending': 'This data is awaiting verification.',
  'persoData.rejected': 'This data requires a correction.',
  'personnal.verify.DYN.11': 'Email Verification',
  'personnal.verify.DYN.15': 'Address Verification',
  'personnal.verify.DYN.18': 'Phone Number Verification',
  'personnalAdd.explain': 'For added or modified personal data, please provide a document attesting to the veracity of this information.<br> <br> Following your request for adding or modification, one of our agent will check your information and will validate your request or not.',
  'personnalData.all': 'All your personal data',
  'personnalData.changing.refused': 'Your modification has been refused.Please correct the requested fields.',
  'personnalData.contact': 'Contact data',
  'personnalData.DYN.address': 'Address Information',
  'personnalData.DYN.contact': 'Contact Information',
  'personnalData.rejected.labelRefusal': 'The following field(s) were refused: {labelrefusal}.<br> <br> Please correct the refused field(s) and try again.',
  'personnalData.title': 'My personal data',
  'personnalHelperMessage.DYN.addressLine1': 'Please enter your contact address.This data will be verified and therefore requires proof such as the one below.',
  'personnalHelperMessage.DYN.email': 'Please enter your contact email.This data will not be verified and therefore does not require proof.',
  'personnalHelperMessage.DYN.phone_nr': 'Please enter your contact phone number.This data will be verified and therefore requires proof such as the one below.',
  'phone_nr.DYN.title': 'Contact phone number',
  'pin.biometric.badPin': 'Your pin code must be correct to activate biometrics. Try Again.',
  'pin.error.numeric': 'Please enter numbers only.',
  'pin.redirectDYN.claim-card_share-claim': 'To access your secure sharing QR code containing card information.',
  'pin.redirectDYN.claim-card_share-claim-mode': 'To access your secure sharing QR code containing card information.',
  'pin.redirectDYN.more_choose-account': 'To choose another account',
  'pin.redirectDYN.more_sign-in-pro': 'To add another account',
  'pin.redirectDYN.more-security_change-password': 'To change a password',
  'pin.redirectDYN.more-security-biometric_more-security-biometric': 'To enable biometric unlocking',
  'pin.redirectDYN.more-security-change-pin_secret-code': 'To reset your secret code.',
  'pin.redirectDYN.more-security-face-id_more-security-face-id': 'To activate your Face ID',
  'pin.redirectDYN.more-security-fingerprint_more-security-fingerprint': 'To activate your fingerprint',
  'pin.redirectDYN.personnal-data-all_share-claim': 'To share your personal data',
  'pin.redirectDYN.secret-code_user': 'To save this code for future connections',
  'pin.redirectDYN.welcome-biometric_welcome-done': 'To activate unlocking by biometric data',
  'pin.redirectDYN.scan-share_scan-share-result': 'To share your personal data with external services',
  'pin.reset.success': 'Your pin has been successfully reset.',
  'place_member.kick': 'Kick',
  'place-member.activity': 'Activity',
  'place-member.permissions': 'Permissions',
  'place.admin': 'Place administration',
  'place.member': 'Member management',
  'place.newMember': 'Add a new member',
  'place.scanner': 'Scan a user',
  'placeActivity.title': 'Activity of ',
  'placeMember.title': 'Members of ',
  'price.promo': '{pricePromo}/year',
  'price.real': '{price}/year',
  'principalMode.set': 'This device is now your primary device.',
  'profile.btn.change_password': 'Change password',
  'profile.newPassword': 'New password',
  'proof.download': 'Download the proof',
  'proof.error.ask': 'Error in the proof?',
  'proof.error.default': 'Your proof has an error. Please modify it.',
  'proof.show': 'View proof',
  'proofError.byAdmin': 'Your <b> proof </b> has been declined by one of our agents. Please modify it and try again.',
  'proofError.byAdmin.desc': '<u>Message: </u> <br> <i>{reasonMessage}</i>',
  'proofRefusal.desc': 'Please enter the reason for refusal :',
  'qr.invalid': 'The scanned QR is not valid',
  'recover.desc': 'An email has been sent to you containing a recovery code.',
  'recover.resetPassword.error': 'Error during resetting the password',
  'recover.title': 'Recover password',
  'refresh.alreadyOpen': 'Data recovery in progress. Please wait....',
  'refusal.ask': 'Validate the refusal?',
  'refusalClaimSelf.success': 'The refusal has been sent.',
  'route.noCBOR': 'Some required services are not available on your device',
  'route.online': 'You have been redirected because this page is not accessible in offline mode. Please reconnect to the internet to access it.',
  'scanner.setPlaceAdmin': 'Scan the QR code of the user\'s ID card or badge',
  'scanner.userActivate': 'Scan the QR code of the user\'s ID card',
  'scanResult.title': 'Scan result',
  'scanShare.desc': 'wishes to read the following data:',
  'scanShare.title': 'Request to share',
  'scanShare.touch': 'Touch the stack of documents to open it',
  'search_place.place_without_id.error': 'Place without place_id',
  'searchPlace.desc': 'Select or search a place below and click on it to manage it.',
  'searchPlace.title': 'Search place',
  'secret-code.codeOK.error': 'This PIN is not correct',
  'secretCode.desc': 'Create a 6-digit secret code that you will use to log in. Later, you can choose a fingerprint or facial recognition login method.',
  'secretCode.title': 'Set Secret Code',
  'secretCodeValid.msg': 'Enter your code',
  'secretCodeValid.title': 'Validate Secret Code',
  'security.false': 'We\'ve detected that your device is rooted. Unfortunately, for security reasons, our application does not support rooted devices. In order to enjoy our service, please use a non-rooted device.',
  'security.loader': 'Unauthorized device',
  'security.root': 'A "rooted" phone creates security risks. These risks could jeopardize the protection of your data, which we cannot allow.',
  'selectAdmin.offline.expired': 'Your pro account has expired. Please try to reconnect once you have internet access.',
  'self.workerAccount': 'My member profile',
  'selfClaim.DYN.address.success': 'Your contact address has been added. It is now being validated.',
  'selfClaim.DYN.addressLine1.modify': 'Your contact address has been changed. It is now being validated.',
  'selfClaim.DYN.addressLine1.success': 'Your contact address has been added. It is now being validated.',
  'selfClaim.DYN.email.modify': 'Your contact email has been changed.',
  'selfClaim.DYN.email.success': 'Your contact email has been added.',
  'selfClaim.DYN.phone_nr.modify': 'Your contact phone number has been changed. He is now being validated.',
  'selfClaim.DYN.phone_nr.success': 'Your contact phone number has been added.He is now being validated.',
  'service.birth_certificate': 'Request a birth certificate',
  'service.declaration_loss_theft': 'Declaration of loss or theft',
  'services.disabled': 'This feature is currently disabled. Please try again later.',
  'services.title': 'Services',
  'setPin.noMatch': 'The PIN code must be the same as the one you wrote previously',
  'setPlaceAdmin.action.desc1.auser': ' as admin of the ',
  'setPlaceAdmin.action.desc1.buser': 'Are you sure you wish to set user ',
  'setPlaceAdmin.action.desc2': 'The user will be granted with the permissions set on the next step.',
  'setPlaceAdmin.desc': 'Scan the QR code of a user\'s identity card or badge and choose a place to set the user as the administrator.',
  'setPlaceAdmin.done.desc1': 'The user has been set as admin of the selected place.',
  'setPlaceAdmin.done.desc2': 'As admin of the place the user has been granted permissions set during this process.',
  'setPlaceAdmin.done.title': 'All set',
  'setPlaceAdmin.form.desc': 'The place where the user will be set as admin.',
  'setPlaceAdmin.form.title': 'Select a place',
  'setPlaceAdmin.scan': 'Scan identity QR',
  'setPlaceAdmin.title': 'Set place admin',
  'setProAccount.action.desc1': 'Are you sure you wish to add the user to the place:',
  'setProAccount.action.desc2': 'The user will be granted all the permissions linked to this place once logged in with its Pro account.',
  'setProAccount.action.desc3': 'Please enter the user\'s email to get the new credentials.',
  'setProAccount.desc': 'Scan the QR code containing all of a user\'s ID card data to add them to an existing location.',
  'setProAccount.done.desc': 'The user has been added to the place. If an account was created, the new credentials can be found below.',
  'setProAccount.done.title': 'Done',
  'setProAccount.form.desc': 'Select the place where you want to add the user.',
  'setProAccount.form.title': 'New Pro Account',
  'setProAccount.scan': 'Scan identity QR',
  'setProAccount.title': 'Set Pro Account',
  'share-claim-done.desc': 'Your data was successfully shared with receiver!',
  'share-claim-done.title': 'Data were transmitted',
  'share.all': 'Share all data',
  'share.choose': 'Choose which data to share',
  'share.claim.offline': 'You cannot share your information in offline mode. Please reconnect to the internet to continue.',
  'share.claim.pinBlocked': 'An error occurred while sharing. Try Again.',
  'share.claims.iso.content': 'Do you agree to share {fields}?',
  'share.claims.iso.content.keptFields': 'Do you agree to share {fields}? Fields stored by the reader will be {keptFields}',
  'share.claims.iso.fields.DYN.age_over_16': 'See if you are over 16',
  'share.claims.iso.fields.DYN.age_over_18': 'See if you are over 18',
  'share.claims.iso.fields.DYN.age_over_21': 'See if you are over 21',
  'share.claims.iso.fields.DYN.age_over_60': 'See if you are over 60',
  'share.claims.iso.fields.DYN.date_of_birth': 'Date of Birth',
  'share.claims.iso.fields.DYN.doc_nr': 'Document Number',
  'share.claims.iso.fields.DYN.expiry_date': 'Expiry Date',
  'share.claims.iso.fields.DYN.first_name': 'First Name',
  'share.claims.iso.fields.DYN.gender': 'Gender',
  'share.claims.iso.fields.DYN.height': 'Height',
  'share.claims.iso.fields.DYN.image_url': 'Image',
  'share.claims.iso.fields.DYN.last_name': 'Last Name',
  'share.claims.iso.fields.DYN.middle_name': 'Middle Name',
  'share.claims.iso.fields.DYN.nationality': 'Nationality',
  'share.claims.iso.fields.DYN.place_of_birth': 'Place of Birth',
  'share.claims.iso.fields.DYN.profession': 'Profession',
  'share.claims.iso.fields.DYN.signature': 'Signature',
  'share.mode.desc': 'Using the wrong method will result in a failed attempt of sharing information',
  'share.mode.outside': 'Share outside the country (international)',
  'share.mode.within': 'Share within the country',
  'share.permissions.bluetoothOff': 'You have disabled your Bluetooth. Re-enable it, then please try again.',
  'share.permissions.locationOff': 'You have disabled your Location. Re-enable it, then please try again..',
  'shared.data': 'User personal data',
  'sign_in.identifier': 'Identifier',
  'sign_in.login_as_customer': 'Incorrect login: Please login as a citizen',
  'sign_in.other_device': 'is your main device. You can only have one main device at a time, which will have access to all available functions. Would you like this device to be your main device ?',
  'sign_in.password': 'Password',
  'signin.email': 'Sign in with email',
  'signIn.forgotPassword': 'Forgot your password ?',
  'signin.phone': 'Sign in with phone',
  'signin.state.email': 'You are currently signing in with <b>email</b>.',
  'signin.state.phone': 'You are currently signing in with <b>phone</b>.',
  'signIn.title': 'Access',
  'signInPro.title': 'Pro Access',
  'signup.desc': 'Create an account by <b>email</b> or <b>phone</b>.',
  'signup.email': 'Sign up with email',
  'signup.phone': 'Sign up with phone',
  'signup.state.email': 'You are currently signing up with <b>email</b>.',
  'signup.state.phone': 'You are currently signing up with <b>phone</b>.',
  'signUp.title': 'Account creation',
  'SOTRA_CARD.DYN.select': 'SOTRA card',
  'sub.buy': 'Buy your subscription now !',
  'sub.promo': 'Get 30% off now !',
  'subscribe.footer': 'This subscription automatically renews every year at a price of {price}, unless cancelled before next billing period. Fees are charged to the payment method chosen at confirmation of purchase. You can manage your subscriptions and turn off auto-renewal by going to your store subscriptions page.',
  'success.kickUser': 'This user has been deleted from this place.',
  'success.logoutAllDevices': 'All other devices have been disconnected.',
  'update_place.title': 'Update place',
  'upload.proof': 'Add proof',
  'user.activation': 'Activation',
  'user.activation.text': 'Please scan activation code and check all information',
  'user.alreadyAdded': 'This user is already added.',
  'user.choose_account': 'Change account',
  'user.choose_activity.DYN.account': 'User account activity',
  'user.choose_activity.DYN.shared': 'Sharing activity',
  'user.choose_place': 'Choose place',
  'user.manage_place_worker': 'Manage place',
  'user.manage_places': 'Manage places',
  'user.manage_users': 'Manage users',
  'user.my_badge': 'My badge',
  'userActivate.form.title': 'User activation',
  'userExist.already_registered': 'This user is already registered as an adminstrator for this location',
  'userPermissions.desc': 'Permissions of user <strong>{user}</strong> in location <strong>{place}</strong>',
  'userPermissions.done': 'Permissions have been set successfully',
  'userPermissions.error': 'Permissions were not set correctly',
  'userPermissions.title': 'Permissions',
  'userRoleDYN.0': 'Customer',
  'userRoleDYN.1': 'Professional',
  'userRoleDYN.2': 'Admin',
  'userRoleDYN.3': 'Super Admin',
  'userScanData.allowAdmin.desc': 'Manage places and users, list issued documents',
  'userScanData.allowAdmin.title': 'Has the right to admin',
  'userScanData.allowConsume.desc': 'Delete a document, list of issued documents',
  'userScanData.allowConsume.title': 'Has the right to consume',
  'userScanData.allowControl.desc': 'Check user information, View location OTP',
  'userScanData.allowControl.title': 'Has the right to control',
  'userScanData.allowCreate.desc': 'Create a document, list of issued documents',
  'userScanData.allowCreate.title': 'Has the right to create',
  'userScanData.desc': 'Rights of the user',
  'userScanData.desc2': 'at the place',
  'userShare.DYN.0': 'Share your national number',
  'userShare.DYN.1': 'Share the identity card',
  'userShare.DYN.10': 'Share the foreign identity card',
  'userShare.DYN.11': 'Contact email sharing',
  'userShare.DYN.12': 'Share the attestation RNPP',
  'userShare.DYN.13': 'Share the SOTRA card',
  'userShare.DYN.14': 'Share the Mugef-CI card',
  'userShare.DYN.15': 'Contact address sharing',
  'userShare.DYN.16': 'Share the health card',
  'userShare.DYN.18': 'Contact phone sharing',
  'userShare.DYN.2': 'Share the driving license',
  'userShare.DYN.3': 'Share your age proof',
  'userShare.DYN.5': 'Share the health card',
  'userShare.DYN.6': 'Share the passport',
  'userShare.DYN.7': 'Share the transport card',
  'userShare.DYN.8': 'Share the resident card',
  'userShare.DYN.9': 'Share the CEDEAO card',
  'welcomeBiometric.desc': 'You can activate biometric authentication to log in faster.',
  'welcomeBiometric.faceID.desc': 'Use your face with Face ID to log in faster and easier than with your PIN code.',
  'welcomeBiometric.fingerprint.desc': 'Use your fingerprint to log in faster and easier than with your PIN code.',
  'welcomeBiometric.title': 'Biometric authentication',
  'welcomeDone.desc1': 'We hope you enjoy using {name}. If you have any comments or suggestions, please send them to us using the Contact button on the home page.',
  'welcomeDone.desc2': 'Thank you for using {name}.',
  'welcomeDone.title': 'Enjoy !',
  'welcomeExplain.desc1': 'With {name}, you can register your official documents such as driver\'s license, identity card, and others, which will free you from the physical constraints of managing your documents.',
  'welcomeExplain.desc2': 'Once registered, you will be able to use your documents to identify yourself to an authorized agent or any other place necessary.',
  'welcomeExplain.title': 'What is {name}?',
  'welcomeHello.desc1': 'With {name}, keep your ID card handy and forget the hassle of managing it.',
  'welcomeHello.desc2': 'After registration, your documents will be available to identify you to an authorized agent or in any other situation where this is necessary.',
  'welcomeHello.title': 'Thank you for joining {name}',
  'workerAdmin.new_member': 'New member',
  'workerAdmin.see_activity': 'See activity',
  'workerAdmin.see_all_members': 'See all members',
  'noIdentity.QR.error': 'Bad QR code sharing. Please scan the QR code of a share containing all the data.',
  'ask.workerSelf.remove': 'Are you sure you want to remove your pro account?',

  'user.wrongOtp.server': 'Your code is incorrect. Try Again.',
  'choose.place.signInPro': 'Please choose one of the locations you are attached to in order to connect.',
  'ask.admin.remove': 'Are you sure you want to remove this administrator?',
  'place.alreadyChoose': 'You have already chosen this place.',
  'share.claims.iso.fields.DYN.family_name': 'Family Name',
  'share.claims.iso.fields.DYN.given_name': 'Given Name',
  'share.claims.iso.fields.DYN.birth_date': 'Birth Date',
  'share.claims.iso.fields.DYN.issue_date': 'Issue Date',
  'share.claims.iso.fields.DYN.issuing_country': 'Issuing Country',
  'share.claims.iso.fields.DYN.issuing_authority': 'Issuing Authority',
  'share.claims.iso.fields.DYN.document_number': 'Document Number',
  'share.claims.iso.fields.DYN.portrait': 'Portrait',
  'share.claims.iso.fields.DYN.driving_privileges': 'Driving Privileges',
  'share.claims.iso.fields.DYN.un_distinguishing_sign': 'Distinguishing Sign',
  'share.claims.iso.fields.DYN.administrative_number': 'Administrative Number',
  'share.claims.iso.fields.DYN.weight': 'Weight',
  'share.claims.iso.fields.DYN.eye_colour': 'Eye Colour',
  'share.claims.iso.fields.DYN.hair_colour': 'Hair Colour',
  'share.claims.iso.fields.DYN.birth_place': 'Birth Place',
  'share.claims.iso.fields.DYN.resident_address': 'Resident Address',
  'share.claims.iso.fields.DYN.portrait_capture_date': 'Portrait Capture Date',
  'share.claims.iso.fields.DYN.age_in_years': 'Age in Years',
  'share.claims.iso.fields.DYN.age_birth_year': 'Birth Year',
  'share.claims.iso.fields.DYN.issuing_jurisdiction': 'Issuing Jurisdiction',
  'share.claims.iso.fields.DYN.resident_city': 'Resident City',
  'share.claims.iso.fields.DYN.resident_state': 'Resident State',
  'share.claims.iso.fields.DYN.resident_postal_code': 'Resident Postal Code',
  'share.claims.iso.fields.DYN.resident_country': 'Resident Country',
  'share.claims.iso.fields.DYN.family_name_national_character': 'Family Name (National Character)',
  'share.claims.iso.fields.DYN.given_name_national_character': 'Given Name (National Character)',
  'share.claims.iso.fields.DYN.signature_usual_mark': 'Signature/Usual Mark',
  'ask.signout.resetPin': 'Are you sure you want to reset your PIN? This will also log you out of all devices.',
  'success.resetPinLogout': 'Your PIN has been successfully reset. You have been logged out of all devices.',
  'error.resetPinLogout': 'An error occurred while resetting your PIN. Please try again.',
  'ask.adminSelf.remove': 'Are you sure you want to remove your pro account from the sign-in options? You will be redirected to your citizen account.',
  'wait.otpCode': 'You have already received an OTP code recently. Please wait before requesting a new one.',
  'user.permissions': 'Edit permissions',
  'err.id.maxlength': 'The ID is too long, it must be a maximum of 40 characters',
  'err.connectIpRange.maxlength': 'The IP range is too long, it must be a maximum of 40 characters',
  'err.tva.maxlength': 'The VAT number is too long, it must be a maximum of 30 characters',
  'err.rcs.maxlength': 'The RCS number is too long, it must be a maximum of 30 characters',
  'err.streetNr.maxlength': 'The street number is too long, it must be a maximum of 15 characters',
  'err.zip.maxlength': 'The postal code is too long, it must be a maximum of 30 characters',
  'err.city.maxlength': 'The city name is too long, it must be a maximum of 60 characters',
  'client.extended.cannotLinkHigherOrWithoutUniqueId': 'You do not have the right to perform this action'
};
