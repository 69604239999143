import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {take, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Article, ArticleType} from '../models/article';
import {BackendMessage, Results} from '../models/backend-message';
import {
  Claim,
  ClaimSelfDetails,
  ClaimSelf,
  ClaimSelfForAdmin,
  ClaimType,
  Claims,
  UserScanData
} from '../models/claim';
import {PlatformEvent} from '../models/information';
import {Place} from '../models/place';
import {AccountType, DelegationType, HtmlTemplates, OtpType, RegisterResponse, User, UserDelegation, UserPermission, UserPlatformRole} from '../models/user';
import {Application} from './application.service';
import {DevicesService, I18nLang, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {SecureStorageService} from './secure-storage.service';
import {clearStoredItem, getStoredItem, removeStoredItem, setStoredItem} from '../utils/storage';
import {isAfter} from 'date-fns';
import {TimeoutError} from 'rxjs';
import {SyncService} from './sync.service';
import {InitializeService} from './initialize.service';

declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService extends Application {
  get accountJwt() {
    return this.account;
  }

  set accountJwt(token: AccountType) {
    clearTimeout(this.jwtExpirationWarning);
    clearTimeout(this.expirationJwt);
    if(token && token.jwt) {
      this.account = token;
      const body = JSON.parse(atob(this.account.jwt.split('.')[1]));
      if(body?.aud) {
        this.userPlaceId = JSON.parse(body.aud).place_id;
      }
      setStoredItem('jwt', this.account);
      const shortJwtExp = body.exp;
      /**
       * Set timeout to refresh the JWT token. Actual timeOut is 3 minutes before expiration
      */
      if(!this.accountTransition) {
        const userInfo = this.getUserInfoOfJwt(this.account);
        if(userInfo) {
          if((this.device.isDevices('cordova'))) {
            if(userInfo?.role !== UserPlatformRole.CUSTOMER) {
              this.setJwtExpirationTimeout(shortJwtExp, 3); // 3 minutes before expiration
            }
          } else {
            this.setJwtExpirationTimeout(shortJwtExp, 3); // 3 minutes before expiration
          }
        }
      }
    } else {
      removeStoredItem('jwt');
      this.account = undefined;
    }
  }


  accountTransition = false;
  jwtExpirationWarning: NodeJS.Timeout;
  expirationJwt: NodeJS.Timeout;
  infoInterval: NodeJS.Timeout;
  lastExchangeCall = 0;
  ct = ClaimType;
  isClaimsCached = false;
  intervalePingServer: NodeJS.Timeout;
  role = UserPlatformRole;
  savedForm: FormGroup;
  firstUnlockPin = false;
  currentlyUnlockingPin: any;
  claims: Claims = {} as Claims;
  isCheckingJwt = false;
  retrySecretCodeBtn = false;
  scannedSelfClaims = [] as ClaimSelfForAdmin[];
  userInfo: User;
  allJwtAcounts: AccountType[] = getStoredItem('jwts') || [];
  allUserInfos = getStoredItem('digid.cache.infos');
  userPlaceId: string;
  userPlaces: Place[];
  currentPlace: Place;
  pinUnlocked: boolean;
  resetPin = false;
  userRole: {
    isSuperAdmin: boolean;
    isWorker: boolean;
    isCustomer: boolean;
    isAdmin: boolean;
  };
  timeoutLowBandwidth = 3 * 1000; // 3 seconds

  env = environment;
  account: AccountType;
  setPrincipalMode = false;

  constructor(private http: HttpClient,
    protected override router: Router,
    protected override lang: LangService,
    protected device: DevicesService,
    private nav: NavigateService,
    private sync: SyncService,
    private secure: SecureStorageService,
    private loader: LoaderService,
    private intizialize: InitializeService) {
    super(router, lang, device);
  }

  setUserRole(user: User) {
    this.userRole = {
      isSuperAdmin: user.role === UserPlatformRole.SUPER_ADMIN,
      isWorker: user.role === UserPlatformRole.WORKER,
      isCustomer: user.role === UserPlatformRole.CUSTOMER,
      isAdmin: user.role === UserPlatformRole.ADMIN || user.role === UserPlatformRole.SUPER_ADMIN
    };
  }


  getClaim(type: ClaimType, claims: Claim[]): Claim {
    const claim: Claim = claims.find((c: Claim) => c.type === type);
    if((!claim.expiresAt || isAfter(new Date(claim.expiresAt), new Date())) && !claim.revokedAt) return claim;
    return undefined;
  }


  getClaimBody(type: ClaimType, claims: Claim[]): any {
    const claim = this.getClaim(type, claims);
    if(!claim) {
      return undefined;
    }
    const serializedClaim = JSON.parse(claim.serialized);
    if(type === ClaimType.IDENTITY) {
      return claim;
    } else {
      return {...claim, ...serializedClaim};
    }
  }


  signOut(forever=true, redirect=true) {
    clearTimeout(this.jwtExpirationWarning);
    clearTimeout(this.expirationJwt);
    clearInterval(this.infoInterval);
    removeStoredItem('setPrincipalMode');
    this.userInfo = undefined;
    this.userPlaceId = '';
    this.currentPlace = undefined;
    this.pinUnlocked = false;
    this.userRole = undefined;
    this.accountJwt = undefined;
    this.claims = {};
    if(forever) {
      this.isClaimsCached = false;
      removeStoredItem('jwt');
      removeStoredItem('welcomeDone');
      removeStoredItem('manualOffline');
      removeStoredItem('jwts');
      removeStoredItem('digid.cache.infos');
      removeStoredItem('lastToPinTime');
      removeStoredItem('currentQrData');
      this.sync.isOnlineSubject.next(this.sync.isOnline);
      removeStoredItem('updateClaims');
      removeStoredItem('biometric');
      removeStoredItem('selfClaimsCustomer');
      if(this.device.isDevices('cordova')) {
        this.secure.clearStore();
        this.secure.deleteEncryptSecureDatas('pin');
        this.secure.deleteEncryptSecureDatas('longJWt');
      }
      if(redirect) {
        window.initialQueryString = [];
        return this.router.navigate(['/home']);
      }
    }
  }

  contact(message: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/contact', {
        message: message
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  users(offset?: number, limit?: number): Promise<Results<User[]>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<User[]>>(environment.baseUri + 'users?1=1'
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe((res: Results<User[]>) => {
          this.processMessages(res);
          resolve(res);
        }, this.makeReject(reject, false));
    });
  }

  user(userId: number, timeoutMessage = true): Promise<User> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<User>>(environment.baseUri + 'user/' + userId)
        .pipe(timeout(20000)).pipe(take(1)).subscribe((res: Results<User>) => {
          this.processMessages(res);
          resolve(res.result);
        }, this.makeReject(reject, false, timeoutMessage));
    });
  }

  messaging(value: PushSubscription): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/messaging', value)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  shareCallback(service: string, requestId: string, shareClaims: {type: ClaimType, id: string, uuid: string}[]): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/share', {
        service: service,
        request_id: requestId,
        share_claims: shareClaims
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  getPin(nni: string, docNumber: string, picture: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'users/pin', {
        nni: nni,
        doc_nr: docNumber,
        picture: picture
      }).pipe(timeout(40000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  testFinger(userId: number, image: string, nni: string, finger: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'admin/users/' + userId + '/finger', {
        image: image,
        nni: nni,
        finger: finger
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  info(loader = true, timeoutMessage = false, timeoutBypass = true): Promise<User> {
    if(loader) {
      this.loader.loading(true);
    }
    return new Promise((resolve, reject) => {
      const key = getStoredItem('jwt').email;
      const cached = getStoredItem('digid.cache.infos') || {};
      const item = cached[key];
      if(this.sync.isOnline) {
        return this.http.get<Results<User>>(environment.baseUri + 'users/info')
          .pipe(timeout((key && item) ? this.timeoutLowBandwidth : 10000))
          .pipe(take(1))
          .subscribe((res: Results<User>) => {
            this.processMessages(res);
            // General set up thanks to having the user finally!
            this.userInfo = res.result;

            if(getStoredItem('lang') !== this.userInfo.lang) {
              setStoredItem('lang', this.userInfo.lang);
            }
            if(!this.userInfo.nationalNumber && !this.device.isDevices('cordova')) {
              this.signOut();
              setTimeout(() => {
                this.loader.loading(true, {type: 'warn', message: this.lang.transform('desktop.noNNi')});
              }, 500);
            }
            try {
              window.updateGlpi(this.lang.readLangString.toLowerCase(), this.userInfo.accountName, this.lang.transform('faqExternalUrl.DYN.role.' + this.userInfo.role));
            } catch {}
            this.setUserRole(this.userInfo);
            if(this.userInfo.accountName) {
              cached[this.userInfo.accountName] = this.userInfo;
            } else {
              cached[this.userInfo.identifier] = this.userInfo;
            }
            if(this.userInfo.isForcedSub && new Date(this.userInfo.isForcedSub).getTime() > Date.now()) this.intizialize.isPro = true;
            setStoredItem('digid.cache.infos', cached);
            const demoMode = getStoredItem('demoMode');
            if(demoMode !== undefined && this.userInfo.server.demoMode) {
              this.nav.demoMode = demoMode;
            } else {
              setStoredItem('demoMode', this.userInfo.server.demoMode);
              this.nav.demoMode = this.userInfo.server.demoMode;
            }
            // Return result
            resolve(res.result);
            if(loader)  {
              this.loader.loading(false);
            }
          }, (err: BackendMessage | TimeoutError) => {
            if(key && item && err instanceof TimeoutError && timeoutBypass) {
              this.userInfo = item;
              this.setUserRole(item);
              resolve(item);
              this.offlineManual();
              if(loader) this.loader.loading(false);
            } else {
              if(loader) this.loader.loading(false);
              return this.makeReject(reject, undefined, timeoutMessage)(err);
            }
          });
      } else {
        this.userInfo = item;
        this.setUserRole(item);
        resolve(item);
        this.loader.loading(false);
      }
    });
  }

  delegations(receiver: boolean): Promise<Results<UserDelegation>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<UserDelegation>>(environment.baseUri + 'users/delegations?receiver=' + receiver)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  deleteUser(): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'users/me')
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  events(received: boolean, offset?: number, limit?: number): Promise<Results<PlatformEvent>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<PlatformEvent>>(environment.baseUri + 'users/events?received=' + received
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  setPreferences(otpPhone?: string, otpAccount?: string, otpType?: OtpType, timeoutMessage = false): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/preferences', {
        lang: this.userInfo.lang,
        notifications_opt_outs: JSON.parse(this.userInfo.notificationsOptOuts),
        otp_phone: otpPhone,
        otp_account: otpAccount,
        otp_type: otpType
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject, undefined, timeoutMessage));
    });
  }

  consumeLoginWeak(accountName: string, password: string, otp?: string, placeId?: string, place_otp?: string, name?: string, longTerm = false, onlyConfirmOtp = false, htmlTemplate?: HtmlTemplates): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{ jwt: string }>>(environment.baseUri + 'users/login_weak', {
        account_name: accountName?.toLowerCase(),
        password: password,
        otp: otp || undefined,
        place_id: placeId || undefined,
        place_otp: place_otp || undefined,
        long_term: longTerm,
        only_confirm_otp: onlyConfirmOtp,
        html_template: htmlTemplate
      }, {observe: 'response'}).pipe(timeout(6000)).pipe(take(1)).subscribe(res => {
        if(res.body) {
          this.processMessages(res.body);
          this.userPlaceId = placeId ? placeId : undefined;
          this.accountJwt = {
            name: name ? name : accountName,
            email: accountName,
            jwt: res.body.result.jwt
          };
          this.lastExchangeCall = Math.floor(Date.now() / 1000);
        }
        resolve(res.status);
      }, this.makeReject(reject, false));
    });
  }

  exchange(placeId: string, place_otp?: string, longTerm = false, timeoutMessage = false): Promise<{ jwt: string }> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{jwt: string}>>(environment.baseUri + 'users/exchange', {
        place_id: placeId || undefined,
        place_otp: place_otp || undefined,
        long_term: longTerm
      }).pipe(timeout(5000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject, false, timeoutMessage));
    });
  }

  exchangeDelegated(delegationId: number, placeId: string, place_otp?: string, longTerm = false): Promise<{ jwt: string }> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{jwt: string}>>(environment.baseUri + 'users/exchange_delegated', {
        delegation_id: delegationId,
        place_id: placeId || undefined,
        place_otp: place_otp || undefined,
        long_term: longTerm
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject, false));
    });
  }

  badge(token?: string, timeoutMessage = true): Promise<string | UserScanData> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'users/badge', {
        token: token || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject, false, timeoutMessage));
    });
  }

  register(accountName: string, password: string): Promise<RegisterResponse> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<RegisterResponse>>(environment.baseUri + 'users/register', {
        account_name: accountName?.toLowerCase(),
        password: password
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res?.result), this.makeReject(reject));
    });
  }

  registerAdmin(accountName: string, password: string, role: UserPlatformRole, userId: string,
    createPlaceId?: string, consumePlaceId?: string, controlPlaceId?: string, adminPlaceId?: string,
    customerId?: number, customerNationalNumber?: string, isSystem?: boolean): Promise<{id: number, identifier?: string}> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{ id: number, identifier: string }>>(environment.baseUri + 'admin/users/register', {
        account_name: accountName?.toLowerCase(),
        password: password,
        role: role,
        user_id: userId,
        create_place_id: createPlaceId || undefined,
        consume_place_id: consumePlaceId || undefined,
        control_place_id: controlPlaceId || undefined,
        admin_place_id: adminPlaceId || undefined,
        customer_id: customerId,
        customer_national_number: customerNationalNumber,
        is_system: isSystem || false
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res?.result), this.makeReject(reject));
    });
  }

  rearm(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.get<undefined>(environment.baseUri + 'users/rearm/' + encodeURIComponent(accountName?.toLowerCase()))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  rearmSc(accountName: string, signingCertificate?: string, certificateChain?: string[]): Promise<RegisterResponse> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<RegisterResponse>>(environment.baseUri + 'users/rearm_sc/' + encodeURIComponent(accountName.toLowerCase()), {
        signing_certificate: signingCertificate,
        certificate_chain: certificateChain
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  reset(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/reset/' + encodeURIComponent(accountName.toLowerCase()), {})
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  receive(accountName: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/receive/' + encodeURIComponent(accountName.toLowerCase()), {})
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  confirm(userId: number, salt: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.get<undefined>(environment.baseUri + 'users/confirm/' + userId + '/' + salt)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  confirmSc(userId: number, salt: string, signature: string, deviceX: string, deviceY: string, deviceName: string, sodFile?: string, dg1File?: string, dg2File?: string,
    dg11File?: string, dg12File?: string, signatureFile?: string, portraitFile?: string, xyCert?: string, certificateChain?: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.baseUri + 'users/confirm_sc/' + userId + '/' + salt, {
        signature: signature,
        x: deviceX,
        y: deviceY,
        name: deviceName,
        sod_file: sodFile || undefined,
        dg1_file: dg1File || undefined,
        dg2_file: dg2File || undefined,
        dg11_file: dg11File || undefined,
        dg12_file: dg12File || undefined,
        signature_file: signatureFile || undefined,
        portrait_file: portraitFile || undefined,
        xy_certificate: xyCert || undefined,
        certificate_chain: certificateChain || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  confirmScPicture(nni: string, portrait: string, deviceX: string, deviceY: string, deviceName: string, sodFile: string, dg1File: string, dg2File: string,
    dg11File: string, dg12File: string, signatureFile?: string, xyCert?: string, certificateChain?: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.baseUri + 'users/confirm_sc_picture', {
        nni: nni,
        x: deviceX,
        y: deviceY,
        name: deviceName,
        portrait: portrait,
        sod_file: sodFile,
        dg1_file: dg1File,
        dg2_file: dg2File,
        dg11_file: dg11File,
        dg12_file: dg12File,
        signature_file: signatureFile || undefined,
        xy_certificate: xyCert || undefined,
        certificate_chain: certificateChain || undefined
      }).pipe(timeout(60000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  activateSc(userId: number, deviceX: string, deviceY: string, deviceName: string, sodFile: string, dg1File: string, dg2File: string, dg11File: string, dg12File: string, signatureFile: string, nni: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.baseUri + 'users/activate_sc/' + userId, {
        x: deviceX,
        y: deviceY,
        name: deviceName,
        sod_file: sodFile,
        dg1_file: dg1File,
        dg2_file: dg2File,
        dg11_file: dg11File,
        dg12_file: dg12File,
        signature_file: signatureFile,
        nni: nni
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  resetPassword(accountName: string, resetKey: string, password: string, otp?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/recover/' + encodeURIComponent(accountName.toLowerCase()), {
        reset_key: resetKey,
        password: password,
        otp: otp || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, reject);
    });
  }

  setPassword(password: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/password', {
        password: password
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  unlockPin(currentPin: string, timeoutMessage = true): Promise<undefined | { error: { errorKey: string; additionalInfo: string } }> {
    if(this.sync.isOnline) {
      return new Promise((resolve, reject) => {
        this.http.post<undefined>(environment.baseUri + 'users/unlock_pin', {
          current_pin: currentPin
        }).pipe(timeout(5000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject, undefined, timeoutMessage));
      });
    } else {
      return new Promise((resolve, reject) => {
        this.secure.getEncryptSecureDatas('pin').then((encryptedPin: string) => {
          if(encryptedPin !== currentPin) {
            resolve({
              error: {
                errorKey: 'client.body.notFound',
                additionalInfo: 'client.extended.wrongPin'
              }
            });
          } else {
            resolve(undefined);
          }
        }).catch(error => {
          reject({
            error: {
              errorKey: 'internal.db',
              additionalInfo: {message: error.message}
            }
          });
        });
      });
    }
  }

  setPin(pin: string, currentPin: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/set_pin', {
        pin: pin,
        current_pin: currentPin
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  logout(deltaMillis?: number): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/logout', {
        delta_millis: deltaMillis || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  places(userId: number, placeId: string, uniqueId: string, accountName: string,
    allowCreate: boolean, allowConsume: boolean, alowcontrol: boolean, allowAdmin: boolean): Promise<{id: number}> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<{ id: number }>>(environment.baseUri + 'admin/users/' + userId + '/places/' + placeId, {
        unique_id: uniqueId,
        account_name: accountName,
        allow_create: allowCreate,
        allow_consume: allowConsume,
        allow_control: alowcontrol,
        allow_admin: allowAdmin
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  lock(userId: number, lock: boolean): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/' + userId + '/lock', {
        lock: lock
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  createDelegation(nniDelegater: string, nniReceiver: string, expiry: Date, type: DelegationType): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<number>>(environment.baseUri + 'admin/delegations', {
        nni_delegater: nniDelegater,
        nni_receiver: nniReceiver,
        expiry: expiry ? expiry.getTime() : undefined,
        type: type
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  delegationExpiry(delegationId: number, expiry: Date): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/delegations/' + delegationId + '/expiry', {
        expiry: expiry ? expiry.getTime() : undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  adminSearchUser(search: string): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<User[]>>(environment.baseUri + 'users/search/' + encodeURIComponent(search))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  email2FA(html_template?: HtmlTemplates): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/2fa/email', {
        html_template: html_template
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  paySub(voucher: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'users/sub', {
        payment_id: voucher
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  payAdminSub(userId: number, voucher: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'admin/users/' + userId + '/sub', {
        payment_id: voucher
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  place(id: string, timeoutMessage = true): Promise<Place> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Place>>(environment.baseUri + 'place/' + id)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject, undefined, timeoutMessage));
    });
  }

  placeEvents(placeId: string, userId?: number, offset?: number, limit?: number, timeoutMessage = true): Promise<Results<PlatformEvent>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<PlatformEvent>>(environment.baseUri + 'place/' + placeId + '/events?1=1'
        + (userId ? '&userId=' + userId : '') + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject, undefined, timeoutMessage));
    });
  }

  listPlaces(ids: string[]): Promise<Place[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Place[]>>(environment.baseUri + 'places/list/' + ids.join('|'))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  searchPlace(search?: string, offset?: number, limit?: number): Promise<Results<Place[]>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Place[]>>(environment.baseUri + 'places/search?1=1' + (search ? '&search=' + encodeURIComponent(search) : '')
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  createPlace(id: string,
    longName: string,
    createClaims: ClaimType[],
    controlClaims: ClaimType[],
    tva?: string,
    rcs?: string,
    street?: string,
    streetNumber?: string,
    zip?: string,
    city?: string,
    country?: string,
    gmtShiftMinutes?: number,
    placeLang?: number,
    preferences?: string,
    b64_logo?: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'places/create', {
        id: id,
        long_name: longName,
        create_claims: createClaims,
        control_claims: controlClaims,
        tva: tva,
        rcs: rcs,
        street: street,
        street_number: streetNumber,
        zip: zip,
        city: city,
        country: country,
        gmt_shift_minutes: gmtShiftMinutes,
        place_lang: placeLang,
        preferences: preferences || undefined,
        b64_logo: b64_logo || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  updatePlace(place: Place): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'place/' + place.id + '/update', {
        long_name: place.longName,
        connect_ua_pattern: place.connectUAPattern,
        connect_ip_range: place.connectIPRange,
        create_claims: place.createClaims,
        control_claims: place.controlClaims,
        tva: place.tva,
        rcs: place.rcs,
        street: place.street,
        street_number: place.streetNumber,
        city: place.city,
        country: place.country,
        gmt_shift_minutes: place.gmtShiftMinutes,
        place_lang: place.placeLang,
        preferences: place.preferences,
        b64_logo: place.b64Logo,
        zip: place.zip
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  deletePlace(placeId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'place/' + placeId)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  getClaims(skim = true): Promise<Claim[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Claim[]>>(environment.baseUri + 'claims?1=1' + (skim ?  '' : '&skim=false'))
        .pipe(timeout(10000), take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  getClaimsProcess(skim = true): Promise<Claim[]> {
    if(!this.device.isDevices('cordova')) {
      return this.getClaims(skim);
    } else {
      return this.secure.getEncryptedDatas('claims')
        .then(values => {
          if(values && values.length > 0) {
            const claims = values.map(v => ({
              ...JSON.parse(v.dataToEncrypt),
              ...JSON.parse(v.dataToStore)
            }));
            this.isClaimsCached = true;
            return claims;
          } else {
            return this.getClaims(skim);
          }
        });
    }
  }

  updateClaimsFromApi(invalidType: 'invalidFull' | 'invalidSkim', claimsIssuerAuthInvalid: Claim[]): Promise<Claim[]> {
    const updateClaimsDates = JSON.parse(localStorage.getItem('updateClaims')) || {};
    if(claimsIssuerAuthInvalid.length > 0) {
      const claimUpdatePromises = claimsIssuerAuthInvalid.map(claim => this.fetchClaim(claim.id, this.secure.fullJwk?.x, this.secure.fullJwk?.y, true));
      return Promise.all(claimUpdatePromises);
    }
    if(invalidType === 'invalidFull') {
      return this.getClaimsProcess(false).then(res => {
        updateClaimsDates.lastFullUpdate = new Date().toISOString();
        const claims = res.reduce((obj, claim) => ({...obj, [claim.id]: claim}), {});
        localStorage.setItem('updateClaims', JSON.stringify(updateClaimsDates));
        return claims as Claim[];
      });
    }
    if(invalidType === 'invalidSkim') {
      return this.getClaimsProcess().then(res => {
        updateClaimsDates.lastSkim = new Date().toISOString();
        localStorage.setItem('updateClaims', JSON.stringify(updateClaimsDates));
        return res;
      });
    }
    return Promise.resolve([]);
  }

  fetchClaim(id: string, deviceX: string, deviceY: string, skim = false): Promise<Claim> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<Claim>>(environment.baseUri + 'claim/' + id + '/fetch' + (skim ? '?skim=true' : ''), {
        x: deviceX,
        y: deviceY
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.sync.isDataSkimRefetch && this.makeReject(reject));
    });
  }

  shareClaim(id: string, deltaUntil: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'claim/' + id + '/share', {delta_until: deltaUntil})
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  readClaim(id: string, uuid?: string): Promise<Claim> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Claim>>(environment.baseUri + 'claim/' + id + (uuid ? '?uuid=' + uuid : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  searchClaim(placeId?: string, externalId?: string, createAtStart?: Date, createAtEnd?: Date, type?: ClaimType, offset?: number, limit?: number): Promise<Results<Claim[]>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Claim[]>>(environment.baseUri + 'claims/search?1=1' + (placeId ? '&placeId=' + encodeURIComponent(placeId) : '')
        + (externalId ? '&externalId=' + encodeURIComponent(externalId) : '') + (createAtStart ? '&createAtStart=' + createAtStart.getTime() : '')
        + (createAtEnd ? '&createAtEnd=' + createAtEnd.getTime() : '') + (type ? '&type=' + type : '')
        + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  createClaim(claim: Claim, nationalNumber: string, byNationalNumber?: string): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<number>>(environment.baseUri + 'claims/create', {
        expires_at: claim.expiresAt ? claim.expiresAt : new Date().getTime(),
        place_id: claim.place_id,
        user__id: claim.user__id,
        serialized: claim.serialized,
        external_id: claim.externalId,
        type: claim.type,
        fetchable: claim.fetchable,
        national_number: nationalNumber,
        by_national_number: byNationalNumber ? byNationalNumber : '',
        imageUrlRecto: claim.imageUrlRecto,
        imageUrlVerso: claim.imageUrlVerso
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  revokeClaim(claimId: string): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'claim/' + claimId)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  createSelfClaim(serialized: string, type: ClaimType, proof: string, proof2?: string, proof3?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'claims_self/create', {
        serialized: serialized,
        proof: proof || undefined,
        proof2: proof2 || undefined,
        proof3: proof3 || undefined,
        type: type
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  mySelfClaims(type?: ClaimType, offset?: number, limit?: number): Promise<ClaimSelf[]> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<ClaimSelf[]>>(environment.baseUri + 'claims_self/by/mine?1=1' + (type ? '&type=' + type : '')
      + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(10000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  openSelfClaims(type?: ClaimType, offset?: number, limit?: number): Promise<Results<ClaimSelf[]>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<ClaimSelf[]>>(environment.baseUri + 'claims_self?1=1' + (type ? '&type=' + type : '')
      + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res), this.makeReject(reject));
    });
  }

  getSelfClaim(claimSelfId: string): Promise<ClaimSelf> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<ClaimSelf>>(environment.baseUri + 'claims_self/' + claimSelfId)
        .pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res.result), this.makeReject(reject));
    });
  }

  judgeSelfClaim(claimSelfId: string, placeId: string, externalId?: string, rejectReason?: ClaimSelfDetails['state']['rejectReason'], expiresAt?: Date): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post<Results<string>>(environment.baseUri + 'claim_self/' + claimSelfId + '/judge', {
        expires_at: expiresAt?.getTime() || undefined,
        place_id: placeId,
        external_id: externalId || undefined,
        reject_reason: JSON.stringify(rejectReason) || undefined
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(res => resolve(res?.result), this.makeReject(reject));
    });
  }

  searchArticle(atDate?: Date, lang?: I18nLang, metaId?: string, types?: ArticleType[], offset?: number, limit?: number): Promise<Results<Article[]>> {
    return new Promise((resolve, reject) => {
      this.http.get<Results<Article[]>>(environment.baseUri + 'articles/search?1=1' + (atDate ? '&atDate=' + atDate.getTime() : '')
      + (lang ? '&lang=' + lang : '') + (metaId ? '&metaId=' + metaId : '') + (types ? '&types=' + types.join('-') : '')
      + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : ''))
        .pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  createArticle(article: Article): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'articles/create', {
        title: article.title,
        image: article.image,
        description: article.description,
        type: article.type,
        visibility_from: new Date(article.visibilityFrom).getTime(),
        visibility_to: new Date(article.visibilityTo).getTime(),
        date: article.date ? new Date(article.date).getTime() : undefined,
        location: article.location,
        link: article.link,
        lang: article.lang,
        content: article.content,
        meta_id: article.metaId,
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  updateArticle(article: Article): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.post<undefined>(environment.baseUri + 'article/' + article.id + '/update', {
        title: article.title,
        image: article.image,
        description: article.description,
        type: article.type,
        visibility_from: new Date(article.visibilityFrom).getTime(),
        visibility_to: new Date(article.visibilityTo).getTime(),
        date: article.date ? new Date(article.date).getTime() : undefined,
        location: article.location,
        link: article.link,
        lang: article.lang,
        content: article.content,
        meta_id: article.metaId
      }).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  deleteArticle(articleId: number): Promise<undefined> {
    return new Promise((resolve, reject) => {
      this.http.delete<undefined>(environment.baseUri + 'article/' + articleId).pipe(timeout(20000)).pipe(take(1)).subscribe(resolve, this.makeReject(reject));
    });
  }

  hasPermission(permission: UserPermission): boolean {
    if(!this.userRole?.isCustomer && this.userInfo) {
      if(this.userRole?.isSuperAdmin) {
        return true;
      } else {
        const current: any = this.userInfo.links.filter(x => x.place_id === this.userPlaceId)[0];
        if(current === undefined) {
          this.nav.to('choose-place');
          return false;
        } else {
          return !!current[permission];
        }
      }
    }
  }

  private semverAbove(ver: number[], ref: number[], strict = true) {
    for(let i = 0; i < ver.length && i < ref.length; i++) {
      if(ver[i] > ref[i]) return true;
      else if(ver[i] < ref[i]) return false;
    }
    return strict ? ver.length > ref.length : ver.length >= ref.length;
  }

  private processMessages(msg: BackendMessage) {
    if(!msg) return;
    if((msg.disableIphoneVersion && this.device.isDevices('cordova') && window.device.platform.toLowerCase() === 'ios' && this.semverAbove(msg.disableIphoneVersion, environment.appVersion, true))
      || (msg.disableAndroidVersion && this.device.isDevices('cordova') && window.device.platform.toLowerCase() !== 'ios' && this.semverAbove(msg.disableAndroidVersion, environment.appVersion, true))
      || (msg.disableWebVersion && !this.device.isDevices('cordova') && this.semverAbove(msg.disableWebVersion, environment.appVersion, true))) {
      this.nav.to('update-version');
      return;
    }
    if(msg.targetAppVersion && this.semverAbove(msg.targetAppVersion, environment.appVersion)) {
      if(this.device.isDevices('cordova')) {
        this.nav.to('update-version');
      } else {
        this.reloadSkipCache();
      }
      return;
    }
  }

  makeReject(reject: (error: any) => void, logout = true, timeoutMessage = true): (error: any) => void {
    let stack = 'err';
    try {
      stack = new Error().stack.split('\n')[5].match(/\.([^ ]+) /)[1];
    } catch (e) {
    }
    return (err: { error?: BackendMessage, status?: number } | TimeoutError | any) => {
      if(err instanceof TimeoutError) {
        if(this.device.isDevices('cordova')) {
          const welcomeDone = getStoredItem('welcomeDone');
          if(this.isCachedDatas() && welcomeDone !== undefined) {
            if(this.intizialize.firstLaunch) {
              this.offlineManual();
              return reject(err);
            } else {
              return this.ping().then(() => {
                if(timeoutMessage) {
                  setTimeout(() => {
                    this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
                  }, 500);
                } else {
                  return reject(err);
                }
              }).catch((err: any) => {
                if(err?.status === 0) {
                  const manualoffline = getStoredItem('manualOffline');
                  if(manualoffline === undefined) {
                    setStoredItem('manualOffline', true);
                    this.sync.isOnlineSubject.next(false);
                    this.loader.loading(false);
                    this.nav.to('user');
                  }
                } else {
                  if(timeoutMessage){
                    setTimeout(() => {
                      this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
                    }, 500);
                  }
                }
                return reject(err);
              });
            }
          } else {
            if(timeoutMessage) {
              setTimeout(() => {
                this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
              }, 500);
            }
            return reject(err);
          }
        } else {
          if(timeoutMessage) {
            setTimeout(() => {
              this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
            }, 500);
          }
          return reject(err);
        }
      } else if(err.status === 0) {
        if(this.isCachedDatas()) {
          const manualoffline = getStoredItem('manualOffline');
          if(manualoffline === undefined) {
            setStoredItem('manualOffline', true);
            this.sync.isOnlineSubject.next(false);
            this.nav.to('user');
            return reject(err);
          }
        } else {
          setTimeout(() => {
            this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
          }, 500);
          return reject(err);
        }
      } else if((err.status === 401 || err.status === 403) && logout) {
        if(this.device.isDevices('cordova')) {
          this.signOut();
          setTimeout(() => {
            this.loader.loading(true, {type: 'error', message: err.error && err.error.error && err.error.error.additionalInfo});
          }, 500);
          return;
        } else {
          this.signOut().then(() => location.href = '/', () => this.reloadSkipCache());
          return;
        }
      } else {
        const msg = err?.error?.error?.additionalInfo;
        const msgAlt = err?.error?.error?.additionalInfo2;
        const msg2 = err?.error?.error?.detailedInfo;
        if(msg || msgAlt || msg2) {
          reject(this.lang.transform('err.' + stack) + ': ' + this.lang.transform(msg) + (msg2 ? this.lang.transform(msg2) : '') + ' ' + (msgAlt ? this.lang.transform(msgAlt) : ''));
        } else {
          reject('err.' + stack);
        }
      }
    };
  }

  private reloadSkipCache() {
    if(navigator.serviceWorker) {
      Promise.all([
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for(const registration of registrations) {
            registration.unregister();
          }
        }),
        caches.keys().then(keys => Promise.all(keys.map(key => caches.delete(key))))
      ]).then(() => {
        clearStoredItem();
        window.location.reload(true);
      });
    } else {
      clearStoredItem();
      window.location.reload(true);
    }
  }

  /**
   * This function contains the logic for checking the JWT in various scenarios.This function contains the logic for checking the JWT in various scenarios.
   * @param debounce - If set to true, the API won't be called if the last call occurred less than 3 minutes ago.
   */
  checkJwt(debounce = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        const savedJwt = getStoredItem('jwt');
        const currentTime = Math.floor(Date.now() / 1000);
        const timeSinceLastCall = currentTime - this.lastExchangeCall;
        const shortJwtExp = JSON.parse(atob(savedJwt.jwt.split('.')[1])).exp;
        const isExpired = shortJwtExp < currentTime + 1;
        if(debounce && timeSinceLastCall < 180 && !isExpired) {
          return resolve();
        }
        this.isCheckingJwt = true;
        if(!this.sync.isOnline) {
          if(!isExpired) {
            return this.assignJwt(savedJwt).then(() => this.info().then(() => {
              this.lastExchangeCall = currentTime;
              resolve();
            }).catch(error => this.makeReject(error)));
          } else {
            if(this.getUserInfoOfJwt(savedJwt).role !== UserPlatformRole.CUSTOMER) {
              return this.assignJwt(this.getCustomerAccount(), true, false).then(() => this.info().then(() => this.loader.loading(true, {
                type: 'valid',
                message: this.lang.transform('exp.pro.session'),
                isWaiting: true
              })).then(() => this.nav.toPin(undefined, 'user'))).catch(error => this.makeReject(error));
            } else {
              return this.assignJwt(savedJwt, true, false).then(() => this.info().then(() => {
                resolve();
              }).catch(error => reject(error)));
            }
          }
        } else if(isExpired) {
          if(!this.device.isDevices('cordova')) {
            this.signOut();
            this.loader.loading(true, {
              type: 'error',
              message: this.lang.transform('exp.session')
            });
            return reject();
          } else {
            if(this.getUserInfoOfJwt(savedJwt).role !== UserPlatformRole.CUSTOMER) {
              clearTimeout(this.jwtExpirationWarning);
              clearTimeout(this.expirationJwt);
              this.accountTransition = true;
              this.loader.loading(true);
              return this.assignJwt(this.getCustomerAccount(), true).then(() => this.exchange(undefined, undefined).then(({jwt}) => this.assignJwt({...this.getCustomerAccount(), jwt}, false).then(() => this.info().then(() => {
                this.loader.loading(false);
                this.lastExchangeCall = currentTime;
                return resolve();
              }))).catch(error => {
                this.loader.loading(false);
                return this.makeReject(error);
              }));
            } else {
              this.loader.loading(true);
              return this.assignJwt(savedJwt, true).then(() => this.exchange(undefined, undefined).then(({jwt}) => this.assignJwt({...savedJwt, jwt}, false).then(() => this.info().then(() => {
                this.loader.loading(false);
                this.lastExchangeCall = currentTime;
                return resolve();
              }))).catch(error => {
                this.loader.loading(false);
                this.makeReject(error);
              }));
            }
          }
        } else {
          this.loader.loading(true);
          const aud = this.checkAud(savedJwt.jwt);
          const exchangeJwt = () => this.exchange(aud?.place_id ?? undefined, undefined).then(({jwt}) => this.assignJwt({...this.accountJwt, jwt}, false).then(() => {
            this.lastExchangeCall = currentTime;
            return this.linkedAccountExchange().then(() => {
              this.loader.loading(false);
              resolve();
            }).catch((err) => {
              this.loader.loading(false);
              reject(err);
            });
          })).catch(err => {
            this.loader.loading(false);
            reject(err);
          });
          const welcomeDone = getStoredItem('welcomeDone');
          return this.assignJwt(savedJwt, false).then(() => this.info().then(() => exchangeJwt())).catch(() => this.ping().then(() => this.info().then(() => exchangeJwt()).catch(error => {
            if(welcomeDone) {
              return this.makeReject(error);
            } else {
              this.signOut();
              setTimeout(() => {
                this.loader.loading(true, {type: 'error', message: 'global.error'});
              }, 500);
            }
          })).catch(error => {
            if(welcomeDone){
              return this.makeReject(error);
            } else {
              this.signOut();
              setTimeout(() => {
                this.loader.loading(true, {type: 'error', message: 'global.error'});
              }, 500);
            }
          }));
        }
      } catch (_e) {
        this.signOut();
        this.loader.loading(true, {type: 'error', message: this.lang.transform('global.error')});
        return reject();
      }
    }).finally(() => {
      this.isCheckingJwt = false;
    });
  }

  /**
   * Check and get the aud of the JWT
   * @param jwt - JWT to check
   * @returns
   */
  checkAud(jwt: string){
    let aud: any;
    try {
      const payload = JSON.parse(atob(jwt.split('.')[1])) || undefined;
      aud = JSON.parse(payload.aud) || undefined;
    } catch (error) {
      aud = undefined;
    }
    return aud;
  }


  /**
   *
   * Allow users to perform exchange for other accounts within our application.
   * The exchange will be based on the customerAccount's longJWT, and it will also verify the validity of the admin account.
   */
  linkedAccountExchange() {
    if(!this.sync.isOnline){
      return Promise.resolve();
    }
    const originalJwt = getStoredItem('jwt');
    const processAccount = (account: AccountType) => {
      if(account.email === originalJwt.email) {
        return Promise.resolve();
      }
      const userInfoAccount = this.getUserInfoOfJwt(account);
      if(userInfoAccount.role === UserPlatformRole.CUSTOMER && this.userRole.isCustomer) {
        return this.assignJwt(account, true).then(() => {
          this.exchange(undefined, undefined).then(({jwt}) => {
            const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === account.email ? {...account, jwt} : storedAccount);
            setStoredItem('jwts', updatedJwts);
          });
        }).catch(error => this.makeReject(error));
      } else {
        if(this.isJwtValid(account)) {
          const aud = this.checkAud(account.jwt);
          return this.assignJwt(account, false).then(() => {
            this.exchange(aud?.place_id ?? undefined, undefined).then(({jwt}) => {
              const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === account.email ? {...account, jwt} : storedAccount);
              setStoredItem('jwts', updatedJwts);
            }).catch(error => this.makeReject(error));
          });
        } else {
          return Promise.resolve();
        }
      }
    };

    const storedAccounts = getStoredItem('jwts');
    const accountPromises = storedAccounts.map(processAccount);
    return Promise.all(accountPromises).then(() => this.assignJwt(originalJwt, false));
  }


  /**
   * Check the validity of the JWT
   * @param accountToCheck - Account to check
   * @returns
   */
  isJwtValid(accountToCheck: AccountType) : boolean{
    try {
      const body = JSON.parse(atob(accountToCheck.jwt.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      if(body.exp < currentTime) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Get the user info of the JWT
   * @param jwtToCheck - JWT to check
   * @returns
   */
  getUserInfoOfJwt(jwtToCheck: AccountType): User{
    const digitInfos = getStoredItem('digid.cache.infos');
    if(digitInfos){
      return digitInfos[jwtToCheck?.email];
    } else {
      return undefined;
    }
  }


  /**
   * Assign the JWT to the account
   * @param accountToAssign - Account to assign
   * @param long - If we want to use the long JWT
   * @returns
   */
  assignJwt(accountToAssign: AccountType, long = false, assignLong = true): Promise<void> {
    return new Promise((resolve) => {
      if(long) {
        return this.secure.getEncryptSecureDatas('longJWt').then(longJwt => {
          const isLongJwtValid = this.isJwtValid({...accountToAssign, jwt: longJwt});
          if(!isLongJwtValid) {
            this.signOut();
            this.loader.loading(true, {type: 'error', message: this.lang.transform('exp.session')});
            return;
          }
          if(assignLong) {
            this.accountJwt = {...accountToAssign, jwt: longJwt};
            const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === accountToAssign.email ? accountToAssign : storedAccount);
            setStoredItem('jwts', updatedJwts);
          }
          return resolve();
        });
      } else {
        this.accountJwt = accountToAssign;
        const isAccountJwtValid = this.isJwtValid(accountToAssign);
        const isAccountToAssignJwtValid = this.isJwtValid(accountToAssign);
        if(!isAccountJwtValid || !isAccountToAssignJwtValid) {
          this.signOut();
          this.loader.loading(true, {type: 'error', message: this.lang.transform('exp.session')});
          return;
        }
        const updatedJwts = getStoredItem('jwts').map((storedAccount: AccountType) => storedAccount.email === accountToAssign.email ? accountToAssign : storedAccount);
        setStoredItem('jwts', updatedJwts);
        resolve();
      }
    });
  }

  /**
   * Get the customer account
   * @returns The customer account
   */
  getCustomerAccount(): AccountType {
    const allJwtAcounts = getStoredItem('jwts');
    const customerAccount = allJwtAcounts.find((account: AccountType) => {
      const userInfo = getStoredItem('digid.cache.infos')[account.email];
      return userInfo.role === UserPlatformRole.CUSTOMER;
    });
    return customerAccount;
  }

  /**
   * Add expiration timeout for the application. If the user is not a customer, we will show a warning message 3 minutes before expiration.
   * @param expirationTime The expiration time of the JWT token
   * @param delayMinutes The delay in minutes before the warning message is shown
   * @private
   */
  private setJwtExpirationTimeout(expirationTime: number, delayMinutes = 0) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiration = expirationTime - currentTime;
    const delaySeconds = delayMinutes * 60;
    if(timeUntilExpiration - delaySeconds >= 0) {
      this.jwtExpirationWarning = setTimeout(() => { // warning message before redirection
        if(this.sync.isOnline) {
          this.loader.loading(true, {
            type: 'warn',
            message: this.lang.transform('close.expire'),
            btnLabel: this.lang.transform('jwt.extend')
          }).then((result: boolean) => {
            if(result) {
              this.exchange(undefined, undefined).then(({jwt}) => {
                this.linkedAccountExchange().then(()=>{
                  this.lastExchangeCall = currentTime;
                  this.accountJwt = {...this.accountJwt, jwt};
                });
              });
            }
          });
        } else {
          this.loader.loading(true, {
            type: 'warn',
            message: this.lang.transform('close.expire.offline')
          });
        }
      }, (timeUntilExpiration - delaySeconds) * 1000);
    }

    /**
     * Handles the expiration of the JWT token. If the user is not a customer, the function attempts to switch to the customer account.
     * If there is no customer account available, the user will be signed out.
     * If the user is a customer, they will also be signed out.
     */

    this.expirationJwt = setTimeout(() => {
      clearTimeout(this.jwtExpirationWarning);
      this.loader.loading(false);
      if(this.userRole.isCustomer) {
        this.signOut(true);
        setTimeout(() => {
          this.loader.loading(true, {type: 'info', message: this.lang.transform(this.sync.isOnline ? 'exp.session' : 'exp.session.offline')});
        }, 500);
      } else {
        this.accountTransition = true;
        if(!this.sync.isOnline){
          this.checkJwt();
        } else {
          if(this.device.isDevices('cordova')) {
            this.signOut(false);
            this.accountJwt = this.getCustomerAccount();
            this.checkJwt().then(() => {
              this.nav.toPin('user', 'user');
              setTimeout(() => {
                this.loader.loading(true, {type: 'info', message: this.lang.transform('admin.disconnect')});
              }, 500);
            }).catch((error) => {
              this.makeReject(error);
            });
          } else {
            this.signOut(true);
            setTimeout(() => {
              this.loader.loading(true, {type: 'info', message: this.lang.transform(this.sync.isOnline ? 'exp.session' : 'exp.session.offline')});
            }, 500);
          }
        }
      }
    }, timeUntilExpiration * 1000);
  }

  logoutAllDevices(): Promise<boolean> {
    return this.checkJwt().then(() => {
      const iat = JSON.parse(atob(this.accountJwt.jwt.split('.')[1])).iat;
      if(!this.device.isDevices('cordova')) {
        return this.logout(Date.now() - (iat * 1000) + 30000).then(() => true);
      } else {
        return this.exchange(undefined, undefined, true).then((res) => this.secure.setEncryptSecureDatas('longJWt', res.jwt).then(() => this.logout((Date.now() - (iat * 1000)) + 30000).then(() => true)));
      }
    })
      .catch(() => false);
  }

  ping(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get<Results<string>>(environment.baseUri + 'ping')
        .pipe(timeout(5000)).pipe(take(1)).subscribe(res => resolve(res.result), reject);
    });
  }

  isCachedDatas() {
    const key = getStoredItem('jwt')?.email;
    const cached = getStoredItem('digid.cache.infos') || {};
    return key && cached[key];
  }

  offlineManual() {
    const manualoffline = getStoredItem('manualOffline');
    if(manualoffline === undefined) {
      setStoredItem('manualOffline', true);
      this.sync.isOnlineSubject.next(false);
      this.nav.to('user');
      setTimeout(() => {
        this.pingServer();
      }, 5000);
    }
  }

  pingServer() {
    clearInterval(this.intervalePingServer);
    this.intervalePingServer = setInterval(() => {
      this.ping().then(res => {
        if(res === 'pong') {
          clearInterval(this.intervalePingServer);
          removeStoredItem('manualOffline');
          this.sync.isOnlineSubject.next(true);
        }
      });
    }, 5000);
  }
}
