import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';

@Component({
  selector: 'app-activation-step-choice',
  templateUrl: './activation-step-choice.component.html',
  styleUrls: ['./activation-step-choice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepChoiceComponent {
  constructor(public api: ApiService, public enroll: EnrollService) {}
}
