import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {ScanService} from '../../../../shared/services/scan.service';
import {LangService, LoaderService, NavigateService} from 'ngx-satoris';

@Component({
  selector: 'app-activation-step-desc-take-face-picture',
  templateUrl: './activation-step-desc-take-face-picture.component.html',
  styleUrl: './activation-step-desc-take-face-picture.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepDescTakeFacePictureComponent {

  constructor(public api: ApiService,
    public enroll: EnrollService,
    private scan: ScanService,
    private lang: LangService,
    private nav: NavigateService,
    private loader: LoaderService) {}

  ngOnInit() {
    if(this.api.userRole.isAdmin && (!this.scan.userScanData || !this.scan.userScanData?.data?.sig)) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('api.createClaim.missingData')}).then(() => {
        this.nav.to('user');
      });
    }
  }
}
