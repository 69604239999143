<div id="layout" class="f_col"  [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-intro" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{(validDatas ? 'activation.step.scan_mrz_nfc.title2' : 'activation.step.scan_mrz_nfc.title') | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <div *ngIf="!validDatas" class="mt_50 f_col f_center f_grow">
      <app-progress-bar type='nfc' [showProgressBar]="false" [showProgressText]="false"></app-progress-bar>
      <app-paragraph class="mt_20" innerhtml="{{'activation.step.scan_mrz_nfc.desc' | translate}}"></app-paragraph>
      <div class="f_align_end f_grow ">
        <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      </div>
    </div>

    <ng-container *ngIf="validDatas">
      <div class="f_col f_align_c mt_40 mb_40">
        <div class="user_img">
          <img id="canvasProfileScanNFC" [src]="validDatas.image" alt="picture">
        </div>
      </div>
      <div class="f_grow">
        <ng-container *ngIf="validDatas['global_name']">
          <span class="user_info_label">{{'form.name' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="user" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["global_name"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['birth_date']">
          <span class="user_info_label">{{'form.birthDate' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="cake-candles" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["birth_date"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['country']">
          <span class="user_info_label">{{'form.country' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="house" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["country"] | translate}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['document_number']">
          <span class="user_info_label">{{'form.docNumber' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="hashtag" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["document_number"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['personal_number']">
          <span class="user_info_label">{{'form.personal_number' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="hashtag" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["personal_number"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['date_of_issue']">
          <span class="user_info_label">{{'form.dateOfIssue' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="calendar-check" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["date_of_issue"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['expiry_date']">
          <span class="user_info_label">{{'form.expiryDate' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="calendar-xmark" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["expiry_date"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['issuing_authority']">
          <span class="user_info_label">{{'form.issuingAuthority' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="building-columns" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["issuing_authority"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['place_of_birth']">
          <span class="user_info_label">{{'form.placeOfBirth' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="baby" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["place_of_birth"]}}
          </app-slide-element>
        </ng-container>

        <ng-container *ngIf="validDatas['issuer']">
          <span class="user_info_label">{{'form.issuer' | translate}}</span>
          <app-slide-element [noPointerEvent]="true" leftIcon="globe" rightIcon="none" class="mb_10 firstLetter">
            {{validDatas["issuer"]}}
          </app-slide-element>
        </ng-container>
      </div>
      <div class="f_align_end f_grow mt_20">
        <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
        <div class="fullWidth ml_10">
          <app-button (btnClicked)="enroll.navigate('next')" class="fullWidth">
            {{'global.continue' | translate}}
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
