/* tslint:disable */
export const fr_strings = {
  'activation.step.descFaceCheck.DYN': 'Nous allons procéder à une vérification de votre identité en utilisant la reconnaissance faciale.<br><br> Veuillez vous préparer à prendre une photo <b>bien centrée</b> de votre visage sur l’écran suivant.<br><br> Assurez-vous que votre visage est <b>entièrement visible, bien éclairé et sans obstruction.</b>',
  'activation.step.descFaceCheck.DYN.admin': 'Nous allons maintenant vérifier l’identité de la personne en utilisant la reconnaissance faciale.<br><br> Sur l’écran suivant, veuillez prendre une photo <b>bien centrée</b> de son visage.<br><br> Assurez-vous que son visage est <b>entièrement visible, bien éclairé et sans obstruction.</b>',
  'activation.step.descFaceCheck.title': 'Vérification de l’identité',
  'err.VOUCHER.pattern': 'Le format de voucher n\'est pas valide',
  'setup_pro_account_done.dialog': 'Veuillez vérifier que le destinataire a bien reçu l\'email ou noter les identifiants avant de fermer cette fenêtre.',
  'news.new.error': 'Une erreur est survenue lors de la suppression de l\'article. Veuillez réessayer.',
  'news.new.deleted': 'Article supprimé avec succès',
  'news.new.delete': 'Êtes-vous sûr de vouloir supprimer cet article ?',
  'global.delete': 'Supprimer',
  'scanner.processing': 'Traitement des données reçues',
  'scan_share.processing': 'Communiquer avec des service externe pour partager vos données',
  'placeSettings.img.success': 'L\'image du lieu a bien été mise à jour',
  'form.image': 'Image',
  'err.image.required': 'Vous devez choisir une image',
  'placeSettings.title': 'Paramètres du lieu',
  'workerAdmin.settings': 'Paramètres',
  'manageNews.update_article': 'Modifier l\'article',
  'version.update.title': 'Mise à jour disponible',
  'version.update.desc': 'Une nouvelle version de l\'application est disponible. Veuillez la mettre à jour pour continuer à utiliser l\'application.',
  'version.update.now': 'Mettre à jour (indisponible)',
  'version.update.later': 'Plus tard',
  'scannedBadge.no_name': 'Nom inconnu',
  'scannedBadge.no_place': 'Lieu inconnu',
  'scannedBadge.permissions.0': '<b>Création:</b> Peut créer un document.',
  'scannedBadge.permissions.1': '<b>Consommation:</b> Peut contrôler des documents.',
  'scannedBadge.permissions.2': '<b>Contrôle:</b> Peut vérifier les informations d\'un utilisateur.',
  'scannedBadge.permissions.3': '<b>Administrer:</b> Peut gérer les lieux et les utilisateurs.',
  'scannedBadge.claim.IDENTITY': 'N° national',
  'scannedBadge.claim.ID_CARD': 'Carte d\'identité',
  'scannedBadge.claim.DRIVING_LICENSE': 'Permis de conduire',
  'scannedBadge.claim.ADULT': 'Majorité',
  'scannedBadge.claim.VACCINATION': 'Vaccination',
  'scannedBadge.claim.HEALTH_CARD': 'Carte de santé',
  'scannedBadge.claim.PASSPORT': 'Passeport',
  'scannedBadge.claim.TRANSPORT_CARD': 'Carte de transport',
  'scannedBadge.claim.RESIDENT_CARD': 'Carte de résident',
  'scannedBadge.claim.CEDEAO_CARD': 'Carte CEDEAO',
  'scannedBadge.claim.ALIEN_CARD': 'Carte de séjour',
  'scannedBadge.claim.ADDITIONAL_INFO_CONTACT_EMAIL': 'Email',
  'scannedBadge.claim.RNPP': 'RNPP',
  'scannedBadge.claim.SOTRA_CARD': 'Carte SOTRA',
  'scannedBadge.claim.MUGEFCI_CARD': 'Carte MUGEFCI',
  'scannedBadge.claim.ADDITIONAL_INFO_ADDRESS': 'Adresse',
  'scannedBadge.claim.ADDITIONAL_INFO_HEALTH': 'Santé',
  'scannedBadge.claim.ADDITIONAL_INFO_BANK': 'Banque',
  'scannedBadge.claim.ADDITIONAL_INFO_CONTACT_PHONE_NR': 'N° de téléphone',
  'scannedBadge.member_of': 'membre de',
  'scannedBadge.reading': 'Autorisé à lire',
  'scannedBadge.permissions': 'Permissions',
  'error.DYN.place.no_control': 'Le lieu auquel votre compte est actuellement lié ne vous permet pas de contrôler ce type de demande.',
  'err.VOUCHER.minlength': 'Le code du ticket est trop court',
  'pin.redirectDYN.sign-in-pro_change-password': 'Pour changer votre mot de passe',
  'ask.signinpro.reset.password': 'Voulez-vous créer un nouveau mot de passe pour votre compte pro ?',
  'ask.worker.remove': 'Êtes-vous sûr de vouloir deconnecter ce compte ?',
  'user.notFound.DYN.server': 'Votre compte n\'a pas été trouvé. Veuillez réessayer.',
  'user.otpExpired.DYN.server': 'Votre code a expiré. Veuillez vous reconnecter',
  'user.wrongOtp.DYN.server': 'Votre code est incorrect. Veuillez réessayer.',
  'user.otpTooSoon.DYN.server': 'Vous avez essayé trop de fois. Veuillez attendre quelques secondes avant de réessayer.',
  'err.phoneCIV.required': 'Vous devez saisir un numéro de téléphone',
  'err.phoneCIV.pattern': 'Le numéro de telephone doit être composé de 10 chiffres et commencer par 01, 05 ou 07',
  'err.FINGERPRINT.required': 'Vous devez choisir un doigt',
  'userPermission.timeout': 'Votre connection internet est trop lente, nous n\'avons pas pu récupérer les données de cet utilisateurs. Voulez-vous réessayer ?',
  'co.unstable': 'Vous êtes actuellement en mode hors ligne. Il est possible que votre connexion soit trop instable ou que vous n\'ayez plus internet. Certaines fonctionnalitées ne sont donc pas disponibles.',
  'no.connection.server': 'Vous êtes actuellement en mode hors-ligne. Veuillez réessayer plus tard.',
  'advantage1': 'Débloquez les visuels de vos cartes',
  'advantage2': 'Partagez vos documents',
  'advantage3': 'Accédez aux services avancés',
  'alreadyAccount': 'Vous avez déjà un compte ?',
  'autoKickUser': 'Vous ne pouvez pas vous supprimer vous-même de cette place.',
  'cancel': 'Annuler',
  'change': 'Changer',
  'checkKickUser': 'Êtes-vous sûr de vouloir supprimer cet utilisateur de cette place ?',
  'confirm': 'Confirmer',
  'createAccount': 'Créer un compte',
  'date': 'Date',
  'explainHelpPersonnal': 'Ci-dessous vous pouvez retrouver la légende des différentes statuts des demandes de vos données personnelles : ',
  'field': 'Champ',
  'infinite': 'Infini',
  'issuerExpired': 'Votre token de partage a expiré. Nous sommes occupé à le renouveler. Veuillez réessayer plus tard...',
  'issuerExpiredNoInternet': 'Votre token de partage a expiré. Veuillez vous reconnecter à internet afin d\'en récupérer un nouveau.',
  'name': 'Nom',
  'nan': 'Pas disponible',
  'nfc-explain': 'Veuillez positionner le téléphone sur votre carte comme indiqué ci-dessus pour scanner la puce NFC. Une fois détectée, veillez à ne pas bouger votre carte ou votre téléphone.',
  'noAllClaimSelf': 'Aucune donnée personnelle enregistrée ou en cours de validation.',
  'none': 'Aucun',
  'notEnrolledBiometric': 'Vous n\'avez pas encore activé l\'authentification biométrique au sein de votre appareil. Veuillez le faire afin de continuer.',
  'noVerify': 'Rejeté',
  'ok': 'ok',
  'personnalDatas': 'Données personnelles',
  'price': 'Prix',
  'proof': 'Justificatif',
  'reset': 'Réinitialiser',
  'retry': 'Réessayer',
  'skip': 'Passer',
  'update': 'METTRE A JOUR',
  'uptodate': 'Vous êtes à jour !',
  'verify': 'Vérifié',
  'waiting': 'En attente',
  '11.DYN.title': 'Email de contact',
  '15.DYN.title': 'Adresse de contact',
  '18.DYN.title': 'Numéro de contact',
  'account.admin': 'Compte admin',
  'account.pro': 'Compte Pro',
  'account.removed': 'Le compte a bien été retiré.',
  'account.super_admin': 'Compte super admin',
  'accountPro.expired': 'Votre compte pro a expiré. Veuillez vous reconnecter.',
  'accountValid.desc': 'Votre compte a été créé mais doit maintenant être activé.',
  'accountValid.email.desc': 'Un e-mail de confirmation vous a été envoyé. Cliquez sur le lien qu\'il contient pour commencer à utiliser votre compte.',
  'accountValid.phone.desc': 'Un SMS de confirmation vous a été envoyé.  Cliquez sur le lien qu\'il contient pour commencer à utiliser votre compte.',
  'accountValid.title': 'Activation du compte',
  'activate.self': 'Activez vous-même votre compte',
  'activation_code.badge_error': 'Impossible d\'obtenir les données du code QR',
  'activation_code.no_scan': 'Impossible de scanner un autre citoyen.',
  'activation.code_info': 'Activer en',
  'activation.code.info.desc': 'Rendez-vous dans votre centre avec votre propre code QR d\'activation unique et sécurisé afin de créer votre compte et de commencer à utiliser votre carte d\'identité numérique.',
  'activation.code.info.title': 'Inscription en ',
  'activation.code.scan': 'Générer le code',
  'activation.desc': 'Votre compte a été créé mais doit maintenant être activé par l\'un de nos partenaires. Veuillez suivre les instructions ci-dessous.',
  'activation.step.admin.done.desc': 'Le compte a bien été validé. La carte d\'identité numérique de l\'utilisateur est désormais disponible sur son compte.',
  'activation.step.ask_can.title': 'Numéro CAN',
  'manageNewsArticles.title': 'Tous les articles',
  'news.new.updated': 'Article mis à jour avec succès',
  'news.new.no_lang': 'L\'article n\'a pas encore été traduit dans cette langue. Vous pouvez le traduire maintenant.',
  'form.thumbnail': 'Miniature',
  'articles.search_error': 'Erreur lors de la recherche des articles. Veuillez réessayer plus tard.',
  'global.edit': 'Modifier',
  'article.status.visible': 'Visible',
  'article.status.not_visible': 'Non visible',
  'article.type.INTERNAL_PRO': 'Fonctionnalité Pro interne',
  'article.type.INTERNAL_ADMIN': 'Fonctionnalité Admin interne',
  'article.type.GOVERNMENT_NEWS': 'Article gouvernemental',
  'article.type.LEGAL_UPDATE': 'Mise à jour légale',
  'article.type.REPORTS_AND_ANALYSIS': 'Rapports et analyses',
  'article.type.INTERVIEWS': 'Interviews',
  'article.type.LOCAL_NEWS': 'Article local',
  'article.type.INTERNATIONAL_NEWS': 'Article international',
  'article.type.OPINIONS_AND_EDITORIALS': 'Opinions et éditoriaux',
  'article.type.SPECIAL_REPORTS': 'Rapports spéciaux',
  'article.type.GUIDES_AND_TUTORIALS': 'Guides et tutoriels',
  'article.type.PRACTICAL_TIPS': 'Conseils pratiques',
  'article.type.FAQ': 'FAQ',
  'article.type.GOVERNMENT_CAMPAIGNS': 'Campagnes gouvernementales',
  'article.type.ENVIRONMENT_AND_SUSTAINABILITY': 'Environnement et durabilité',
  'article.type.CULTURAL_EVENTS': 'Événements culturels',
  'article.type.CULTURAL_HERITAGE': 'Patrimoine culturel',
  'article.type.SPORTS_NEWS': 'Actualités sportives',
  'article.type.POP_CULTURE': 'Culture pop',
  'news.new.success': 'Article créé avec succès. Souhaitez-vous le traduire maintenant dans une autre langue disponible ?',
  'news.publish': 'Publier',
  'news.fromTo': 'Période de visibilité',
  'err.to.required': 'Date de fin requise',
  'err.from.required': 'Date de début requise',
  'err.articleType.required': 'Type d\'article requis',
  'err.language.required': 'Langue requise',
  'article.language_choice': 'Langue de l\'article',
  'form.articleType': 'Type d\'article',
  'articleType.1': 'Fonctionnalité interne',
  'articleType.2': 'Article interne',
  'articleType.3': 'Fonctionnalité externe',
  'articleType.4': 'Article externe',
  'global.save': 'Enregistrer',
  'news.save_publish': 'Enregistrer et publier',
  'err.link.pattern': 'Lien invalide',
  'err.link.required': 'Lien requis',
  'form.link': 'Lien URL',
  'news.contentIsLink': 'Cet article est un lien vers un site externe',
  'news.content': 'Contenu',
  'err.title.required': 'Titre requis',
  'err.subtitle.required': 'Sous-titre requis',
  'err.thumbnail.required': 'Miniature requise',
  'err.content.required': 'Contenu requis',
  'form.title': 'Titre',
  'form.subtitle': 'Sous-titre',
  'news.thumbnail': 'Image de l\'article',
  'news.preview': 'Aperçu de la miniature',
  'global.select_image': 'Sélectionner une image',
  'manageNews.new_article': 'Nouvel article',
  'manageNews.articles': 'Tous les articles',
  'user.manage_news': 'Gestion des actualités',
  'subscription.noProduct': 'Aucun produit disponible pour le moment. Veuillez réessayer plus tard.',
  'subscription.error': 'Une erreur est survenue lors de l\'ouverture de la page d\'abonnement. Veuillez réessayer.',
  'info.getPin.valid': 'Code PIN a bien été récupéré. Vous pouvez refaire NFC scan pour continuer.',
  'question.getPin.confirm': 'Avez-vous bien pris connaissance de votre PIN?',
  'err.getPin.invalidImage': 'Le photo envoyé n\'est pas valide. Veuillez réessayer?',
  'err.checkPin': 'Impossible de recuperer votre code PIN',
  'activation.step.choice.ask_pin.nfc_scan_id_card': 'Si vous avez le code PIN, veuillez l\'utiliser pour continuer.<br/>Sinon, vous pouvez obtenir votre <strong>code originel</strong> par authentification de votre visage. <br/><br/><strong>Attention:</strong> si le code PIN de la carte a été modifié au moins une fois, il ne pourra pas être récupéré',
  'activation.step.choice.ASK_PIN': 'J\'ai un code PIN de ma carte',
  'activation.step.choice.NFC_SCAN_ID_CARD': 'J\'ai perdu mon code PIN',
  'err.getPin.noMatch': 'Malheureusement, aucune correspondance n\'a été trouvée dans notre base de données. Veuillez réessayer.',
  'err.activationCodeMap.unableToGetYourLocation': 'Impossible d\'obtenir votre position.',
  'err.activationCodeMap.notSupported': 'Geolocation n\'est pas supportée par ce navigateur.',
  'err.fingerprint.captureTimeout': 'Le scan de l\'empreinte digitale a pris trop de temps. Veuillez réessayer.',
  'client.extended.noFingerAuth': 'Les empreintes digitales ne correspondent pas, vérifiez si le doigt est sélectionné correctement et essayez de numériser à nouveau.',
  'fingerprint.read.self.form.desc': 'Veuillez choisir un doigt et cliquer sur le bouton Continuer pour commencer la procédure de verification.',
  'fingerprint.read.self.form.desc2': 'Placez le doigt sélectionné dans la zone de balayage et attendez quelques secondes pour que la vérification soit terminé.',
  'activation.step.fingerprint.title': 'Verification du doigt',
  'fingerprint.init.init_failed': 'L\'appareil n\'a pas été initialisé. Veuillez relancer l\'application.',
  'err.fingerprint.noDevicePresentYet': 'Aucun appareil n\'a été trouvé. Veuillez relancer l\'application.',
  'err.fingerprint.sdkNotInitialized': 'L\'appareil n\'a pas été initialisé. Veuillez relancer l\'application.',
  'err.fingerprint.noCordova': 'Disponible uniquement sur téléphone.',
  'form.finger': 'Doigt pour scanner',
  'activation.step.fingerprint.fingerOption.DYNunknown': 'Doigt inconnu',
  'activation.step.fingerprint.fingerOption.DYNrightThumb': 'Pouce droit',
  'activation.step.fingerprint.fingerOption.DYNrightIndex': 'Index droit',
  'activation.step.fingerprint.fingerOption.DYNrightMiddle': 'Doigt du milieu de la main droite',
  'activation.step.fingerprint.fingerOption.DYNrightRing': 'Annulaire droite',
  'activation.step.fingerprint.fingerOption.DYNrightLittle': 'Petit doigt de la main droite',
  'activation.step.fingerprint.fingerOption.DYNleftThumb': 'Pouce gauche',
  'activation.step.fingerprint.fingerOption.DYNleftIndex': 'Index gauche',
  'activation.step.fingerprint.fingerOption.DYNleftMiddle': 'Doigt du milieu de la main gauche',
  'activation.step.fingerprint.fingerOption.DYNleftRing': 'Annulaire gauche',
  'activation.step.fingerprint.fingerOption.DYNleftLittle': 'Petit doigt de la main gauche',
  'otp.pattern': 'Le code doit être composé de 6 chiffres uniquement.',
  'user.otpTooSoon.server': 'Vous avez essayé trop de fois. Veuillez attendre quelques secondes avant de réessayer.',
  'err.password.mustNotMatch': 'Le nouveau mot de passe doit être différent de l\'ancien mot de passe.',
  'err.oldPassword.customPatternRequirements': 'L\'ancien mot de passe doit contenir au moins 3 des éléments suivants : lettre majuscule, lettre minuscule, chiffre, caractère spécial.',
  'err.password.customPatternRequirements': 'Le mot de passe doit contenir au moins 3 des éléments suivants : lettre majuscule, lettre minuscule, chiffre, caractère spécial.',
  'more.setPrimaryDevice': 'Définir comme appareil principal',
  'form.deviceName': 'Nom de l\'appareil',
  'activation.step.ask_deviceName.desc': 'Veuillez entrer un nom pour cet appareil. Ce nom sera utilisé pour identifier cet appareil dans la liste des appareils autorisés.',
  'activation.step.ask_deviceName.title': 'Nom de l\'appareil',
  'activation.step.ask_nni.desc': 'Veuillez entrer votre <b>numéro d\'identité nationale</b> ci-dessous:',
  'activation.step.ask_nni.title': 'Numéro d\'Identité Nationale',
  'activation.step.ask_pin.title': 'Code pin',
  'activation.step.choice': 'Le processus suivant peut être fait de plusieurs façons. Choisissez l\'option qui vous convient le mieux ci-dessous :',
  'activation.step.choice.DYN.ASK_PIN': 'J\'ai le code PIN de ma carte',
  'activation.step.choice.DYN.ask_pin.nfc_scan_id_card': 'Si vous avez le code PIN, veuillez l\'utiliser pour continuer.<br/>Sinon, vous pouvez obtenir votre <strong>code originel</strong> par authentification de votre visage. <br/><br/><strong>Attention:</strong> si le code PIN de la carte a été modifié au moins une fois, il ne pourra pas être récupéré',
  'activation.step.choice.DYN.DOC_NUMBER': 'Entrer manuellement les données',
  'activation.step.choice.DYN.img.ask_device_name.form_user': 'assets-countries/choice.svg',
  'activation.step.choice.DYN.ask_device_name.form_user': 'Choisissez une mode d\'activation',
  'activation.step.choice.DYN.ASK_DEVICE_NAME': 'Activation par empreinte digitale',
  'activation.step.choice.DYN.VOUCHER': 'Activer l\'abonnement',
  'activation.step.voucher.title': 'Activer l\'abonnement',
  'activation.step.voucher.desc': 'Entrez un ticket pour continuer à activer votre compte',
  'form.voucher': 'Code du ticket',
  'qr.userNotRead': 'Impossible de lire les données du compte scanné',
  'qr.voucherNotOk': 'Ce ticket ne peut être utilisé, merci de réessayer',
  'activation.step.choice.DYN.ASK_FORM_USER': 'Activation en mode manuel',
  'activation.step.choice.DYN.FORM_USER': 'Activation en mode manuel',
  'activation.step.choice.DYN.img.scan_mrz_scan.doc_number': 'assets-countries/choice.svg',
  'activation.step.choice.DYN.NFC_SCAN_ID_CARD': 'Je ne connais pas mon code PIN',
  'activation.step.choice.DYN.SCAN_MRZ_SCAN': 'Scanner le code MRZ',
  'activation.step.choice.DYN.scan_mrz_scan.doc_number': 'Veuillez scanner le code MRZ de votre document (série de <b>lettres</b> et de <b>chiffres</b> avec <b><<<</b> à l\'arrière du document) ou bien entrer quelques informations de la carte manuellement ?',
  'activation.step.choice.DYN.scan_mrz.doc_number': 'Souhaitez-vous scanner le code MRZ de votre document (série de <b>lettres</b> et de <b>chiffres</b> avec <b><<<</b> à l\'arrière du document) ou bien entrer quelques informations de la carte manuellement ?',
  'activation.step.choice.title': 'Choisissez votre méthode d\'activation',
  'activation.step.confirm.fc.DYN.PROFESSION': 'Profession',
  'activation.step.confirm.fc.DYN.BIRTH_PLACE': 'Lieu de naissance',
  'activation.step.confirm.fc.DYN.HEIGHT': 'Taille',
  'activation.step.confirm.fc.DYN.FIRST_NAME': 'Prenom',
  'activation.step.confirm.fc.DYN.MIDDLE_NAME': 'Second prenom',
  'activation.step.confirm.fc.DYN.LAST_NAME': 'Nom de famille',
  'activation.step.confirm.fc.DYN.GENDER': 'Sexe',
  'activation.step.confirm.fc.DYN.NATIONALITY': 'Nationalité',
  'activation.step.confirm.fc.DYN.COUNTRY': 'Pays',
  'activation.step.confirm.fc.DYN.PERSONAL_NUMBER': 'Numéro personnel',
  'activation.step.confirm.fc.DYN.DISTRICT': 'District',
  'activation.step.confirm.fc.DYN.ISSUE_PLACE': 'Place d\'issue',
  'activation.step.confirm.fc.DYN.ISSUE_DATE': 'Date d\'issue',
  'activation.step.confirm.fc.DYN.SUB_LOCATION': 'Sous-division',
  'activation.step.confirm.fc.DYN.DIVISION': 'Division',
  'activation.step.confirm.fc.DYN.LOCATION': 'Localisation',
  'activation.step.confirm.fc.DYN.OCCUPATION': 'Occupation',
  'activation.step.confirm.fc.DYN.CAN': 'Numéro CAN',
  'activation.step.confirm.fc.DYN.PIN': 'Code PIN',
  'activation.step.confirm.fc.DYN.USER_ID': 'Identifiant de l\'utilisateur',
  'activation.step.confirm.fc.DYN.DEVICE_XY': 'XY de l\'appareil',
  'activation.step.confirm.fc.DYN.DATE_OF_BIRTH': 'Date de naissance',
  'activation.step.confirm.fc.DYN.DEVICE_NAME': 'Nom de l\'appareil',
  'activation.step.confirm.fc.DYN.DOC_EXPIRY_DATE': 'Date d\'expiration',
  'activation.step.confirm.fc.DYN.DOC_NUMBER': 'Numéro de document',
  'activation.step.confirm.fc.DYN.FACE_PICTURE': 'Photo de votre visage',
  'activation.step.confirm.fc.DYN.FINGERPRINT': 'Empreinte digitale',
  'activation.step.confirm.fc.DYN.MRZ': 'Code MRZ',
  'activation.step.confirm.fc.DYN.NFC': 'Scan NFC',
  'activation.step.confirm.fc.DYN.NNI': 'Numéro d\'identité nationale',
  'activation.step.confirm.fc.DYN.SIGNATURE': 'Signature',
  'activation.step.confirm.title': 'Résumé des étapes',
  'activation.step.date_of_birth.desc': 'Veuillez entrer votre <b>date de naissance</b> ci-dessous:',
  'activation.step.date_of_birth.title': 'Date de naissance',
  'activation.step.doc_exp_date.desc': 'Veuillez entrer la <b>date d\'expiration</b> de votre document ci-dessous:',
  'activation.step.doc_exp_date.title': 'Date d\'expiration',
  'activation.step.doc_number.desc': 'Veuillez entrer le <b>numéro de votre document</b> ci-dessous:',
  'activation.step.doc_number.title': 'Numéro du document',
  'activation.step.done.desc': 'Vous avez activé votre compte avec succès.',
  'activation.step.done.title': 'Activation terminée',
  'activation.step.draw_signature': 'Dessinez votre signature ci-dessous',
  'activation.step.draw_signature.admin': 'Dessinez votre signature ci-dessous ou uploadez une image de votre signature',
  'activation.step.draw_signature.title': 'Votre signature',
  'activation.step.fingerprint.fingerOption.DYN.leftIndex': 'Index gauche',
  'activation.step.fingerprint.fingerOption.DYN.leftLittle': 'Petit doigt de la main gauche',
  'activation.step.fingerprint.fingerOption.DYN.leftMiddle': 'Doigt du milieu de la main gauche',
  'activation.step.fingerprint.fingerOption.DYN.leftRing': 'Annulaire gauche',
  'activation.step.fingerprint.fingerOption.DYN.leftThumb': 'Pouce gauche',
  'activation.step.fingerprint.fingerOption.DYN.rightIndex': 'Index droit',
  'activation.step.fingerprint.fingerOption.DYN.rightLittle': 'Petit doigt de la main droite',
  'activation.step.fingerprint.fingerOption.DYN.rightMiddle': 'Doigt du milieu de la main droite',
  'activation.step.fingerprint.fingerOption.DYN.rightRing': 'Annulaire droite',
  'activation.step.fingerprint.fingerOption.DYN.rightThumb': 'Pouce droit',
  'activation.step.fingerprint.fingerOption.DYN.unknown': 'Doigt inconnu',
  'activation.step.intro.DYN': 'Bienvenue dans le processus d\'activation de votre carte <b>{country}</b>, veuillez suivre les étapes suivantes pour activer votre carte.</br></br> Si vous avez des questions, veuillez ouvrir le Centre d\'aide. Merci!',
  'activation.step.intro.DYN.admin': 'Bienvenue dans le processus d\'activation de la carte <b>{country}</b> pour votre client. Veuillez suivre les étapes suivantes pour procéder à l\'activation. Si vous rencontrez des difficultés, n\'hésitez pas à consulter le Centre d\'aide. Merci !',
  'activation.step.intro.resetAccount.title': 'Nouvel appareil principal',
  'activation.step.intro.title': 'Activer votre compte',
  'activation.step.nfc_scan_id_card.title': 'Scan NFC',
  'activation.step.scan_mrz': 'Scan de document',
  'activation.step.scan_mrz_choice.desc': 'Veuillez choisir le document que vous souhaitez scanner ci-dessous:',
  'activation.step.scan_mrz_choice.title': 'Choix du document à scanner',
  'activation.step.scan_mrz_nfc.desc': 'Veuillez <b>poser votre document sur le dos de votre téléphone</b> et attendre que le scan NFC soit terminé.',
  'activation.step.scan_mrz_nfc.title': 'Scan NFC du document',
  'activation.step.scan_mrz_nfc.title2': 'Résultat du scan NFC',
  'activation.step.scan_mrz_scan.desc': 'L\'appareil photo va s\'ouvrir automatiquement.</br></br>Veuillez <b>placer votre document<b> dans le cadre avec le code MRZ visible (série de <b>lettres<b> et <b>chiffres<b> avec <b><<<<b> au dos de le document) et attendez la fin de la numérisation.',
  'activation.step.scan_mrz_scan.title': 'Numérisez votre document',
  'activation.step.scan_mrz.title': 'Scannez votre document',
  'activation.title': 'Activation',
  'activity.claim.type.DYN0': 'Identité partagée',
  'activity.claim.type.DYN1': 'Carte d\'identité partagée',
  'activity.claim.type.DYN2': 'Permis de conduire partagé',
  'activity.claim.type.DYN3': 'Majorité partagé',
  'activity.claim.type.DYN5': 'Carte de santé partagée',
  'activity.claim.type.DYN7': 'Carte de transport partagée',
  'activity.claim.type.DYN15': 'Partage de l\'adresse de contact',
  'activity.claim.type.DYN18': 'Partage du numéro de téléphone de contact',
  'activity.claim.type.DYN13': 'Partage de la carte SOTRA',
  'activity.getActivities.error': 'Impossible d\'obtenir des événements',
  'activity.type.DYN0': 'Compte créé',
  'activity.type.DYN1': 'Compte confirmé',
  'activity.type.DYN10': 'Lecture iData',
  'activity.type.DYN11': 'Certificat revoqué',
  'activity.type.DYN12': 'Pin modifié',
  'activity.type.DYN3': 'Mot de passe modifié',
  'activity.type.DYN4': 'Connexion',
  'activity.type.DYN6': 'iData créé',
  'activity.type.DYN7': 'iData révoqué',
  'activity.type.DYN8': 'iData récupéré',
  'activity.type.DYN9': 'iData partagé',
  'addPlaceMember.desc': 'Ajouter un nouveau membre à ',
  'addPlaceMember.title': 'Ajouter un nouveau membre',
  'address.DYN.title': 'Adresse de contact',
  'addressLine1.DYN.title': 'Adresse de contact',
  'admin.changePerm': 'Êtes-vous sûr de vouloir changer les droits de cet utilisateur ?',
  'admin.disconnect': 'Vous avez bien été déconnecté de votre compte pro. Vous avez été redirigé vers votre compte citoyen.',
  'admin.noAdminPerm': 'Vous ne pouvez pas vous supprimer vos propre droits Administateur de cette place.',
  'admin.reconnect': 'Veuillez vous reconnecter sur votre compte pro afin de continuer.',
  'all.personnalDatas': 'Toutes vos données personnelles',
  'allAccounts.disconnected': 'Tous vos comptes ont bien été déconnectés.',
  'allShare.title': 'Choix de partage',
  'already.otp.DYN.0': 'Votre double authentification par email est déjà activée. Veuillez choisir une autre option.',
  'already.otp.DYN.1': 'Votre double authentification par téléphone est déjà activée. Veuillez choisir une autre option.',
  'api.createClaim.error': 'Impossible d\'obtenir la demande de remboursement',
  'api.createClaim.missingData': 'Données manquantes pour créer l\'utilisateur, veuillez réessayer',
  'api.listPlaces.error': 'Impossible d\'obtenir la liste des lieux',
  'api.place.error': 'Erreur lors de l\'obtention d\'informations sur le lieu. Veuillez réssayer, ou revenir à votre compte citoyen.',
  'api.places.error': 'Impossible d\'enregistrer un utilisateur dans un lieu',
  'api.postClaim.badNni': 'Le service souhaite se connecter à un autre citoyen que vous.',
  'api.postClaim.error': 'Impossible de transmettre le résultat au service',
  'api.postClaim.success': 'Le service a reçu vos informations',
  'api.registerAdmin.error': 'Impossible d\'enregistrer un administrateur dans un lieu',
  'api.searchPlace.error': 'Impossible d\'obtenir les résultats de la recherche',
  'api.setPin.error': 'Impossible de définir le code PIN',
  'api.shareClaim.err_wrong_doc': 'Le document demandé ne correspond pas à votre document',
  'api.shareClaim.error': 'Impossible de partager',
  'api.shareClaim.error_not_closed': 'La connexion n\'a pas été terminée',
  'api.shareClaim.noClaim': 'Impossible de partager. Cela est dû au fait que ce n\'est pas l\'appareil sur lequel vous vous êtes initialement enregistré. Pour le faire sur cet appareil, il faudra vous réenroller.',
  'api.shareClaim.receiverClose': 'Le vérificateur a cloturé le transfert',
  'api.user_info.error': 'Impossible d\'obtenir des données sur l\'utilisateur',
  'approveClaimSelf.success': 'Le justificatif a bien été approuvé.',
  'ask.approveClaimSelf': 'Si applicable, veuillez saisir la date d\'expiration du justificatif.',
  'ask.changeCustomer': 'Êtes-vous sur de vouloir repasser sur votre compte citoyen ?',
  'ask.disconnect.allAccounts': 'Êtes-vous sûr de vouloir déconnecter tous vos comptes ?',
  'ask.disconnectAllDevices': 'Êtes-vous sûr de vouloir déconnecter tous les autres appareils ?',
  'ask.faceID.unset': 'Êtes-vous sûr de vouloir désactiver votre FaceID ?',
  'ask.fingerprint.unset': 'Êtes-vous sûr de vouloir désactiver votre empreinte digitale ?',
  'ask.help': 'Besoin d\'aide ?',
  'ask.logoutAllDevices': 'Voulez-vous déconnecter tous les autres appareils connectés à votre compte ?',
  'ask.signout': 'Êtes-vous sur de vouloir vous déconnecter ?',
  'ATTESTATION_RNPP.DYN.select': 'Attestation RNPP',
  'badge.title': 'Mon badge',
  'biometric.dismissed': 'Vous avez annulé votre authentification biométrique. Veuillez réessayer.',
  'biometric.faceID.setError': 'Une erreur s\'est produite lors de l\'activation de FaceID, veuillez réessayer.',
  'biometric.faceID.setSuccess': 'FaceID activé avec succès !',
  'biometric.maybeNoExist': 'Votre appareil ne supporte pas l\'authentification biométrique, ou vous n\'avez pas d\'empreinte digitale enregistrée.',
  'biometric.notAvailable': 'Votre appareil ne supporte pas l\'authentification biométrique. Veuillez l\'activer si possible',
  'biometricANDROID.unregister.success': 'Authentification par empreinte digitale désactivée avec succès !',
  'biometricIOS.unregister.success': 'Authentification par Face ID désactivée avec succès !',
  'bluetooth.desc.disabled': 'Afin de procéder au partage, vous avez besoin d\'activer votre Bluetooth',
  'bluetooth.desc.noPermissions': 'Afin de procéder au partage, vous avez besoin de donner les permissions d\'utilisation de votre Bluetooth / Appareils à proximité',
  'bluetooth.disabled': 'Vous devez activer votre bluetooth afin de pouvoir continuer',
  'btn.retry': 'Réessayer',
  'call.timeout': 'Votre connexion internet est trop lente. L\'appel au serveur a expiré. Veuillez réessayer.',
  'camera.desc.noPermission': 'Afin de procéder au partage, vous avez besoin de donner les permissions d\'utilisation de votre caméra',
  'camera.turnFlashOff': 'Éteindre le flash',
  'camera.turnFlashOn': 'Allumer le flash',
  'card.activating': 'Vos cartes sont en cours de création...',
  'card.generator.card': 'Choisissez un document',
  'cardGenerator.title': 'Générateur de carte',
  'change-password-done.desc': 'Votre nouveau mot de passe a été défini avec succès. Veuillez vous connecter à nouveau.',
  'change-password-done.pro.desc': 'Le nouveau mot de passe de votre compte pro a été défini avec succès. Veuillez ajouter votre compte pro à nouveau.',
  'change-password.newpass_error': 'Impossible d\'établir un nouveau mot de passe',
  'change-password.wrong_oldpass': 'L\'ancien mot de passe est incorrect',
  'change.fromAdmin': 'Votre compte pro a bien été retiré. Vous avez été redirigé vers votre compte citoyen.',
  'change.lang.already.set': 'Cette langue est déjà sélectionnée.',
  'changePin.desc': 'Vous pouvez modifier votre code secret à tout moment. Vous devrez introduire votre code secret actuel suivi de votre nouveau code secret.',
  'changePin.title': 'Modifier le code secret',
  'checkBox_label.DYN.ALIEN_CARD': 'Carte de séjour',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT': 'Données de contact',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT_EMAIL': 'Email de contact',
  'checkBox_label.DYN.ADDITIONAL_INFO_CONTACT_PHONE_NR': 'Numéro de contact',
  'checkBox_label.DYN.ADDITIONAL_INFO_ADDRESS': 'Données d\'adresse',
  'checkBox_label.DYN.ADDITIONAL_INFO_HEALTH': 'Données de santé',
  'checkBox_label.DYN.ADDITIONAL_INFO_BANK': 'Données bancaires',
  'checkBox_label.DYN.VACCINATION': 'Carte de vaccination',
  'checkBox_label.DYN.HEALTH_CARD': 'Carte de santé',
  'checkBox_label.DYN.PASSPORT': 'Passeport',
  'checkBox_label.DYN.TRANSPORT_CARD': 'Carte de transport',
  'checkBox_label.DYN.RESIDENT_CARD': 'Carte de résident',
  'checkBox_label.DYN.CEDEAO_CARD': 'Carte cedeao',
  'checkBox_label.DYN.IDENTITY': 'Identité',
  'checkBox_label.DYN.ID_CARD': 'Carte d\'identité',
  'checkBox_label.DYN.DRIVING_LICENSE': 'Permis de conduire',
  'checkBox_label.DYN.ADULT': 'Vérification adulte',
  'checkBox_label.DYN.RNPP': 'Attestation RNPP',
  'checkBox_label.DYN.SOTRA_CARD': 'Carte SOTRA',
  'checkBox_label.DYN.MUGEFCI_CARD': 'Carte MUGEFCI',
  'choose_account.title': 'Gestion des comptes',
  'choose_place.exchange_error': 'Impossible de changer de lieu',
  'choose-lang.desc': 'Veuillez choisir la langue qui sera utilisée pour cette application.',
  'choose-lang.title': 'Préférence de langue',
  'chooseAccount.disconnectAll': 'Déconnecter tous les comptes',
  'chooseAccount.edit': 'Mode édition',
  'chooseAccount.exit_edit': 'Quitter le mode édition',
  'chooseAccount.new_account': 'Ajouter un nouveau compte',
  'claim.DYN.1': 'Carte d\'identité',
  'claim.DYN.10': 'Carte d\'étranger',
  'claim.DYN.11': 'E-mail de contact',
  'claim.DYN.12': 'Attestation RNPP',
  'claim.DYN.13': 'Carte SOTRA',
  'claim.DYN.14': 'Carte Mugef-CI',
  'claim.DYN.15': 'Adresse de contact',
  'claim.DYN.18': 'Téléphone de contact',
  'claim.DYN.2': 'Permis de conduire',
  'claim.DYN.5': 'Carte de santé',
  'claim.DYN.6': 'Passeport',
  'claim.DYN.7': 'Carte de transport',
  'claim.DYN.8': 'Carte de résident',
  'claim.DYN.9': 'Carte CEDEAO',
  'claimSelf.RefusalTitle': 'Raison du refus',
  'client.badState': 'Mauvais état',
  'client.body.auth': 'Impossible de vous authentifier',
  'client.body.missing': 'Il a manqué des paramètres à la requête',
  'client.body.notFound': 'Resource non trouvée',
  'client.extended.badReadRight': 'Vous n\'avez pas les droits pour cette opération',
  'client.extended.alreadyExecuted': 'L\'opération est entièrement complétée',
  'client.extended.badPayload': 'Requête API mal formée',
  'client.extended.badSigState': 'L\'étape de signature ne permet pas cette opération',
  'client.extended.cannotAcceptDocument': 'Il semble y avoir un problème avec votre document. Veuillez réessayer. Si le problème persiste, veuillez contacter notre équipe d\'assistance.',
  'client.extended.crlRevoked.DYN.server': 'La carte d\'identité scannée a été révoquée. Veuillez réessayer.',
  'client.extended.customerExists.DYN.server': 'La carte d\'identité scannée est déjà utilisée. Veuillez réessayer.',
  'client.extended.emailAccountExist.DYN.server': 'Cette adresse mail est déjà utilisée.',
  'client.extended.invalidUuid.DYN.server': 'Le QR code scanné n\'est pas valide. Veuillez réessayer.',
  'client.extended.largeUpload': 'Upload trop large. Demandez la version pro à notre support pour enlever ces limites.',
  'client.extended.locked': 'Le compte est suspendu',
  'client.extended.lockedTenant': 'La société est suspendue, contactez votre administrateur',
  'client.extended.mainAccountExists': 'Un compte existe au nom de l\'email invité. Utilisez-le pour suivre cette invitation. Vous n\'y avez pas accès? Contactez-nous!',
  'client.extended.missingResource': 'Ressource introuvable ou indisponible',
  'client.extended.missingResourceLogin.DYN.server': 'Le compte n\'existe pas ou le mot de passe est incorrect.',
  'client.extended.needAbovePlan': 'Vous ne pouvez rejoindre ce plan tarifaire actuellement, contactez le support',
  'client.extended.noEnroll': 'Impossible de s\'inscrire, veuillez nous contacter',
  'client.extended.notActiveTenant': 'Pas de société choisie',
  'client.extended.notActiveToken': 'Votre session a expiré. Veuillez vous reconnecter',
  'client.extended.notAllSigned': 'Toutes les parties n\'ont pas encore signé le procès-verbal',
  'client.extended.notArchived': 'Impossible de mettre à jour une ressource archivée',
  'client.extended.notLast': 'Une ressource plus récente existe!',
  'client.extended.notSignable': 'Cet élément ne peut pas être signé maintenant. Le reçu a-t\'il déjà été créé? Est-ce votre tour?',
  'client.extended.notVerified': 'Impossible sur un compte non vérifé',
  'client.extended.noVirtual': 'Impossible à appliquer à une signature',
  'client.extended.otherIdentity.DYN.server': 'Vous devez utiliser la même carte d\'identité que celle avec laquelle vous vous êtes enregistré. Veuillez réessayer avec la bonne carte.',
  'client.extended.overlaps': 'Les titres existent ou ne sont pas valides dans leur numérotation',
  'client.extended.pendingEmail': 'Impossible de vous connecter,e le compte n\'est pas vérifié',
  'client.extended.phoneAccountExist.DYN.server': 'Ce numéro de téléphone est déjà utilisé.',
  'client.extended.pinNotReady': 'Trop d\'essais, veuillez réessayer dans quelques secondes.',
  'client.extended.processed': 'Déjà complété',
  'client.extended.requireOtpLogin': 'Votre compte est sécurisé avec un code de validation.',
  'client.extended.sequenceDupped': 'Séquence dupliquée',
  'client.extended.smartIdNoCert': 'Impossible de récupérer le certificat de signature',
  'client.extended.tooEarly': 'La requête est faite trop tôt',
  'client.extended.userBound': 'L\'utilisateur est déjà lié',
  'client.extended.uuidExpired.DYN.server': 'Le QR code scanné a expiré. Veuillez réessayer.',
  'client.extended.wrongAuth': 'Action non autorisée',
  'client.extended.wrongFace': 'Le visage sur la photo ne correspond pas au visage sur le document.',
  'client.extended.wrongGeo': 'Votre géolocalisation a été évaluée a un pays non approuvé',
  'client.password': 'Mot de passe incorrect sur une ressource protégée',
  'client.puzzle': 'La signature client est fausse...',
  'close.expire': 'Attention, votre session va expirer dans 5 minutes. Voulez-vous la prolonger ?',
  'close.expire.offline': 'Vous allez être déconnecté de votre compte pro dans 3 minutes. Vous pourrez vous y reconnecter une fois votre internet retrouvé.',
  'connect.back': 'Retour à la connexion',
  'connectPro.back': 'Retour à l\'ajout d\'un compte pro',
  'create_place.control': 'Permissions de contrôle',
  'create_place.create': 'Permissions de création',
  'create_place.createPlace.error': 'Impossible de créer un lieu',
  'create_place.errorDuplicate': 'Ce lieu existe déjà sous un même ID. Veuillez en choisir un autre.',
  'create_place.setAdmin.desc': 'Le lieu a été créé avec succès. Vous pouvez maintenant désigner un utilisateur comme administrateur de ce lieu.',
  'create_place.setAdmin.title': 'Définir un lieu admin',
  'create_place.title': 'Créer un nouveau lieu',
  'create_place.updatePlace.error': 'Impossible de mettre à jour le lieu',
  'current.open': 'Ouverture en cours...',
  'desktop.noNNi': 'Votre compte n\'est pas activé, veuillez d\'abord activer votre compte via l\'application mobile avant de vous connecter via le site web.',
  'device.noNfc': 'Cette fonctionnalitée n\'est disponible qu\'avec un appareil disposant de la technologie NFC. Veuillez réessayer avec un autre appareil.',
  'devicePermissionsDYN.BLUETOOTH.title': 'Permission bluetooth nécessaire',
  'devicePermissionsDYN.CAMERA.title': 'Permission caméra nécessaire',
  'devicePermissionsDYNBLUETOOTH.desc1': 'Vous avez besoin des permissions de bluetooth afin de bien faire fonctionner l\'application. Veuillez activer les permissions.',
  'devicePermissionsDYNCAMERA.desc1': 'Vous avez besoin des permissions de caméra afin de bien faire fonctionner l\'application. Veuillez activer les permissions.',
  'devicePermissionsDYNNFC.desc1': 'Vous avez besoin des permissions de NFC afin de bien faire fonctionner l\'application. Veuillez activer les permissions.',
  'devicePermissionsDYNNFC.title': 'Permission NFC nécessaire.',
  'disconnect.allOtherDevices': 'Déconnecter tous les autres appareils',
  'email.DYN.title': 'Email de contact',
  'err.accountValidated': 'Votre compte est déjà validé',
  'err.addMeetingQuestions': 'Impossible d\'ajouter des questions à la réunion',
  'err.addMeetingUser': 'Impossible d\'ajoouter un utilisateur',
  'err.address.changeRequired': 'Vous devez remplir les champs de l\'adresse pour continuer.',
  'err.address.incomplete': 'Tous les champs de l\'adresse doivent être remplis',
  'err.address.invalidType': 'Mauvais format de document uploadé pour l\'adresse',
  'err.address.requiredPdf': 'Document pdf manquant pour l\'adresse',
  'err.addressLine1.minlength': 'Ligne d\'adresse 1 trop petite',
  'err.addressLine1.required': 'Ligne d\'adresse 1 requise',
  'err.addressLine2.minlength': 'Ligne d\'adresse 2 trop petite',
  'err.addressLine2.required': 'Ligne d\'adresse 2 requise',
  'err.addTag': 'Erreur lors de la création du tag',
  'err.addTagValue': 'Erreur lors de la création de la valeur du tag',
  'err.addTranslation': 'Erreur lors de l\'ajout de la traduction',
  'err.bindMeeting': 'Impossible de récupérer la session',
  'err.BIRTH_DATE.required': 'La date de naissance est requise',
  'err.BIRTH_PLACE.pattern': 'Lieu de naissance ne correspond pas au modèle',
  'err.BIRTH_PLACE.required': 'Le lieu de naissance est requise',
  'err.birthDate.required': 'La date de naissance est requise',
  'err.camera.notfound': 'Aucune caméra trouvée',
  'err.CAN.minlength': 'Numéro CAN trop petit',
  'err.CAN.pattern': 'Nr. CAN ne correspond pas au modèle',
  'err.CAN.required': 'Numéro CAN requis',
  'err.cancelMeeting': 'Impossible d\'annuler la réunion',
  'err.canNr.pattern': 'Nr. CAN ne correspond pas au modèle',
  'err.canNr.required': 'Nr. CAN est requis',
  'err.certificate.required': 'Le certificat est requis',
  'err.city.minLength': 'Ville trop petite',
  'err.city.pattern': 'format de ville invalide',
  'err.city.required': 'La ville est requise',
  'err.closeKyc': 'Impossible de valider les KYC',
  'err.code.required': 'Code d\'email est requis',
  'err.commentMeeting': 'Impossible de commenter',
  'err.complete2FA': 'Erreur dans le processus 2FA',
  'err.confirm': 'Impossible de confirmer l\'email',
  'err.consumeDelegation': 'Impossible d\'utiliser la délégation',
  'err.consumeLogin': 'Erreur lors de la connexion',
  'err.consumeLoginWeak': 'Connexion impossible',
  'err.contact': 'Impossible de transmettre le message',
  'err.countries.required': 'Pays requis',
  'err.country.required': 'Le pays est requis',
  'err.COUNTRY.required': 'Le pays est requis',
  'err.DATE_OF_BIRTH.max': 'La date de naissance doit être dans le passé',
  'err.DATE_OF_BIRTH.min': 'La date de naissance est trop ancienne',
  'err.DATE_OF_BIRTH.required': 'La date de naissance est requise',
  'err.DEVICE_NAME.required': 'Le nom de l\'appareil est requis',
  'err.DISTRICT.required': 'District requis',
  'err.DIVISION.required': 'La division est requise',
  'err.DOC_EXPIRY_DATE.max': 'La date d\'expiration du document est trop éloignée dans le futur',
  'err.DOC_EXPIRY_DATE.min': 'La date d\'expiration du document doit être dans le futur',
  'err.DOC_EXPIRY_DATE.required': 'La date d\'expiration est requise',
  'err.DOC_NUMBER.required': 'Le numéro de document est requis',
  'err.docNr.pattern': 'Numéro du document ne correspond pas au modèle',
  'err.docNr.required': 'Numéro du document est requise',
  'err.email.email': 'L\'email doit avoir un format correct : email@mail.com',
  'err.email.invalidFileType': 'Mauvais format de document uploadé pour l\'email de contact.',
  'err.email.required': 'Email requis',
  'err.email.requiredPdf': 'Document pdf manquant pour l\'email de contact',
  'err.eraseMeeting': 'Impossible d\'effacer la réunion',
  'err.err': 'Erreur',
  'err.err.DYN.server': 'Votre connexion au serveur a été perdue. Veuillez attendre que le serveur soit à nouveau disponible afin de continuer.',
  'err.expiryDate.required': 'La date d\'expiration est requise',
  'err.exportTenant': 'Impossible de lire les données',
  'err.fileSize': 'Le fichier dépasse la taille: ',
  'err.fileType': 'Mauvais format de fichier ',
  'err.FIRST_NAME.pattern': 'Prénom ne correspond pas au modèle',
  'err.FIRST_NAME.required': 'Prénom requis',
  'err.GENDER.required': 'Le sexe est requise',
  'err.getTenantElements': 'Impossible de voir les éléments achetés',
  'err.HEIGHT.required': 'Taille est requis',
  'err.id.required': 'L\'ID est requis',
  'err.identifier.pattern': 'Identifiant invalide',
  'err.identifier.required': 'Identifiant requis',
  'err.info': 'Impossible de lire les informations du compte',
  'err.ISSUE_DATE.required': 'La date d\'émission est requise',
  'err.ISSUE_PLACE.required': 'Le lieu d\'émission est requis',
  'err.lang.required': 'La langue est requise',
  'err.LAST_NAME.pattern': 'Nom de famille ne correspond pas au modèle',
  'err.LAST_NAME.required': 'Nom de famille requis',
  'err.LOCATION.required': 'La localisation est requise',
  'err.loginWeak: Could not validate user': 'Impossible de valider l\'utilisateur.',
  'err.loginWeak.DYN.server': 'Votre connexion au serveur a été perdue. Veuillez attendre que le serveur soit à nouveau disponible afin de continuer.',
  'err.MIDDLE_NAME.pattern': 'Second prénom ne correspond pas au modèle',
  'err.MIDDLE_NAME.required': 'Le second prénom est requise',
  'err.mrz.required': 'MRZ est requise',
  'err.name.required': 'Le nom est requis',
  'err.NATIONALITY.pattern': 'Nationalité ne correspond pas au modèle',
  'err.NATIONALITY.required': 'La nationalité est requise',
  'err.NFC.required': 'Numéro NFC requis',
  'err.nni.required': 'Le numéro d\'identité nationale est requis',
  'err.NNI.required': 'Le numéro d\'identité nationale est requis.',
  'err.old.required': 'Ancien mot de passe est requis',
  'err.oldPassword.minlength': 'Votre mot de passe doit contenir au moins 10 caractères.',
  'err.oldPassword.required': 'Ancien mot de passe requis',
  'err.otp.pattern': 'Mauvais format de code de validation',
  'err.otp.required': 'Code de validation (OTP 1) requis',
  'err.otp2.required': 'Code de validation (OTP 2) requis',
  'err.password.maxlength': 'Le mot de passe doit comporter entre 10 et 30 caractères',
  'err.password.minlength': 'Le mot de passe doit comporter entre 10 et 30 caractères',
  'err.password.required': 'Mot de passe requis',
  'err.password2.minlength': 'Le mot de passe doit comporter entre 6 et 20 caractères',
  'err.password2.mustMatch': 'Les mots de passe ne correspondent pas',
  'err.password2.required': 'Deuxième mot de passe est requis',
  'err.PERSONAL_NUMBER.required': 'Numéro personnel est requis',
  'err.phone_nr.pattern': 'Numéro de téléphone invalide',
  'err.phone_nr.required': 'Numéro de téléphone requis',
  'err.phone.invalidFileType': 'Mauvais format de document uploadé pour le numéro de téléphone de contact.',
  'err.phone.pattern': 'Le numéro de téléphone doit être composé de 10 chiffres et commencer par 01, 05 ou 07',
  'err.phone.required': 'Le numéro de téléphone est requis',
  'err.phone.requiredPdf': 'Document pdf manquant pour le numéro de téléphone de contact',
  'err.PIN.minlength': 'Code pin trop petit',
  'err.PIN.required': 'Code pin requis',
  'err.place.required': 'Le lieu est requis',
  'err.postalCode.pattern': 'Code postal invalide',
  'err.postalCode.required': 'Code postal requis',
  'err.PROFESSION.pattern': 'Profession ne correspond pas au modèle',
  'err.PROFESSION.required': 'Profession est requis',
  'err.proofPdf.required': 'Justificatif requis',
  'err.rcs.required': 'Le numéro de companie est requis',
  'err.rearm': 'Impossible de renvoyer l\'email',
  'err.region.minLength': 'Ville trop petite',
  'err.region.pattern': 'format de region invalide',
  'err.region.required': 'Région requise',
  'err.register': 'Impossible de créer un compte',
  'err.requiredField': 'Un champ requis est manquant',
  'err.reset': 'Impossible de générer un code',
  'err.resetPassword': 'Impossible de changer le mot de passe',
  'err.resetQuestion': 'Impossible de supprimer les votes de la question',
  'err.setPassword': 'Impossible de définir les informations d\'identification',
  'err.setPicture': 'Impossible de modifier les images',
  'err.setPin': 'Impossible de définir les informations d\'identification',
  'err.signature.required': 'Signature requise',
  'err.SIGNATURE.required': 'Signature requise',
  'err.street.required': 'Le nom de rue est requis',
  'err.streetNr.required': 'Le Numéro de rue est requis',
  'err.streetNumber.required': 'Street nr. is required',
  'err.SUBLOCATION.required': 'La sous-localisation est requise',
  'err.tenantLang.required': 'Language is required',
  'err.terms.required': 'Les conditions générales doivent être acceptées',
  'err.tva.required': 'Le numéro de TVA est requis',
  'err.unlockPin': 'Impossible de vérifier le PIN',
  'err.zip.pattern': 'Code postal invalide',
  'err.zip.required': 'Le code postal est requis',
  'error.basic': 'Une erreur inattendue s\'est produite',
  'error.nfc': 'Une erreur s\'est produite lors de la lecture de la puce NFC. Vous avez peut-être saisi de fausses données dans les étapes précédentes. Veuillez vérifier vos données et réessayer.',
  'error.no_cordova': 'Cette fonctionnalité n\'est pas disponible sur votre appareil',
  'error.noPlace': 'Vous devez d\'abord choisir un lieu pour pouvoir continuer',
  'exp.pro.session': 'Votre session a expiré. Vous avez été redirigé vers votre compte citoyen.',
  'exp.session': 'Votre session a expirée, veuillez vous reconnecter.',
  'exp.session.offline': 'Vous avez bien été déconnecté de votre compte pro. Vous avez été redirigé vers votre compte citoyen. Vous pourrez vous reconnecter à votre compte pro une fois internet retrouvé.',
  'explain.personnal.change': 'Votre donnée vérifiée a été modifiée. Elle est en attente de validation ou refusée par un de nos agents. Vous pouvez tout de même partager votre donnée validée.',
  'explain.personnal.noVerify': 'Votre donnée n\'a pas été vérifiée et a été refusée par un de nos agents. Veuillez la modifier et réessayer.',
  'explain.personnal.verify': 'Votre donnée est vérifier et peut être partagée.',
  'explain.personnal.waiting': 'Votre donnée n\'a pas été vérifiée et est en attente de validation par un de nos agent',
  'external.down': 'Serveur externe injoignable',
  'faceID.biometric.notGranted': 'Vous devez autoriser l\'utilisation de votre Face ID afin de pouvoir continuer',
  'faceID.button.set': 'Activer faceID',
  'faceID.button.unset': 'Désactiver faceID',
  'faceID.notGranted.desc': 'Vous avez besoin des permissions d\'utilisation de votre Face ID afin de pouvoir continuer. Veuillez les autoriser et réessayer.',
  'faceID.set.desc': 'Votre FaceID est bien activé. Cependant si vous voulez désactiver cette fonctionnalité, vous pouvez le faire en cliquant sur le bouton ci-dessous.',
  'faceID.unset.desc': 'Votre FaceID est actuellement désactivé. Si vous voulez activer cette fonctionnalité afin de l\'utiliser à la place de votre code secret, vous pouvez le faire en cliquant sur le bouton ci-dessous.',
  'faqExternalUrl.DYN.role.0': 'https://www.notion.so/satoris/Guide-utilisateur-Citoyen-8345b59ac872401d9cc43385aa39807e?pvs=4',
  'faqExternalUrl.DYN.role.1': 'https://www.notion.so/satoris/Guide-utilisateur-Professionnel-126ab2a1eb9143a0819b7c810f32b2e3?pvs=4',
  'faqExternalUrl.DYN.role.2': 'https://www.notion.so/satoris/Guide-utilisateur-Admin-8af486c469084a059b9d208f7c60d22c?pvs=4',
  'field.maybeError': 'Champ(s) comportant une erreur :',
  'fingerprint.button.set': 'Activer l\'empreinte digitale',
  'fingerprint.desc': 'Vous pouvez utiliser vos empreintes digitales à la place de votre code secret. Si vous le souhaitez, veuillez placer votre doigt sur le lecteur d\'empreinte digitale de votre téléphone afin de le lier.',
  'fingerprint.notGranted.desc': 'Vous avez besoin des permissions d\'utilisation de votre empreinte digitale afin de pouvoir continuer. Veuillez les autoriser et réessayer.',
  'fingerprint.set.desc': 'Votre empreinte digitale est activée. Toutefois, si vous voulez désactiver cette fonctionnalité, vous pouvez le faire en cliquant sur le bouton ci-dessous.',
  'fingerprint.unset.desc': 'Votre empreinte digitale est actuellement désactivée. Si vous voulez activer cette fonctionnalité pour l\'utiliser à la place de votre code secret, vous pouvez le faire en cliquant sur le bouton ci-dessous.',
  'fingerprints.button.set': 'Utiliser les empreintes digitales',
  'fingerprints.button.unset': 'Supprimer les empreintes digitales',
  'fingerprints.dialog.getSecret': 'Utiliser les empreintes digitales au lieu du code PIN',
  'fingerprints.dialog.setSecret': 'Utilisez la biométrie au lieu du code PIN', //Use biometric instead of PIN,
  'fingerprints.register.error': 'Les empreintes digitales n\'ont pas été enregistrées.', //Fingerprints was\'t registered,
  'fingerprints.register.success': 'Vous avez enregistré vos empreintes digitales avec succès.', //'You\'ve successfully registered your fingerprints',
  'fingerprints.unregister.success': 'Les empreintes digitales ont été effacées.',
  'forgotPassword.title': 'Mot de passe oublié',
  'forgotPin.desc': 'Vous avez oublié votre code PIN ou votre compte est bloqué après trop de tentatives infructueuses ? Cette sécurité est essentielle pour la protection de votre compte. Vous pouvez réinitialiser votre code PIN en cliquant sur le bouton ci-dessous.',
  'forgotPin.title': 'PIN oublié',
  'form.address': 'Adresse',
  'form.birth_place': 'Lieu de naissance',
  'form.birthDate': 'Date de naissance',
  'form.birthPlace': 'Lieu de naissance',
  'form.can': 'N° CAN',
  'form.center': 'Centre',
  'form.city': 'Ville',
  'form.code': 'Code',
  'form.connectIpRange': 'Plage IP',
  'form.connectUaPattern': 'Modèle UA',
  'form.country': 'Pays',
  'form.country_of_birth': 'Pays de naissance',
  'form.dateOfBirth': 'Date de naissance',
  'form.dateOfIssue': 'Date de délivrance',
  'form.district': 'District',
  'form.division': 'Division',
  'form.doc_nr': 'Numéro de document',
  'form.docExpiryDate': 'Date d\'expiration',
  'form.docNr': 'Numéro du document',
  'form.docNumber': 'Numéro de document',
  'form.driving_privileges': 'Permis de conduire',
  'form.DYN.addressLine1': 'Ligne d\'adresse 1',
  'form.DYN.addressLine2': 'Ligne d\'adresse 2',
  'form.DYN.city': 'Ville',
  'form.DYN.countries': 'Pays',
  'form.DYN.country': 'Pays',
  'form.DYN.country.BEL': 'Belgique',
  'form.DYN.country.CIV': 'Côte d\'Ivoire',
  'form.DYN.country.GMB': 'Gambie',
  'form.DYN.country.KEN': 'Kenya',
  'form.DYN.country.LUX': 'Luxembourg',
  'form.DYN.country.UTO': 'Utopia',
  'form.DYN.country.ZWE': 'Zimbabwe',
  'form.DYN.email': 'Email',
  'form.DYN.phone_nr': 'Numéro de téléphone',
  'form.DYN.postalCode': 'Code postal',
  'form.DYN.region': 'Région',
  'form.email': 'Email',
  'form.expiryDate': 'Date d\'expiration',
  'form.father_name': 'Nom du père',
  'form.firstName': 'Prénom',
  'form.gender': 'Sexe',
  'form.gender.DYN.female': 'Femme',
  'form.gender.DYN.male': 'Homme',
  'form.gmt': 'Fuseau horaire',
  'form.gmtShiftMinutes.DYN-360': '-6 (NewYork, ...)',
  'form.gmtShiftMinutes.DYN-540': '-9 (San Fransisco, ...)',
  'form.gmtShiftMinutes.DYN0': '+0 (London, ...)',
  'form.gmtShiftMinutes.DYN60': '+1 (Luxembourg, Paris, Bruxelles, ...)',
  'form.height': 'Taille en cm',
  'form.id': 'ID',
  'form.identifier': 'Identifiant',
  'form.issue_date': 'Date de délivrance',
  'form.issueDate': 'Date d\'émission',
  'form.issuePlace': 'Place d\'émission',
  'form.issuer': 'Autorité émettrice',
  'form.issuing_authority': 'Autorité émettrice',
  'form.issuing_country': 'Pays d\'émission',
  'form.issuingAuthority': 'Autorité émettrice',
  'form.lang': 'Langue',
  'form.lang.DYN.EN': 'English',
  'form.lang.DYN.FR': 'Français',
  'form.lang.DYN.IT': 'Italien',
  'form.lang.DYN.JA': 'Japonais',
  'form.lang.DYN.DE': 'Allemand',
  'form.lang.DYN.CN': 'Chinois',
  'form.lang.DYN.RU': 'Russe',
  'form.lastName': 'Nom de famille',
  'form.location': 'Localisation',
  'form.middleName': 'Second prénom',
  'form.mother_name': 'Nom de la mère',
  'form.mrz': 'MRZ',
  'form.name': 'Nom',
  'form.nationality': 'Nationalité',
  'form.nationality.DYN.BEL': 'Belge',
  'form.nationality.DYN.CIV': 'Ivoirienne',
  'form.nationality.DYN.GMB': 'Gambien',
  'form.nationality.DYN.KEN': 'Kényan',
  'form.nationality.DYN.LUX': 'Luxembourgeois',
  'form.nationality.DYN.UTO': 'Utopien',
  'form.nationality.DYN.ZWE': 'Zimbabwienne',
  'form.nni': 'Numéro d\'Identité Nationale',
  'form.occupation': 'Occupation',
  'form.password': 'Mot de passe',
  'form.password_new': 'Nouveau mot de passe',
  'form.password_new2': 'Confirmer nouveau mot de passe',
  'form.password_old': 'Ancien mot de passe',
  'form.password2': 'Retaper mot de passe',
  'form.personalNr': 'Numéro personnel',
  'form.phoneNumber': 'Numéro de téléphone',
  'form.pin': 'Code PIN',
  'form.place': 'Lieu',
  'form.placeOfBirth': 'Lieu de naissance',
  'form.plan.DYN0': 'Max 5 utilisateurs',
  'form.plan.DYN1': 'Max 15 utilisateurs',
  'form.plan.DYN2': 'Max 30 utilisateurs',
  'form.plan.DYN3': 'Max 50 utilisateurs',
  'form.plan.DYN4': 'Sur mesure',
  'form.profession': 'Profession',
  'form.rcs': 'Nr. RCS',
  'form.residence_adr': 'Adresse de résidence',
  'form.sms': 'Numéro de téléphone',
  'form.ssn': 'Numéro de sécurité sociale',
  'form.street': 'Nom de rue',
  'form.streetNr': 'Nr. de rue',
  'form.sublocation': 'Sous-localisation',
  'form.subLocation': 'Sous-location',
  'form.support_document': 'Document de support',
  'form.support_document_date': 'Date du document de support',
  'form.tenantLang.DYN0': 'Anglais',
  'form.tenantLang.DYN1': 'Français',
  'form.title_of_signing_authority': 'Titre de l\'autorité signataire',
  'form.town_of_residence': 'Ville de résidence',
  'form.tva': 'Nr. TVA',
  'form.updatePlace': 'Les informations de la place ont bien été modifiées.',
  'form.updatePlace.ask': 'Êtes-vous sûr de vouloir modifier les informations de cette place ?',
  'form.user': 'Utilisateur',
  'form.zip': 'Code postal',
  'generator.card': 'Carte',
  'generator.country': 'Pays',
  'getPin.faceCheck': 'Nous avons trouver le code suivant pour votre carte d\'identité : <u><b>{pin}</b></u>. <br> Veuillez le conserver précieusement pour des usages futurs. <br> Ce code correspond a votre code d\'origine, donc non applicable si vous l\'avez modifié entre temps.',
  'global.abo': 'Abonnement',
  'global.accept': 'Accepter',
  'global.approve': 'Approuver',
  'global.back': 'Retour',
  'global.clear': 'Effacer',
  'global.close': 'Fermer',
  'global.confirm': 'Confirmer',
  'global.continue': 'Continuer',
  'global.disconnect': 'Déconnecter',
  'global.error': 'Une erreur est survenue, veuillez réessayer',
  'global.eula': 'Conditions d\'Utilisation',
  'global.hello': 'Bonjour',
  'global.help_center': 'Centre d\'aide',
  'global.more': 'Plus',
  'global.next': 'Suivant',
  'global.NNI': 'Numéro national',
  'global.no': 'Non',
  'global.no_data_available': 'Pas de données disponibles',
  'global.no_filtered_data': 'Pas de résultats, essayez de changer les filtres',
  'global.or': 'Ou',
  'global.privacy': 'Politique des Données',
  'global.refresh': 'Rafraîchir',
  'global.refusal': 'Refuser',
  'global.refuse': 'Refuser',
  'global.restore': 'Restaurez votre abonnement depuis votre compte',
  'global.search': 'Rechercher',
  'global.share': 'Partager',
  'global.subscribe': 'Souscrire à un abonnement',
  'global.upload': 'Uploader',
  'global.uploaded': 'Image Uploadée',
  'global.yes': 'Oui',
  'header.back': 'Retour',
  'header.disconnect': 'Se déconnecter',
  'identity_card.rotate': 'Tourner pour une carte plus grande',
  'iframe.ask.return': 'Êtes-vous sûr de vouloir quitter ce service? Vos modifications seront perdues.',
  'iframe.offline': 'Vous venez de perdre votre connexion internet. Veuillez attendre que votre connexion soit rétablie afin de continuer, ou retourner sur votre page principale.',
  'internal.db': 'Impossible de joindre la base de données',
  'isClaimSelf.share': 'Partage de vos données personnelles',
  'issuerAuth.expired.get': 'Votre token de partage a expiré. Nous allons le récupérer. Veuillez réessayer plus tard...',
  'issuerAuth.recover': 'Nous sommes en train de récupérer votre token de partage. Veuillez patienter...',
  'issuerAuth.recoverNoInternet': 'Une erreur est survenue lors de la récupération de votre token de partage. Veuillez vous reconnecter à internet afin de réessayer.',
  'just.activatedAccount': 'Votre compte a bien été activé. Vous pouvez maintenant vous connecter.',
  'jwt.extend': 'Prolonger',
  'localisation.desc.disabled': 'Afin de procéder au partage, vous avez besoin d\'activer votre localisation',
  'localisation.desc.noPermissions': 'Afin de procéder au partage, vous avez besoin de donner les permissions d\'utilisation de votre Localisation',
  'localisation.disabled': 'Vous devez activer votre localisation afin de pouvoir continuer',
  'localisation.title': 'Permission de Localisation nécessaire',
  'login.ask_otp.email': 'Veuillez entrer le code à 6 chiffres que vous avez reçu par e-mail.',
  'login.ask_otp.sms': 'Veuillez entrer le code à 6 chiffres que vous avez reçu par sms.',
  'login.in': 'identifier',
  'login.invalid': 'Vous avez tenté de vous connecter avec un compte professionnel non associé à votre compte citoyen. Veuillez vous reconnecter avec votre compte personnel et utiliser les identifiants corrects.',
  'login.sign_in': 'S\'identifier',
  'lost.connection': 'Hors ligne',
  'manage.alreadyOpen': 'Cette demande a déjà été ouverte par un autre agent il y a moins de 5 minutes. Voulez-vous tout de même continuer ?',
  'manage.personnal.pending': 'Vérification en attente',
  'manage.user.activation': 'Activation d\'un utilisateur',
  'manage.user.personnal-data': 'Gestion des données personnelles',
  'manage.user.set_pro_account': 'Créer un compte Pro',
  'manage.user.title': 'Gestion d\'utilisateur',
  'managePlace.new_place': 'Nouveau lieu',
  'managePlace.search_place': 'Rechercher un lieu',
  'managePlace.set_place_admin': 'Définir l\'administrateur du lieu',
  'managePlace.title': 'Gestion du lieu',
  'managePopup.helper': 'Cette page vous permet de gérer les données personnelles des utilisateurs. Si vous voyez que l\'icone d\'une demande est rouge, c\'est qu\'elle a déja été ouverte par un autre agent en deça de 5 minutes.',
  'manageSelf.noDate': 'Non ouvert',
  'meeting.typeDYN0': 'Réunion',
  'meeting.typeDYN5': 'Brouillon',
  'modifyPersonnal.DYN.APPROVED.ask': 'Êtes-vous sûr de vouloir modifier cette donnée approuvée ?',
  'modifyPersonnal.DYN.CHANGING.ask': 'Êtes-vous sûr de vouloir modifier cette donnée ? Cela remplacera celle précédemment validée.',
  'modifyPersonnal.DYN.PENDING.ask': 'Êtes-vous sûr de vouloir modifier cette donnée ? Vous perdrez votre ancienne demande en cours.',
  'modifyPersonnal.DYN.REJECTED.ask': 'Êtes-vous sûr de vos corrections ?',
  'modifyPersonnal.noChange': 'Vous n\'avez modifier aucune donnée, seul le justificatif a été modifié. Êtes-vous sûr ?',
  'modifyPersonnal.refusal.void': 'Veuillez modifier les corrections demandées afin de continuer.',
  'modifyPersonnal.void': 'Veuillez effectuer au moins un changement afin de continuer.',
  'more.change_password': 'Modifier le mot de passe',
  'more.change_pin': 'Modifier le code secret',
  'more.delete_user': 'Supprimer l\'utilisateur',
  'more.delete_user_sure': 'Voulez-vous vraiment supprimer l\'utilisateur actuel? Vous pourrez créer un nouveau compte à l\'aide de sa carte d\'identité.',
  'more.language': 'Langue',
  'more.purchasedSubscription': 'Abonnement actif',
  'more.purchasedSubscriptionLong': 'Votre abonnement est actif et sera renouvelé selon les conditions initiales d\'achat. Si vous souhaitez le modifier, rendez-vous sur la liste des abonnements de votre app store mobile.',
  'more.purchaseSubscription': 'Acheter un abonnement',
  'more.security': 'Sécurité',
  'more.security.faceid': 'Face ID',
  'more.security.faceID': 'Face ID',
  'more.security.fingerprint': 'Empreintes digitales',
  'more.security.otp': 'Double authentification',
  'more.security.otp.email': 'Authentification par email',
  'more.security.otp.sms': 'Authentification par téléphone',
  'more.title': 'En plus',
  'mrzType.DYN.TD1': 'Carte d\'identité',
  'mrzType.DYN.TD3': 'Passeport',
  'MUGEFCI_CARD.DYN.select': 'Carte Mugef-CI',
  'nearest.activation.point': 'Lieu le plus proche',
  'news.modal': 'Nouvelle fonctionnalité',
  'news.title': 'Actualités',
  'nfc.demo': 'Démo NFC, veuillez patienter 5 secondes...',
  'nfc.error.icao': 'Une erreur est survenue lors de la lecture de votre carte NFC. Votre carte n\'a plus été détectée durant le processus de scan. Veuillez bien positionner votre téléphone sur votre carte comme indiqué sur l\'illustration.',
  'nfc.read.adminActivate': 'Une erreur s\'est produite lors de l\'enregistrement de l\'utilisateur, veuillez réssayer.',
  'nfc.read.confirmSc.error': 'Une erreur s\'est produite. Soit votre code PIN est incorrect, soit votre téléphone a perdu la connexion NFC avec la carte. Veuillez réinsérer votre code PIN et replacer votre carte comme indiqué sur l\'image.',
  'nfc.read.nfc_notfound': 'NFC introuvable, veuillez l\'activer dans vos options',
  'nfc.read.noMorePinTries': 'Il semblerait que vous ayez entré trop de fois un pin incorrect. Veuillez vous rendre dans un centre Oneci afin de débloquer votre carte.',
  'nfc.read.self': 'Scan NFC de carte d\'identité',
  'nfc.read.self.canError': 'Une erreur s\'est produite. Soit votre numéro CAN est incorrect, soit votre téléphone a perdu la connexion NFC avec la carte. Veuillez réinsérer votre CAN et replacer votre carte comme indiqué sur l\'image.',
  'nfc.read.self.form.desc': 'Veuillez saisir le numéro de CAN inscrit dans le coin inférieur droit de la carte d\'identité.',
  'nfc.read.self.pin.desc': 'Confirmez votre auto-inscription en saisissant le code PIN de votre carte d\'identité.',
  'nfc.read.self.scan': 'Démarrer le processus',
  'nfc.read.self.scan.pinRemain': 'Essais de PIN restants',
  'no.connection': 'Vous avez besoin d\'internet pour pouvoir accéder à cette page. Veuillez vous reconnecter',
  'no.connection.signIn': 'Vous avez besoin d\'internet pour pouvoir vous connecter la première fois. Veuillez réessayer plus tard.',
  'no.personnalData': 'Vous n\'avez actuellement aucune donnée personnelle enregistrée ou en cours de validation.',
  'noIDinQR.error': 'Il n\'y a pas d\'ID dans ce QR-code',
  'notif.meetingIn.body': 'Vous avez été enregistré comme participant à une nouvelle réunion sur la plate-forme.',
  'notif.meetingIn.title': 'Prochaine nouvelle réunion',
  'notif.meetingStart.body': 'Une réunion à laquelle vous participez vient de commencer!',
  'notif.meetingStart.title': 'Réunion en cours',
  'notif.meetingStop.body': 'Processus formels en cours sur la réunion récemment clôturée.',
  'notif.meetingStop.title': 'Réunion clôturée',
  'notif.recvMandate.body': 'Vous avez reçu une procuration pour une réunion! Vérifiez-la si vous ne voulez pas l\'accepter.',
  'notif.recvMandate.title': 'Vous avez reçu une procuration',
  'notif.signIn.body': 'Vous avez invité à signer un document',
  'notif.signIn.title': 'Invitation à signer un document',
  'notif.signTransfer.body': 'Vous êtes invité à signer un document de manière électronique',
  'notif.signTransfer.title': 'Invitation pour signature électronique',
  'notif.transferIn.body': 'Vous êtes invités à réceptionner un document',
  'notif.transferIn.title': 'Invitation à réceptionner un document',
  'otp.actual.DYN.0': 'Votre double authentification actuelle est définie sur email',
  'otp.actual.DYN.1': 'Votre double authentification actuelle est définie sur téléphone',
  'otp.desc.email': 'Pour une authentification par email, votre adresse e-mail principale sera utilisée. Pour rappel, votre email principal est: ',
  'otp.desc.explain': 'Pour améliorer la sécurité de votre compte, utilisez un second facteur d\'authentification. Vous pouvez recevoir un mot de passe unique (OTP) par email ou téléphone.',
  'otp.desc.sms': 'Pour une authentification par téléphone, saisissez votre numéro de téléphone mobile. Un code unique (OTP) vous sera envoyé par SMS. Entrez ce code dans le champ fourni pour valider votre double authentification.',
  'otp.email.changePassword.error': 'Votre changement de mot de passe n\'a pas abouti. Vérifiez bien que le code unique reçu par email soit correct.',
  'otp.email.success': 'Votre double authentification par email a été activée avec succès.',
  'otp.login.fail': 'Votre code est incorrect. Veuillez réessayer.',
  'otp.resend.email': 'Renvoyer le lien par email',
  'otp.resend.phone': 'Renvoyer le code par SMS',
  'otp.sms.error': 'Votre double authentification par téléphone n\'a pas pu être activée. Vérifiez bien que le code unique reçu par sms soit correct.',
  'otp.sms.success': 'Votre double authentification par téléphone a été activée avec succès.',
  'otp.successResend.email': 'Votre lien a été renvoyé avec succès.',
  'otp.successResend.sms': 'Votre code a été renvoyé avec succès.',
  'passwordChanged.desc': 'Votre nouveau mot de passe a été modifié et vous pouvez maintenant vous connecter.',
  'passwordChanged.title': 'Mot de passe modifié',
  'passwordProChanged.desc': 'Le mot de passe de votre compte pro a  bien été modifié.',
  'perm.settings.always': 'Ouvrir les permissions',
  'perm.settings.once': 'Activer',
  'permission.perm1': 'Créer',
  'permission.perm2': 'Consommer',
  'permission.perm3': 'Contrôler',
  'permission.perm4': 'Admin',
  'persoData.approved': 'Cette donnée a été vérifiée.',
  'persoData.changing': 'Votre modification est en cours de validation.',
  'persoData.pending': 'Cette donnée est en attente de vérification.',
  'persoData.rejected': 'Cette donnée requiert une correction',
  'personnal.verify.DYN.11': 'Vérification de l\'email',
  'personnal.verify.DYN.15': 'Vérification de l\'adresse',
  'personnal.verify.DYN.18': 'Vérification du numéro de téléphone',
  'personnalAdd.explain': 'Pour chaques données personnelles ajoutées ou modifiées, veuillez fournir un document attestant de la véracité de ces informations si demandé. <br> <br> A la suite de votre demande d\'ajout ou de modification, un de notre agent vérifiera vos informations et validera ou non votre demande.',
  'personnalData.all': 'Toutes vos données personnelles',
  'personnalData.changing.refused': 'Votre modification a été refusée. Veuillez corriger les champs demandés.',
  'personnalData.contact': 'Données de contact',
  'personnalData.DYN.address': 'Informations sur l\'adresse',
  'personnalData.DYN.contact': 'Informations de contact',
  'personnalData.rejected.labelRefusal': 'Le/Les champ(s) suivant(s) ont été refusé(s) : {labelRefusal}. <br> <br> Veuillez corriger le/les champs refusés et réessayer.',
  'personnalData.title': 'Mes Données personnelles',
  'personnalHelperMessage.DYN.addressLine1': 'Veuillez saisir votre adresse de contact. Cette donnée sera vérifiée et requiert donc un justificatif tel que celui ci-dessous.',
  'personnalHelperMessage.DYN.email': 'Veuillez saisir votre email de contact. Cette donnée ne sera pas vérifiée et ne requiert donc pas de justificatif.',
  'personnalHelperMessage.DYN.phone_nr': 'Veuillez saisir votre numéro de téléphone de contact. Cette donnée sera vérifiée et requiert donc un justificatif tel que celui ci-dessous.',
  'phone_nr.DYN.title': 'Numéro de contact',
  'pin.biometric.badPin': 'Votre code pin doit être correct pour activer la biométrie. Veuillez réessayer.',
  'pin.error.numeric': 'Veuillez saisir des chiffres uniquement.',
  'pin.redirectDYN.claim-card_share-claim': 'Pour accéder à votre code QR de partage sécurisé contenant les informations de la carte.',
  'pin.redirectDYN.claim-card_share-claim-mode': 'Pour accéder à votre code QR de partage sécurisé contenant les informations de la carte.',
  'pin.redirectDYN.more_choose-account': 'Pour choisir un autre compte',
  'pin.redirectDYN.more_sign-in-pro': 'Pour ajouter un autre compte',
  'pin.redirectDYN.more-security_change-password': 'Pour modifier le mot de passe',
  'pin.redirectDYN.more-security-biometric_more-security-biometric': 'Pour activer le déverrouillage par données biométriques',
  'pin.redirectDYN.more-security-change-pin_secret-code': 'Pour accéder à la réinitialisation de votre code secret.',
  'pin.redirectDYN.more-security-face-id_more-security-face-id': 'Pour activer votre Face ID',
  'pin.redirectDYN.more-security-fingerprint_more-security-fingerprint': 'Pour activer vos empreintes digitales',
  'pin.redirectDYN.personnal-data-all_share-claim': 'Pour partager vos données personnelles',
  'pin.redirectDYN.secret-code_user': 'Afin d\'enregistrer ce code pour de futures connexions',
  'pin.redirectDYN.welcome-biometric_welcome-done': 'Pour activer le déverrouillage par données biométriques',
  'pin.redirectDYN.scan-share_scan-share-result': 'Pour partager vos données personnelles avec service externes',
  'pin.reset.success': 'Votre code pin a bien été réinitialisé.',
  'place_member.kick': 'Expulser',
  'place-member.activity': 'Activité',
  'place-member.permissions': 'Permissions',
  'place.admin': 'Administration du lieu',
  'place.member': 'Gestion des membres',
  'place.newMember': 'Ajouter un nouveau membre',
  'place.scanner': 'Scanner un utilisateur',
  'placeActivity.title': 'Activité de ',
  'placeMember.title': 'Membres de ',
  'price.promo': '{pricePromo}/an',
  'price.real': '{price}/an',
  'principalMode.set': 'Cet appareil est maintenant votre appareil principal.',
  'profile.btn.change_password': 'Modifier le mot de passe',
  'profile.newPassword': 'Nouveau mot de passe',
  'proof.download': 'Télécharger le justificatif',
  'proof.error.ask': 'Erreur dans le justificatif ?',
  'proof.error.default': 'Votre justificatif comporte une erreur. Veuillez le modifier.',
  'proof.show': 'Afficher le justificatif',
  'proofError.byAdmin': 'Votre <b> justificatif </b> a été refusé par un de nos agents. Veuillez le modifier et réessayer.',
  'proofError.byAdmin.desc': '<u>Message : </u> <br> <i>{reasonMessage}</i>',
  'proofRefusal.desc': 'Veuillez saisir la raison du refus: ',
  'qr.invalid': 'Le QR scanné n\'est pas valide',
  'recover.desc': 'Un e-mail contenant un code de récupération vous a été envoyé',
  'recover.resetPassword.error': 'Erreur lors de la réinitialisation du mot de passe',
  'recover.title': 'Récupérer le mot de passe',
  'refresh.alreadyOpen': 'Récupération des données en cours. Veuillez patienter...',
  'refusal.ask': 'Valider le refus ?',
  'refusalClaimSelf.success': 'Le refus a bien été envoyé.',
  'route.noCBOR': 'Des services requis ne sont pas disponibles sur votre appareil',
  'route.online': 'Vous avez été redirigé car cette page n\'est pas accessible en mode hors ligne. Veuillez vous reconnecter à internet afin d\'y accéder.',
  'scanner.setPlaceAdmin': 'Scannez le code QR de la carte d\'identité ou du badge de l\'utilisateur',
  'scanner.userActivate': 'Scannez le code QR de la carte d\'identité de l\'utilisateur',
  'scanResult.title': 'Résultat du scan',
  'scanShare.desc': 'souhaite lire les données suivantes:',
  'scanShare.title': 'Demande de partage',
  'scanShare.touch': 'Touchez la pile de documents pour l\'ouvrir',
  'search_place.place_without_id.error': 'Place without place_id',
  'searchPlace.desc': 'Sélectionnez ou recherchez un lieu ci-dessous et cliquez dessus pour le gérer.',
  'searchPlace.title': 'Rechercher un lieu',
  'secret-code.codeOK.error': 'Ce code PIN n\'est pas correct',
  'secretCode.desc': 'Créez un code secret à 6 chiffres que vous utiliserez pour vous connecter. Plus tard, vous pourrez choisir une méthode de connexion par empreinte digitale ou reconnaissance faciale.',
  'secretCode.title': 'Définir le code secret',
  'secretCodeValid.msg': 'Entrez votre code',
  'secretCodeValid.title': 'Valider le code secret',
  'security.false': 'Nous avons détecté que votre appareil est rooté. Malheureusement, pour des raisons de sécurité, notre application ne supporte pas les appareils rootés. Afin de profiter de notre service, veuillez utiliser un appareil non rooté.',
  'security.loader': 'Appareil non autorisé',
  'security.root': 'Un téléphone "rooté" crée des risques de sécurité. Ces risques peuvent compromettre la protection de vos données, ce que nous ne permettons pas. ',
  'selectAdmin.offline.expired': 'Votre compte pro a expiré. Veuillez tenter de vous y reconnecter une fois que vous aurez accès à internet.',
  'self.workerAccount': 'Mon profil de membre',
  'selfClaim.DYN.address.success': 'Votre adresse de contact a bien été ajoutée. Elle est maintenant en cours de validation.',
  'selfClaim.DYN.addressLine1.modify': 'Votre adresse de contact a bien été modifié. Elle est maintenant en cours de validation.',
  'selfClaim.DYN.addressLine1.success': 'Votre adresse de contact a bien été ajoutée. Elle est maintenant en cours de validation.',
  'selfClaim.DYN.email.modify': 'Votre email de contact a bien été modifié.',
  'selfClaim.DYN.email.success': 'Votre email de contact a bien été ajouté.',
  'selfClaim.DYN.phone_nr.modify': 'Votre numéro de téléphone de contact a bien été modifié. Il est maintenant en cours de validation.',
  'selfClaim.DYN.phone_nr.success': 'Votre numéro de téléphone de contact a bien été ajouté. Il est maintenant en cours de validation.',
  'service.birth_certificate': 'Demande d\'acte de naissance',
  'service.declaration_loss_theft': 'Déclaration de perte ou de vol',
  'services.disabled': 'Cette fonctionnalité est désactivée pour le moment. Veuillez réessayer plus tard.',
  'services.title': 'Services',
  'setPin.noMatch': 'Le code PIN doit être le même que celui que vous avez écrit précédemment',
  'setPlaceAdmin.action.desc1.auser': ' comme administrateur du ',
  'setPlaceAdmin.action.desc1.buser': 'Êtes-vous sûr de vouloir définir l\'utilisateur ',
  'setPlaceAdmin.action.desc2': 'L\'utilisateur se verra accorder les autorisations définies à l\'étape suivant.',
  'setPlaceAdmin.desc': 'Scannez le code QR de la carte d\'identité ou du badge d\'un utilisateur, choisissez un lieu et définissez l\'utilisateur comme son administrateur.',
  'setPlaceAdmin.done.desc1': 'L\'utilisateur a été défini comme administrateur du lieu sélectionné.',
  'setPlaceAdmin.done.desc2': 'En tant qu\'administrateur du lieu, l\'utilisateur s\'est vu accorder les autorisations définies au cours de ce processus.',
  'setPlaceAdmin.done.title': 'Terminé',
  'setPlaceAdmin.form.desc': 'L\'endroit où l\'utilisateur sera défini comme administrateur.',
  'setPlaceAdmin.form.title': 'Sélectionner un lieu',
  'setPlaceAdmin.scan': 'Scanner le code QR d\'identité',
  'setPlaceAdmin.title': 'Définir l\'admin du lieu',
  'setProAccount.action.desc1': 'Êtes-vous sûr de vouloir ajouter l\'utilisateur au lieu :',
  'setProAccount.action.desc2': 'L\'utilisateur se verra accorder toutes les permissions liées à ce lieu une fois connecté avec son compte Pro.',
  'setProAccount.action.desc3': 'Merci d\'entrer l\'email de l\'utilisateur pour recevoir les identifiants.',
  'setProAccount.desc': 'Scannez le QR code contenant toutes les données de la carte d\'identité d\'un utilisateur afin de l\'ajouter à un lieu existant.',
  'setProAccount.done.desc': 'L\'utilisateur a été ajouté à la place. Si un compte a été créé vous en trouverez les informations ici.',
  'setProAccount.done.title': 'Terminé',
  'setProAccount.form.desc': 'Sélectionnez l\'endroit où vous voulez ajouter l\'utilisateur.',
  'setProAccount.form.title': 'Nouveau compte Pro',
  'setProAccount.scan': 'Scanner l\'identité QR',
  'setProAccount.title': 'Définir un compte Pro',
  'share-claim-done.desc': 'Vos données ont été partagées avec succès avec le récepteur !',
  'share-claim-done.title': 'Données envoyées avec succès',
  'share.all': 'Partager toutes les données',
  'share.choose': 'Sélectionnez la donnée à partager',
  'share.claim.offline': 'Vous ne pouvez pas partager vos informations en mode hors ligne. Veuillez vous reconnecter à internet afin de continuer.',
  'share.claim.pinBlocked': 'Une erreur est survenue lors de votre partage. Veuillez réessayer.',
  'share.claims.iso.content': 'Acceptez-vous de partager {fields} ?',
  'share.claims.iso.content.keptFields': 'Acceptez-vous de partager {fields} ? Les champs stockés par le lecteur seront {keptFields}',
  'share.claims.iso.fields.DYN.age_over_16': 'Si vous avez plus de 16 ans',
  'share.claims.iso.fields.DYN.age_over_18': 'Si vous avez plus de 18 ans',
  'share.claims.iso.fields.DYN.age_over_21': 'Si vous avez plus de 21 ans',
  'share.claims.iso.fields.DYN.age_over_60': 'Si vous avez plus de 60 ans',
  'share.claims.iso.fields.DYN.date_of_birth': 'Date de naissance',
  'share.claims.iso.fields.DYN.doc_nr': 'Numéro de document',
  'share.claims.iso.fields.DYN.expiry_date': 'Date d\'expiration',
  'share.claims.iso.fields.DYN.first_name': 'Prénom',
  'share.claims.iso.fields.DYN.gender': 'Genre',
  'share.claims.iso.fields.DYN.height': 'Taille',
  'share.claims.iso.fields.DYN.image_url': 'Image',
  'share.claims.iso.fields.DYN.last_name': 'Nom',
  'share.claims.iso.fields.DYN.middle_name': 'Second prénom',
  'share.claims.iso.fields.DYN.nationality': 'Nationalité',
  'share.claims.iso.fields.DYN.place_of_birth': 'Lieu de naissance',
  'share.claims.iso.fields.DYN.profession': 'Profession',
  'share.claims.iso.fields.DYN.signature': 'Signature',
  'share.mode.desc': 'L\'utilisation d\'une mauvaise méthode se soldera par l\'échec de la tentative de partage de l\'information.',
  'share.mode.outside': 'Partage en dehors du pays (international)',
  'share.mode.within': 'Partage à l\'intérieur du pays',
  'share.permissions.bluetoothOff': 'Vous avez désactivé votre Bluetooth. Réactivez-le, puis veuillez réessayer.',
  'share.permissions.locationOff': 'Vous avez désactivé votre Localisation. Réactivez-la, puis veuillez réessayer.',
  'shared.data': 'Données personnelles de l\'utilisateur',
  'sign_in.identifier': 'Identifiant',
  'sign_in.login_as_customer': 'Login incorrect : Veuillez vous connecter en tant que citoyen',
  'sign_in.other_device': 'est votre appareil principal. Vous ne pouvez avoir qu\'un seul appareil principal à la fois, qui aura accès à toutes les fonctionnalités disponibles. Souhaitez-vous faire de cet appareil votre appareil principal ?',
  'sign_in.password': 'Mot de passe',
  'signin.email': 'Se connecter par email',
  'signIn.forgotPassword': 'Mot de passe oublié ?',
  'signin.phone': 'Se connecter par téléphone',
  'signin.state.email': 'Vous êtes actuellement en train de vous connecter par <b>email</b>.',
  'signin.state.phone': 'Vous êtes actuellement en train de vous connecter par <b>téléphone</b>.',
  'signIn.title': 'Accès',
  'signInPro.title': 'Accès Pro',
  'signup.desc': 'Créez un compte par <b>email</b> ou par <b>téléphone</b>.',
  'signup.email': 'S\'enregistrer par email',
  'signup.phone': 'S\'enregistrer par téléphone',
  'signup.state.email': 'Vous êtes actuellement en train de vous enregistrer par <b>email</b>.',
  'signup.state.phone': 'Vous êtes actuellement en train de vous enregistrer par <b>téléphone</b>.',
  'signUp.title': 'Créer un compte',
  'SOTRA_CARD.DYN.select': 'Carte SOTRA',
  'sub.buy': 'Achetez votre abonnement dès maintenant !',
  'sub.promo': 'Bénéficiez de 30% de réduction pour le moment !',
  'subscribe.footer': 'Cet abonnement se renouvelle automatiquement chaque année au prix de {price}, à moins qu\'il ne soit annulé avant la prochaine période. Les frais sont débités du moyen de paiement utilisé lors de la confirmation de l\'achat. Vous pouvez gérer vos abonnements et désactiver le renouvellement automatique en accédant à la liste de vos abonnements depuis le store.',
  'success.kickUser': 'Cet utilisateur a bien été supprimé de cette place.',
  'success.logoutAllDevices': 'Tous les autres appareils ont bien été déconnectés.',
  'update_place.title': 'Mise à jour du lieu',
  'upload.proof': 'Ajouter un justificatif',
  'user.activation': 'Activation',
  'user.activation.text': 'Vous allez être invité à scanner le code QR de l\'utilisateur pour commencer le processus d\'activation.',
  'user.alreadyAdded': 'Cet utilisateur a déjà été ajouté.',
  'user.choose_account': 'Changer de compte',
  'user.choose_activity.DYN.account': 'Activité du compte',
  'user.choose_activity.DYN.shared': 'Activité de partage',
  'user.choose_place': 'Choisir le lieu',
  'user.manage_place_worker': 'Gestion du lieu',
  'user.manage_places': 'Gestion lieux',
  'user.manage_users': 'Gestion utilisateurs',
  'user.my_badge': 'Mon badge',
  'userActivate.form.title': 'Activation de l\'utilisateur',
  'userExist.already_registered': 'Cet utilisateur est déjà enregistré en tant qu\'administrateur pour ce lieu',
  'userPermissions.desc': 'Permissions de l\'utilisateur <strong>{user}</strong> dans le lieu <strong>{place}</strong>',
  'userPermissions.done': 'Les permissions ont été définies avec succès',
  'userPermissions.error': 'Les permissions n\'ont pas été correctement définies',
  'userPermissions.title': 'Permissions',
  'userRoleDYN.0': 'Utilisateur',
  'userRoleDYN.1': 'Professionnel',
  'userRoleDYN.2': 'Admin',
  'userRoleDYN.3': 'Super Admin',
  'userScanData.allowAdmin.desc': 'Gérer les lieux et les utilisateurs, lister des documents émis',
  'userScanData.allowAdmin.title': 'A le droit d\'administrer',
  'userScanData.allowConsume.desc': 'Supprimer un document, lister des document émis',
  'userScanData.allowConsume.title': 'A le droit de consommer',
  'userScanData.allowControl.desc': 'Vérifier les informations d\'un utilisateur, Voir l\'OTP du lieu',
  'userScanData.allowControl.title': 'A le droit de contrôler',
  'userScanData.allowCreate.desc': 'Créer un document, lister des documents émis',
  'userScanData.allowCreate.title': 'A le droit de créer',
  'userScanData.desc': 'Droits de l\'utilisateur',
  'userScanData.desc2': 'dans le lieu',
  'userShare.DYN.0': 'Partage du numéro national',
  'userShare.DYN.1': 'Partage de la carte d\'identité',
  'userShare.DYN.10': 'Partage de la carte d\'étranger',
  'userShare.DYN.11': 'Partage de l\'email de contact',
  'userShare.DYN.12': 'Partage de l\'attestation RNPP',
  'userShare.DYN.13': 'Partage de la carte SOTRA',
  'userShare.DYN.14': 'Partage de la carte Mugef-CI',
  'userShare.DYN.15': 'Partage de l\'adresse de contact',
  'userShare.DYN.16': 'Partage de la carte de santé',
  'userShare.DYN.18': 'Partage du téléphone de contact',
  'userShare.DYN.2': 'Partage du permis de conduire',
  'userShare.DYN.3': 'Partage de preuve d\'âge',
  'userShare.DYN.5': 'Partage de la carte de santé',
  'userShare.DYN.6': 'Partage du passeport',
  'userShare.DYN.7': 'Partage de la carte de transport',
  'userShare.DYN.8': 'Partage de la carte de résident',
  'userShare.DYN.9': 'Partage de la carte CEDEAO',
  'welcomeBiometric.desc': 'Vous pouvez activer l\'authentification biométrique afin de vous connecter plus rapidement.',
  'welcomeBiometric.faceID.desc': 'Utilisez votre visage avec Face ID pour vous connecter plus rapidement et facilement plutôt que votre code pin.',
  'welcomeBiometric.fingerprint.desc': 'Utilisez votre empreinte digitale pour vous connecter plus rapidement et facilement plutôt que votre code pin.',
  'welcomeBiometric.title': 'Authentification biométrique',
  'welcomeDone.desc1': 'Nous espérons que vous apprécierez l\'utilisation de {name}. Si vous avez des commentaires ou des suggestions, n\'hésitez pas à nous les faire parvenir en utilisant le bouton Contact sur la page d\'accueil.',
  'welcomeDone.desc2': 'Merci d\'utiliser {name}.',
  'welcomeDone.title': 'Profitez-bien !',
  'welcomeExplain.desc1': 'Avec {name}, gardez votre carte d\'identité sous la main et oubliez les tracas liés à sa gestion.',
  'welcomeExplain.desc2': 'Après l\'enregistrement, vos documents seront disponibles pour vous identifier auprès d\'un agent agréé ou dans toute autre situation où cela est nécessaire.',
  'welcomeExplain.title': 'Qu’est ce que {name} ?',
  'welcomeHello.desc1': 'Bienvenue sur {name}, notre plateforme de digitalisation de documents officiels.',
  'welcomeHello.desc2': 'Nous sommes fiers de vous offrir le meilleur moyen de stocker et utiliser vos documents officiels en ligne.',
  'welcomeHello.title': 'Merci d\'avoir rejoint {name}',
  'workerAdmin.new_member': 'Ajouter un nouveau membre',
  'workerAdmin.see_activity': 'Voir l\'activité',
  'workerAdmin.see_all_members': 'Voir tous les membres',
  'noIdentity.QR.error': 'Mauvais partage de QR code. Veuillez scanner le QR code d\'un partage contentant toutes les données.',
  'user.wrongOtp.server': 'Votre code est incorrect. Veuillez réessayer.',
  'api.place.offline.error': 'Vous êtes actuellement hors-ligne. Veuillez vous reconnecter à internet afin de continuer.',
  'badge.timeout': 'Votre connection internet est trop lente, nous n\'avons pas pu récupérer les données de votre badge. Voulez-vous réessayer ?',
  'choose.place.signInPro': 'Veuillez choisir l\'un des lieux auxquels vous êtes rattaché(e) afin de vous connecter.',
  'ask.admin.remove': 'Êtes-vous sûr de vouloir supprimer cet administrateur ?',
  'place.alreadyChoose': 'Vous avez déjà choisi ce lieu.',
  'share.claims.iso.fields.DYN.family_name': 'Nom de famille',
  'share.claims.iso.fields.DYN.given_name': 'Prénom',
  'share.claims.iso.fields.DYN.birth_date': 'Date de naissance',
  'share.claims.iso.fields.DYN.issue_date': 'Date d\'émission',
  'share.claims.iso.fields.DYN.issuing_country': 'Pays d\'émission',
  'share.claims.iso.fields.DYN.issuing_authority': 'Autorité d\'émission',
  'share.claims.iso.fields.DYN.document_number': 'Numéro de document',
  'share.claims.iso.fields.DYN.portrait': 'Portrait',
  'share.claims.iso.fields.DYN.driving_privileges': 'Privilèges de conduite',
  'share.claims.iso.fields.DYN.un_distinguishing_sign': 'Signe distinctif',
  'share.claims.iso.fields.DYN.administrative_number': 'Numéro administratif',
  'share.claims.iso.fields.DYN.weight': 'Poids',
  'share.claims.iso.fields.DYN.eye_colour': 'Couleur des yeux',
  'share.claims.iso.fields.DYN.hair_colour': 'Couleur des cheveux',
  'share.claims.iso.fields.DYN.birth_place': 'Lieu de naissance',
  'share.claims.iso.fields.DYN.resident_address': 'Adresse de résidence',
  'share.claims.iso.fields.DYN.portrait_capture_date': 'Date de capture du portrait',
  'share.claims.iso.fields.DYN.age_in_years': 'Âge en années',
  'share.claims.iso.fields.DYN.age_birth_year': 'Année de naissance',
  'share.claims.iso.fields.DYN.issuing_jurisdiction': 'Juridiction d\'émission',
  'share.claims.iso.fields.DYN.resident_city': 'Ville de résidence',
  'share.claims.iso.fields.DYN.resident_state': 'État de résidence',
  'share.claims.iso.fields.DYN.resident_postal_code': 'Code postal de résidence',
  'share.claims.iso.fields.DYN.resident_country': 'Pays de résidence',
  'share.claims.iso.fields.DYN.family_name_national_character': 'Nom de famille (caractère national)',
  'share.claims.iso.fields.DYN.given_name_national_character': 'Prénom (caractère national)',
  'share.claims.iso.fields.DYN.signature_usual_mark': 'Signature ou marque habituelle',
  'ask.signout.resetPin': 'Êtes-vous sûr de vouloir réinitialiser votre code PIN ? Cela vous déconnectera également de tous les appareils.',
  'success.resetPinLogout': 'Votre code PIN a bien été réinitialisé. Vous avez été déconnecté de tous les appareils.',
  'error.resetPinLogout': 'Une erreur est survenue lors de la réinitialisation de votre code PIN. Veuillez réessayer.',
  'ask.adminSelf.remove': 'Êtes-vous sûr de vouloir retirer votre compte pro du choix de connexion ? Vous serez redirigé vers votre compte citoyen.',
  'wait.otpCode': 'Vous avez déjà reçu un code OTP il y a peu de temps. Veuillez patienter avant d’en recevoir un nouveau.',
  'user.permissions': 'Modifier les permissions',
  'err.id.maxlength': "L'ID est trop long, il doit être de 40 caractères maximum",
  'err.connectIpRange.maxlength': 'La plage IP est trop longue, elle doit être de 40 caractères maximum',
  'err.tva.maxlength': 'Le numéro de TVA est trop long, il doit être de 30 caractères maximum',
  'err.rcs.maxlength': 'Le numéro RCS est trop long, il doit être de 30 caractères maximum',
  'err.streetNr.maxlength': 'Le numéro de rue est trop long, il doit être de 15 caractères maximum',
  'err.zip.maxlength': 'Le code postal est trop long, il doit être de 30 caractères maximum',
  'err.city.maxlength': 'La ville est trop longue, elle doit être de 60 caractères maximum',
  'client.extended.cannotLinkHigherOrWithoutUniqueId': 'Vous n\'avez pas le droit pour réaliser cette action'
};
