import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../shared/services/api.service';
import {LangService, LoaderService, NavigateService, PinData} from 'ngx-satoris';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-pin',
  templateUrl: './forgot-pin.component.html',
  styleUrls: ['./forgot-pin.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPinComponent {
  data: {state: PinData, queryParams: any};

  constructor(public api: ApiService, 
    public nav: NavigateService, 
    private router: Router,
    private loader: LoaderService,
    private lang: LangService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras;
  }

  signoutButton() {
    if(this.api.userRole.isCustomer) {
      this.loader.loading(true, {type: 'warn', message: this.lang.transform('ask.signout'), btnLabel: this.lang.transform('confirm')}).then((done) =>{
        if(done) {
          this.api.signOut(true);
        }
      });
    }
  }
}
