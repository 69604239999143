import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {LangService, LoaderService} from 'ngx-satoris';
import {SecureStorageService} from 'src/app/shared/services/secure-storage.service';
import {ClaimIdCard, ClaimType} from '../../../../shared/models/claim';
import {completeIdCardAdmin} from '../../../../../environments/environment';
import {FC} from 'src/app/shared/models/enroll';

@Component({
  selector: 'app-activation-step-confirm',
  templateUrl: './activation-step-confirm.component.html',
  styleUrls: ['./activation-step-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepConfirmComponent {
  inConfirm = false;

  constructor(public api: ApiService,
              public enroll: EnrollService,
              private loader: LoaderService,
              private lang: LangService,
              private secure: SecureStorageService) {}

  confirm() {
    this.inConfirm = true;
    this.loader.loading(true);

    if(this.api.userRole.isAdmin) {
      this.claimIdentityByAdmin().then(() => {
        this.claimIdCardByAdmin().then(() => {
          this.loader.loadingElement('webcam', false);
          this.enroll.form.controls[FC.USER_ID] = undefined;
          this.enroll.navigate('next');
        }).catch(err => {
          this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.createClaim.error')});
        });
      }).catch(err => {
        this.loader.loading(true, {type: 'error', message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.createClaim.error')});
      });
    } else {
      this.secure.retrieveSecretKey().then(() => this.api.confirmScPicture(this.enroll.form.controls[FC.NNI].value,
        this.enroll.form.controls[FC.FACE_PICTURE].value,
        this.secure.fullJwk.x,
        this.secure.fullJwk.y,
        'device',
        this.enroll.form.controls[FC.NFC].value.sod,
        this.enroll.form.controls[FC.NFC].value.dg1,
        this.enroll.form.controls[FC.NFC].value.dg2,
        this.enroll.form.controls[FC.NFC].value.dg11,
        this.enroll.form.controls[FC.NFC].value.dg12,
        this.enroll.form.controls[FC.SIGNATURE].value).then(() => {
        this.loader.loading(false);
        this.enroll.navigate('next');
      })).catch(err => {
        this.loader.loading(true, {type: 'error', message: this.lang.transform(err)});
        this.inConfirm = false;
      });
    }
  }

  claimIdCardByAdmin() {
    const serialized: ClaimIdCard = {
      image_url: this.enroll.form.controls[FC.FACE_PICTURE].value.trim(),
      first_name: [this.enroll.form.controls[FC.FIRST_NAME].value],
      middle_name: this.enroll.form.controls[FC.MIDDLE_NAME].value.length > 0 ?
          this.enroll.form.controls[FC.MIDDLE_NAME].value.split(',').map((element: string) => element.trim()) :
          [],
      last_name: this.enroll.form.controls[FC.LAST_NAME].value.split(',').map((element: string) => element.trim()),
      expiry_date: new Date(this.enroll.form.controls[FC.DOC_EXPIRY_DATE].value.trim()).toISOString(),
      gender: this.enroll.form.controls[FC.GENDER].value.trim(),
      nationality: this.enroll.form.controls[FC.NATIONALITY].value.trim(),
      mrz: this.enroll.form.controls[FC.MRZ].value,
      signature: this.enroll.form.controls[FC.SIGNATURE].value.trim(),
      doc_nr: this.enroll.form.controls[FC.DOC_NUMBER].value.trim(),
      date_of_birth: undefined
    };
    completeIdCardAdmin(serialized, this.enroll.form);

    return this.api.createClaim({
      createdAt: '',
      created_by_user__id: this.api.userInfo.id,
      expiresAt: new Date(this.enroll.form.controls[FC.DOC_EXPIRY_DATE].value).getTime(),
      externalId: this.enroll.form.controls[FC.PERSONAL_NUMBER].value,
      fetchable: true,
      fetchedAt: '',
      id: '',
      place_id: this.api.userPlaceId,
      readUuid: '',
      readUuidUntil: '',
      revokedAt: '',
      revoked_by_user__id: 0,
      serialized: JSON.stringify(serialized),
      signature: this.enroll.form.controls[FC.SIGNATURE].value,
      type: ClaimType.ID_CARD,
      user__id: this.enroll.form.controls[FC.USER_ID].value,
      imageUrlRecto: '',
      imageUrlVerso: ''
    }, this.enroll.form.controls[FC.PERSONAL_NUMBER].value, undefined);
  }

  claimIdentityByAdmin() {
    return this.api.createClaim({
      createdAt: '',
      created_by_user__id: this.api.userInfo.id,
      expiresAt: new Date(this.enroll.form.controls[FC.DOC_EXPIRY_DATE].value).getTime(),
      externalId: this.enroll.form.controls[FC.PERSONAL_NUMBER].value,
      fetchable: false,
      fetchedAt: '',
      id: '',
      place_id: this.api.userPlaceId,
      readUuid: '',
      readUuidUntil: '',
      revokedAt: '',
      revoked_by_user__id: 0,
      serialized: '"' + this.enroll.form.controls[FC.PERSONAL_NUMBER].value + '"',
      signature: this.enroll.form.controls[FC.SIGNATURE].value,
      type: ClaimType.IDENTITY,
      user__id: this.enroll.form.controls[FC.USER_ID].value,
      imageUrlRecto: '',
      imageUrlVerso: ''
    }, this.enroll.form.controls[FC.PERSONAL_NUMBER].value, undefined);
  }
}
