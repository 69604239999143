<div id="layout" class="f_col layout_sm">
  <app-view-title bgImage="assets-countries/header_background.png">{{'more.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20">
      <app-slide-element leftIcon="shield-halved"  (btnClicked)="nav.to('more-security')" class="mb_10">
        {{'more.security' | translate}}
      </app-slide-element>
      <app-slide-element [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="headset" (btnClicked)="sync.isOnline ? api.openSupport() : sync.loaderOffline()" class="mb_10">
        {{'global.help_center' | translate}}
      </app-slide-element>
      <app-slide-element leftIcon="language" (btnClicked)="nav.to('choose-lang', undefined,  { state: { fromRoute: 'more'} })" class="mb_10">
        {{'more.language' | translate}}
      </app-slide-element>
      <app-slide-element *ngIf="!nav.demoMode && (((api.userInfo?.workerView || api.userInfo?.placeAdminView || !api.userRole?.isCustomer) && !addMode) || (api.userInfo?.links?.length > 0 && jwts.length > 1))" leftIcon="user-tie" (btnClicked)="nav.toPin('more', 'choose-account', true)">
        {{'user.choose_account' | translate}}
      </app-slide-element>
      <app-slide-element [disabled]="!sync.isOnline" *ngIf="!nav.demoMode && addMode"  [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="user-plus" (btnClicked)="sync.isOnline  ? nav.toPin('more', 'sign-in-pro', true, undefined, {queryParams: {keepPin: true}}) : sync.loaderOffline()">
        {{'chooseAccount.new_account' | translate}}
      </app-slide-element>
      <div *ngIf="(api.userInfo?.links?.length > 1) && api.userPlaceId !== undefined">
        <app-slide-element  [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="person-digging" (btnClicked)="sync.isOnline  ? nav.toPin('more', 'choose-place') : sync.loaderOffline()" class="mt_10">
          {{'user.choose_place' | translate}}
        </app-slide-element>
      </div>
      <app-slide-element [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="clock-rotate-left" (btnClicked)="sync.isOnline ? nav.toPin('more', 'choose-activity', false, undefined, {queryParams: {type: 'account'}}) : sync.loaderOffline()" class="mt_10">
        {{'user.choose_activity.DYN.account' | translate}}
      </app-slide-element>
      <app-slide-element [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="clock-rotate-left" (btnClicked)="sync.isOnline ? nav.toPin('more', 'choose-activity', false, undefined, {queryParams: {type: 'shared'}}) : sync.loaderOffline()" class="mt_10">
          {{'user.choose_activity.DYN.shared' | translate}}
      </app-slide-element>
      <app-slide-element *ngIf="devices.isDevices('cordova') && api.userRole?.isCustomer && this.secure.fullJwk?.hash !== this.api.userInfo?.deviceHash"
                         [disabled]="!sync.isOnline" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="mobile-screen-button"
                         (btnClicked)="sync.isOnline ? toSetPrimaryDevice() : sync.loaderOffline()" class="mt_10">
        {{'more.setPrimaryDevice' | translate}}
      </app-slide-element>
      <app-slide-element *ngIf="devices.isDevices('cordova') && !init.isPro" [disabled]="!sync.isOnline || !init.products[0]" [colorRight]="!sync.isOnline ? '#f51d2a' : undefined" [rightIcon]="!sync.isOnline ? 'wifi' : 'arrow-right'" leftIcon="clock-rotate-left" (btnClicked)="sync.isOnline ? nav.to('subscription', undefined, {state: {fromRoute: 'more'}}) : sync.loaderOffline()" class="mt_10">
        {{'more.purchaseSubscription' | translate}}
      </app-slide-element>
      <div class="flex gap_10 f_align_c f_center">
        <div class="flex gap_10 f_center">
          <div class="logo_certif">
            <img src="assets/images/logo_icao.svg" alt="">
          </div>
          <div class="logo_certif">
            <img src="assets/images/logo_iso.svg" alt="">
          </div>
        </div>
      </div>
      <div class="flex gap_10 f_align_c f_center">
        <app-paragraph textAlign="center" (click)="follow(devices.isDevices('cordova-ios') ? 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/' : api.env.eulaLink)">{{'global.eula' | translate}}</app-paragraph>
      </div>
      <div class="flex gap_10 f_align_c f_center">
        <app-paragraph textAlign="center" (click)="follow(api.env.privacyLink)">{{'global.privacy' | translate}}</app-paragraph>
      </div>
    </div>
    <app-paragraph *ngIf="api.userInfo?.server?.demoMode" class="mt_20" textAlign="center" (press)="disableDemoMode(true)" (pressup)="disableDemoMode(false)">{{(nav.demoMode ? 'demoMode.on' : 'demoMode.off') | translate}}</app-paragraph>
    <app-button customBackgroundColor="var(--clr-btn-red)" (btnClicked)="signoutButton()" class="mt_20 fullWidth">{{'header.disconnect' | translate}}</app-button>
    <div class="mt_10" *ngIf="nav.demoMode">
      <app-button color="warn" (btnClicked)="deleteUser()" class="fullWidth">{{'more.delete_user' | translate}}</app-button>
    </div>
  </div>
</div>
