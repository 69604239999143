import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';

const animationTime = '0.4s';

export const routeTransitionAnimations = trigger('triggerName', [
  transition('' +
    'manageNewsArticles => newsSelect, ' +
    'news => newsSelect, ' +
    'manageNews => manageNewsNew, ' +
    'manageNews => manageNewsArticles, ' +
    'user => manageNews, ' +
    'more => activationStepIntro, ' +
    'activationStepIntro => activationStepVoucher, ' +
    'activationStepVoucher => activationStepAskDeviceName, ' +
    'activationStepIntro => activationStepAskDeviceName, ' +
    'user => activationStepIntro, ' +
    'scanShare => user, ' +
    'scan => scanShare, ' +
    'moreSecurity => changePin, ' +
    'changePin => secretCodeValid, ' +
    'services => scan, ' +
    'services => news, ' +
    'services => more, ' +
    'services => serviceIframe, ' +
    'activation => activationCode, ' +
    'activation => activationCodeInfo,' +
    'activation => chooseLang,' +
    'activation => nfcRead, ' +
    'activationCodeInfo => activationCode,' +
    'activationCodeInfo => activationCodeMap,' +
    'activationCodeInfo => chooseLang,' +
    'activationCodeInfo => secretCodeValid,' +
    'activity => devicePermissions, ' +
    'activity => more, ' +
    'activity => news, ' +
    'activity => scan, ' +
    'changePassword => changePasswordDone,' +
    'changePassword => services, ' +
    'changePassword => more, ' +
    'changePassword => news, ' +
    'changePassword => scan, ' +
    'changePassword => user, ' +
    'chooseAccount => services, ' +
    'chooseAccount => news, ' +
    'chooseAccount => scan, ' +
    'chooseAccount => secretCodeValid, ' +
    'chooseAccount => signInPro, ' +
    'chooseAccount => user, ' +
    'chooseLang => devicePermissions, ' +
    'chooseLang => scan, ' +
    'choosePlace => secretCode,' +
    'createPlace => createPlaceAdmin, ' +
    'createPlace => devicePermissions, ' +
    'devicePermissions => userShare, ' +
    'accountValid => chooseLang,' +
    'forgotPassword => chooseLang,' +
    'forgotPassword => recover, ' +
    'forgotPin => chooseLang,' +
    'home => chooseLang,' +
    'home => signUp, ' +
    'managePlace => createPlace, ' +
    'managePlace => devicePermissions, ' +
    'managePlace => more, ' +
    'managePlace => searchPlace, ' +
    'managePlace => setPlaceAdmin, ' +
    'managePlaceWorker => placeSettings,' +
    'managePlaceWorker => placeActivity,' +
    'managePlaceWorker => placeMembers,' +
    'managePlaceWorker => setProAccount,' +
    'manageUser => devicePermissions, ' +
    'manageUser => more, ' +
    'manageUser => searchUser, ' +
    'manageUser => setProAccount, ' +
    'more => chooseLang,' +
    'more => devicePermissions, ' +
    'more => home,' +
    'more => moreAbout, ' +
    'more => moreData, ' +
    'more => moreFeedback, ' +
    'more => moreSecurity, ' +
    'more => secretCodeValid,' +
    'moreSecurity => devicePermissions, ' +
    'moreSecurity => moreSecurityBiometric,' +
    'moreSecurity => news,' +
    'news => devicePermissions, ' +
    'news => more, ' +
    'nfcRead => services, ' +
    'nfcRead => chooseLang,' +
    'nfcRead => more, ' +
    'nfcRead => news, ' +
    'nfcRead => nfcReadCan, ' +
    'nfcRead => nfcReadScan,' +
    'nfcRead => scan, ' +
    'nfcRead => user, ' +
    'nfcReadConfirmation => services, ' +
    'nfcReadConfirmation => chooseLang,' +
    'nfcReadConfirmation => more, ' +
    'nfcReadConfirmation => news, ' +
    'nfcReadConfirmation => nfcReadDone,' +
    'nfcReadConfirmation => scan, ' +
    'nfcReadConfirmation => user, ' +
    'nfcReadDone => services, ' +
    'nfcReadDone => chooseLang,' +
    'nfcReadDone => more, ' +
    'nfcReadDone => news, ' +
    'nfcReadDone => user, ' +
    'nfcReadDrawSignature => services, ' +
    'nfcReadDrawSignature => chooseLang,' +
    'nfcReadDrawSignature => more, ' +
    'nfcReadDrawSignature => news, ' +
    'nfcReadDrawSignature => nfcReadConfirmation,' +
    'nfcReadPin => nfcReadDrawSignature,' +
    'nfcReadDrawSignature => scan, ' +
    'nfcReadDrawSignature => user, ' +
    'nfcReadCan => services, ' +
    'nfcReadCan => chooseLang,' +
    'nfcReadCan => more, ' +
    'nfcReadCan => news, ' +
    'nfcReadCan => nfcReadScan, ' +
    'nfcReadCan => scan, ' +
    'nfcReadCan => user, ' +
    'nfcReadFound => services, ' +
    'nfcReadFound => chooseLang,' +
    'nfcReadFound => more, ' +
    'nfcReadFound => news, ' +
    'nfcReadFound => nfcReadDrawSignature,' +
    'nfcReadFound => scan, ' +
    'nfcReadFound => user, ' +
    'nfcReadPin => chooseLang,' +
    'nfcReadScan => services, ' +
    'nfcReadScan => chooseLang,' +
    'nfcReadScan => more, ' +
    'nfcReadScan => news, ' +
    'nfcReadScan => nfcReadFound,' +
    'nfcReadScan => scan, ' +
    'nfcReadScan => user, ' +
    'placeMember => userPermissions,' +
    'placeMember => placeActivity,' +
    'placeMembers => placeMember,' +
    'recover => passwordChanged, ' +
    'scan => more, ' +
    'scan => news, ' +
    'scan => scanResult, ' +
    'searchPlace => services, ' +
    'searchPlace => createPlace, ' +
    'searchPlace => devicePermissions, ' +
    'searchPlace => more, ' +
    'searchPlace => news, ' +
    'searchPlace => scan, ' +
    'searchPlace => user, ' +
    'secretCode => secretCodeValid, ' +
    'secretCodeValid => activation, ' +
    'secretCodeValid => changePassword,' +
    'secretCodeValid => chooseAccount, ' +
    'secretCodeValid => signInPro, ' +
    'secretCodeValid => forgotPin, ' +
    'secretCodeValid => user, ' +
    'secretCodeValid => userShare, ' +
    'secretCodeValid => userShareMode, ' +
    'setPlaceAdminAction => userPermissions,' +
    'setPlaceAdmin => services, ' +
    'setPlaceAdmin => devicePermissions, ' +
    'setPlaceAdmin => more, ' +
    'setPlaceAdmin => news, ' +
    'setPlaceAdmin => scan, ' +
    'setPlaceAdmin => setPlaceAdminScan, ' +
    'setPlaceAdmin => user, ' +
    'setPlaceAdminScan => services, ' +
    'setPlaceAdminScan => more, ' +
    'setPlaceAdminScan => news, ' +
    'setPlaceAdminScan => scan, ' +
    'setProAccount => services, ' +
    'setProAccount => devicePermissions, ' +
    'setProAccount => more,' +
    'setProAccount => news, ' +
    'setProAccount => scan, ' +
    'setProAccount => setProAccountScan, ' +
    'setProAccount => user, ' +
    'setProAccountAction => setProAccountDone, ' +
    'setProAccountForm => setProAccountAction, ' +
    'setProAccountScan => services, ' +
    'setProAccountScan => more, ' +
    'setProAccountScan => news, ' +
    'setProAccountScan => scan, ' +
    'setProAccountScan => setProAccountForm, ' +
    'setProAccountScan => user, ' +
    'settings => devicePermissions, ' +
    'signIn => activation, ' +
    'signIn => chooseLang,' +
    'signIn => forgotPassword, ' +
    'signIn => secretCode, ' +
    'signIn => secretCodeValid, ' +
    'signInPro => secretCodeValid, ' +
    'signUp => chooseLang,' +
    'signUp => accountValid, ' +
    'user => services, ' +
    'user => badge, ' +
    'user => choosePlace,' +
    'user => devicePermissions, ' +
    'user => managePlace, ' +
    'user => managePlaceWorker,' +
    'user => manageUser, ' +
    'user => more, ' +
    'user => news, ' +
    'user => scan, ' +
    'user => userCovid, ' +
    'user => userDriverCard, ' +
    'user => userIdCard, ' +
    'user => userHcCard, ' +
    'user => userTransportCard, ' +
    'user => userPassport, ' +
    'user => userTransport, ' +
    'user => userPro, ' +
    'user => claimCard, ' +
    'claimCard => claimData, ' +
    'claimCard => shareClaim, ' +
    'claimCard => shareClaimMode, ' +
    'claimCard => secretCodeValid, ' +
    'secretCodeValid => shareClaim, ' +
    'secretCodeValid => shareClaimMode, ' +
    'shareClaimMode => shareClaim, ' +
    'userPermissions => setPlaceAdminDone,' +
    'userActivateForm => userTakePicture, ' +
    'userActivate => services, ' +
    'qrScan => activity, ' +
    'qrScan => more, ' +
    'qrScan => news, ' +
    'qrScan => nfcReadCan, ' +
    'qrScan => user, ' +
    'qrScan => userActivateForm, ' +
    'userActivate => services, ' +
    'userActivate => devicePermissions, ' +
    'userActivate => more, ' +
    'userActivate => news, ' +
    'userActivate => scan, ' +
    'userActivate => user, ' +
    'userActivate => userActivateScan, ' +
    'userActivateForm => userTakePicture, ' +
    'qrScan => services, ' +
    'qrScan => more, ' +
    'qrScan => news, ' +
    'qrScan => nfcReadCan, ' +
    'qrScan => user, ' +
    'qrScan => userActivateForm, ' +
    'userCovid => devicePermissions, ' +
    'userDriverCard => devicePermissions, ' +
    'userDriverCard => userShare, ' +
    'userDriverCard => userShareMode, ' +
    'userDriverCard => userDriver, ' +
    'userDriverCard => services, ' +
    'userDriverCard => devicePermissions, ' +
    'userDriverCard => more, ' +
    'userDriverCard => news, ' +
    'userDriverCard => scan, ' +
    'userDriverCard => secretCodeValid, ' +
    'userHcCard => userHc, ' +
    'userHcCard => services, ' +
    'userHcCard => more, ' +
    'userHcCard => news, ' +
    'userHcCard => scan, ' +
    'userTransportCard => userTransport, ' +
    'userTransportCard => services, ' +
    'userTransportCard => more, ' +
    'userTransportCard => news, ' +
    'userTransportCard => scan, ' +
    'userHcCard => secretCodeValid, ' +
    'userHc => services, ' +
    'userHc => more, ' +
    'userHc => news, ' +
    'userHc => scan, ' +
    'userHc => secretCodeValid, ' +
    'userShareMode => userShare, ' +
    'userId => devicePermissions, ' +
    'userIdCard => services, ' +
    'userIdCard => devicePermissions, ' +
    'passwordChanged => chooseLang, ' +
    'userIdCard => more, ' +
    'userIdCard => news, ' +
    'userIdCard => scan, ' +
    'userIdCard => secretCodeValid, ' +
    'userIdCard => userId, ' +
    'userIdCard => userShare, ' +
    'userShare => devicePermissions, ' +
    'userShare => secretCodeValid, ' +
    'userShare => shareClaimDone, ' +
    'userPassport => devicePermissions, ' +
    'userTransport => devicePermissions, ' +
    'userTakePicture => userActivateDone, ' +
    'moreSecurity => changePassword, ' +
    'signInPro => forgotPassword, ' +
    'userIdCard => userShareMode, ' +
    'userShareMode => userShare, ' +
    'userDriver => userShareMode, ' +
    'userDriver => services, ' +
    'userDriver => more, ' +
    'userDriver => news, ' +
    'userDriver => scan, ' +
    'nfcReadDrawSignature => nfcReadScan, ' +
    'nfcReadCan => nfcReadPin, ' +
    'nfcReadPin => nfcReadScan, ' +
    'nfcReadCan => nfcReadDrawSignature, ' +
    'qrScan => activateForm, ' +
    'activateForm => nfcReadDrawSignature, ' +
    'nfcRead => qrScan, ' +
    'nfcReadDrawSignature => userActivateCamera, ' +
    'activationCode => user, ' +
    'activation1 => user, ' +
    'activationCodeInfo => user, ' +
    'activationCodeInfo => welcomeHello, ' +
    'signIn => welcomeHello, ' +
    'activationCodeInfo => user, ' +
    'userActivateCamera => nfcReadDone, ' +
    'welcomeHello => chooseLang, ' +
    'welcomeHello => welcomeExplain, ' +
    'welcomeExplain => chooseLang, ' +
    'welcomeExplain => welcomeDone, ' +
    'welcomeDone => user, ' +
    'welcomeDone => chooseLang, ' +
    'nfcReadDone => welcomeHello, ' +
    'welcomeDone => secretCodeValid, ' +
    'secretCodeValid => welcomeHello, ' +
    'activationCode => welcomeHello, ' +
    'nfcReadDrawSignature => nfcReadScan, ' +
    'nfcReadCan => nfcReadPin, ' +
    'nfcReadPin => nfcReadScan, ' +
    'nfcReadCan => nfcReadDrawSignature, ' +
    'secretCodeValid => choosePlace, ' +
    'moreSecurity => secretCodeValid, ' +
    'changePasswordDone => secretCodeValid, ' +
    'changePasswordDone => moreSecurity, ' +
    'managePlaceWorker => services, ' +
    'managePlaceWorker => news, ' +
    'managePlaceWorker => more, ' +
    'placeMembers => user, ' +
    'placeMembers => services, ' +
    'placeMembers => news, ' +
    'placeMembers => more, ' +
    'placeActivity => user, ' +
    'placeActivity => services, ' +
    'placeActivity => news, ' +
    'placeActivity => more, ' +
    'setPlaceAdminScan => setPlaceAdminForm, ' +
    'setPlaceAdminForm => setPlaceAdminAction, ' +
    'setPlaceAdminDone => managePlace, ' +
    'setProAccountScan => user, ' +
    'secretCodeValid => welcomeDone, ' +
    'setProAccountScan => setProAccountAction, ' +
    'setProAccountForm => user, ' +
    'userShareMode => devicePermissions, ' +
    'welcomeExplain => welcomeBiometric, ' +
    'welcomeBiometric => welcomeDone, ' +
    'welcomeBiometric => secretCodeValid, ' +
    'moreSecurity => moreSecurityBiometric, ' +
    'moreSecurityBiometric => secretCodeValid, ' +
    'moreSecurity => user, ' +
    'moreSecurity => services, ' +
    'moreSecurity => more, ' +
    'moreSecurity => news, ' +
    'moreActivity => user, ' +
    'moreActivity => news, ' +
    'moreActivity => services, ' +
    'moreActivity => more, ' +
    'more => moreActivity, ' +
    'secretCodeValid => moreActivity, ' +
    'activation => activationStepIntro, ' +
    'activation => devicePermissions, ' +
    'activationStepIntro => activationStepScanMrzChoice, ' +
    'activationStepIntro => activationStepDrawSignature, ' +
    'activationStepScanMrzChoice => activationStepChoice, ' +
    'activationStepChoice => activationStepScanMrzScan, ' +
    'activationStepChoice => activationStepScanMrz, ' +
    'activationStepChoice => activationStepDocNumber, ' +
    'activationStepDocNumber => activationStepDocExpDate, ' +
    'activationStepDocExpDate => activationStepDateOfBirth, ' +
    'activationStepDateOfBirth => activationStepScanMrzNfc, ' +
    'activationStepScanMrzScan => activationStepScanMrzNfc, ' +
    'activationStepScanMrzNfc => activationStepAskNni, ' +
    'activationStepAskNni => activationStepDrawSignature, ' +
    'activationStepDrawSignature => activationStepTakeFacePicture, ' +
    'activationStepTakeFacePicture => activationStepConfirm, ' +
    'activationStepAskCan => activationStepAskPin, ' +
    'activationStepDrawSignature => chooseLang, ' +
    'activationStepAskCan => chooseLang, ' +
    'activationStepAskPin => chooseLang, ' +
    'activationStepNfcScanIdCard => chooseLang, ' +
    'devicePermissions => activationStepIntro, ' +
    'activationStepAskPin => activationStepNfcScanIdCard, ' +
    'activationStepScanMrzNfc => activationStepScanMrzNfc, ' +
    'activationStepScanMrz => activation, ' +
    'activationStepIntro => chooseLang, ' +
    'activationStepDescTakeFacePicture => chooseLang, ' +
    'activationStepDescTakeFacePicture => activationStepTakeFacePicture, ' +
    'user => managerUser, ' +
    'manageUser => activationStepIntro, ' +
    'activationStepIntro => qrScan, ' +
    'qrScan => activationStepChoice, ' +
    'activationStepAskCan => activationStepNfcScanIdCard, ' +
    'activationStepDrawSignature => activationStepAskCan, ' +
    'activationStepDrawSignature => activationStepAskCan, ' +
    'activationStepDrawSignature => activationStepNfcScanIdCard, ' +
    'activationStepChoice => activationStepFormUser, ' +
    'qrScan => activationStepFormUser, ' +
    'activationStepFormUser => activationStepDrawSignature, ' +
    'activationStepDrawSignature => activationStepCamera, ' +
    'activationStepCamera => activationStepDone, ' +
    'activationStepCamera => activationStepConfirm, ' +
    'activationStepChoice => activationStepDrawSignature, ' +
    'securityProvider => chooseLang, ' +
    'activationStepDrawSignature => activationStepTakeFacePicture, ' +
    'moreSecurity => moreSecurityOtpExplain, ' +
    'moreSecurityOtpExplain => moreSecurityOtp, ' +
    'activationStepAskCan => activationStepChoice, ' +
    'news => serviceIframe, ' +

    'moreSecurityOtpExplain => more, ' +
    'moreSecurityOtpExplain => news, ' +
    'moreSecurityOtpExplain => user, ' +
    'moreSecurityOtpExplain => services, ' +
    'moreSecurityOtpExplain => scan, ' +

    'moreSecurityOtp => more, ' +
    'moreSecurityOtp => news, ' +
    'moreSecurityOtp => user, ' +
    'moreSecurityOtp => services, ' +
    'moreSecurityOtp => scan, ' +
    'signIn => accountValid, ' +

    'claimCard => more, ' +
    'claimCard => news, ' +
    'claimCard => services, ' +
    'claimCard => scan, ' +

    'claimData => more, ' +
    'claimData => news, ' +
    'claimData => user, ' +
    'claimData => services, ' +
    'claimData => scan, ' +

    'shareClaimMode => more, ' +
    'shareClaimMode => news, ' +
    'shareClaimMode => user, ' +
    'shareClaimMode => services, ' +
    'shareClaimMode => scan, ' +

    'activationStepAskDeviceName => activationStepDrawSignature, ' +
    'activationStepAskDeviceName => chooseLang, ' +
    'activationStepChoice => activationStepAskDeviceName, ' +
    'shareClaim => shareClaimDone, ' +
    'signInPro => home, ' +
    'activationStepChoice => activationStepAskPin, ' +
    'activationStepChoice => activationStepNfcScanIdCard, ' +
        'more => signInPro, ' +
    'more => chooseAccount, ' +

    'shareClaimMode => secretCodeValid, ' +
    'secretCodeValid => shareClaim, ' +

    'user => personnalData, ' +
    'personnalData => more, ' +
    'personnalData => news, ' +
    'personnalData => services, ' +
    'personnalData => scan, ' +
    'secretCodeValid => personnalData, ' +
    'personnalData => personnalDataAdd, ' +
    'personnalData => personnalDataExplain, ' +
    'secretCodeValid => personnalDataType, ' +
    'personnalData => personnalDataExplain, ' +
    'personnalData => personnalDataType, ' +
    'personnalData => personnalDataSubType, ' +
    'personnalDataType => personnalDataSubType, ' +
    'personnalData => personnalDataAll, ' +
    'manageUser => personnalDataManage, ' +
    'personnalDataManage => personnalDataManageId, ' +
    'personnalDataManageId => personnalDataManageProof, ' +
    'personnalDataManageId => personnalDataManageRefusal, ' +

    'personnalDataAll => more, ' +
    'personnalDataAll => news, ' +
    'personnalDataAll => services, ' +
    'personnalDataAll => scan, ' +

    'personnalDataType => more, ' +
    'personnalDataType => news, ' +
    'personnalDataType => services, ' +
    'personnalDataType => scan, ' +

    'personnalDataSubType => more, ' +
    'personnalDataSubType => news, ' +
    'personnalDataSubType => services, ' +
    'personnalDataSubType => scan, ' +

    'personnalDataManage => more, ' +
    'personnalDataManage => news, ' +
    'personnalDataManage => services, ' +
    'personnalDataManage => scan, ' +

    'personnalDataManageId => more, ' +
    'personnalDataManageId => news, ' +
    'personnalDataManageId => services, ' +
    'personnalDataManageId => scan, ' +

    'personnalDataManageRefusal => more, ' +
    'personnalDataManageRefusal => news, ' +
    'personnalDataManageRefusal => services, ' +
    'personnalDataManageRefusal => scan, ' +

    'personnalDataExplain => more, ' +
    'personnalDataExplain => news, ' +
    'personnalDataExplain => services, ' +
    'personnalDataExplain => scan, ' +

    'personnalDataShare => more, ' +
    'personnalDataShare => news, ' +
    'personnalDataShare => services, ' +
    'personnalDataShare => scan, ' +
    'personnalData => personnalDataShare, ' +
    'personnalDataAll => shareClaim, ' +
    'personnalDataAll => secretCodeValid, ' +
    'personnalDataAll => personnalDataExplain, ' +
    'personnalDataSubtype => personnalDataExplain, ' +

    'subscription => more, ' +
    'subscription => news, ' +
    'subscription => services, ' +
    'subscription => scan, ' +

    'claimCard => subscription, ' +
    'more => subscription, ' +


    'home => signIn', [
    style({position: 'relative'}),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%'
      })
    ], {optional: true}),
    query(':enter', [style({transform: 'translateX(100vw)', opacity: 1})], {optional: true}),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate(animationTime+' ease', style({transform: 'translateX(-100vw)', opacity: 0}))], {optional: true}),
      query(':enter', [animate(animationTime+' ease', style({transform: 'translateX(0)', opacity: 1}))], {optional: true})
    ]),
    query(':enter', animateChild(), {optional: true})
  ]),
  transition('' +
    'activationStepVoucher => activationStepIntro, ' +
    'activationStepAskDeviceName => activationStepVoucher, ' +
    'newsSelect => news, ' +
    'newsSelect => manageNewsArticles, ' +
    'manageNewsNew => manageNews, ' +
    'manageNewsArticles => manageNews, ' +
    'manageNews => user, ' +
    'activationStepAskDeviceName => activationStepIntro, ' +
    'activationStepIntro => user, ' +
    'changePin => moreSecurity, ' +
    'secretCodeValid => changePin, ' +
    'services => user, ' +
    'serviceIframe => services, ' +
    'activation => secretCodeValid, ' +
    'activation => signIn, ' +
    'activationCode => activation, ' +
    'activationCode => activationCodeInfo,' +
    'activationCodeInfo => activation,' +
    'activationCodeMap => activationCodeInfo,' +
    'activationSelfForm => activationSelf,' +
    'activity => changePassword, ' +
    'activity => chooseAccount, ' +
    'activity => manageUser, ' +
    'activity => nfcRead, ' +
    'activity => nfcReadConfirmation, ' +
    'activity => nfcReadDone, ' +
    'activity => nfcReadDrawSignature, ' +
    'activity => nfcReadCan, ' +
    'activity => nfcReadFound, ' +
    'activity => nfcReadScan, ' +
    'activity => searchPlace, ' +
    'activity => setPlaceAdmin, ' +
    'activity => setProAccount, ' +
    'activity => setProAccountScan, ' +
    'activity => user, ' +
    'activity => qrExplain, ' +
    'badge => user, ' +
    'changePassword => moreSecurity,' +
    'changePasswordDone => services,' +
    'changePasswordDone => more,' +
    'changePasswordDone => news,' +
    'changePasswordDone => scan,' +
    'changePasswordDone => user,' +
    'chooseLang => activation,' +
    'chooseLang => activationCodeInfo,' +
    'chooseLang => services, ' +
    'chooseLang => accountValid,' +
    'chooseLang => forgotPassword,' +
    'chooseLang => forgotPin,' +
    'chooseLang => home,' +
    'chooseLang => more, ' +
    'chooseLang => nfcRead,' +
    'chooseLang => nfcReadConfirmation,' +
    'chooseLang => nfcReadDone,' +
    'chooseLang => nfcReadDrawSignature,' +
    'chooseLang => nfcReadCan,' +
    'chooseLang => nfcReadFound,' +
    'chooseLang => nfcReadPin,' +
    'chooseLang => nfcReadScan,' +
    'chooseLang => signIn,' +
    'chooseLang => signUp,' +
    'chooseLang => user, ' +
    'choosePlace => user, ' +
    'createPlace => *, ' +
    'createPlaceAdmin => *, ' +
    'devicePermissions => services, ' +
    'devicePermissions => chooseLang, ' +
    'devicePermissions => createPlace, ' +
    'devicePermissions => managePlace, ' +
    'devicePermissions => manageUser, ' +
    'devicePermissions => more, ' +
    'devicePermissions => news, ' +
    'devicePermissions => searchPlace, ' +
    'devicePermissions => setPlaceAdmin, ' +
    'devicePermissions => setProAccount, ' +
    'devicePermissions => settings, ' +
    'devicePermissions => user, ' +
    'devicePermissions => userCovid, ' +
    'devicePermissions => userDriver, ' +
    'devicePermissions => userId, ' +
    'devicePermissions => userIdCard, ' +
    'devicePermissions => userPassport, ' +
    'devicePermissions => userTransport, ' +
    'userTransportCard => user, ' +
    'accountValid => signIn, ' +
    'forgotPassword => signIn, ' +
    'forgotPin => home, ' +
    'forgotPin => secretCodeValid, ' +
    'managePlace => user, ' +
    'managePlaceWorker => user, ' +
    'manageUser => user, ' +
    'more => services, ' +
    'more => changePassword, ' +
    'chooseAccount => more, ' +
    'more => devicePermissions, ' +
    'more => manageUser, ' +
    'more => news, ' +
    'more => nfcRead, ' +
    'more => nfcReadConfirmation, ' +
    'more => nfcReadDone, ' +
    'more => nfcReadDrawSignature, ' +
    'more => nfcReadCan, ' +
    'more => nfcReadFound, ' +
    'more => nfcReadScan, ' +
    'more => scan, ' +
    'more => searchPlace, ' +
    'more => setPlaceAdmin, ' +
    'more => setProAccount, ' +
    'more => setProAccountScan, ' +
    'more => signIn, ' +
    'more => user, ' +
    'more => qrExplain, ' +
    'moreAbout => *, ' +
    'moreData => *, ' +
    'moreFeedback => *, ' +
    'moreSecurity => more, ' +
    'moreSecurityBiometric => moreSecurity, ' +
    'news => services, ' +
    'news => changePassword, ' +
    'news => chooseAccount, ' +
    'news => devicePermissions, ' +
    'news => manageUser, ' +
    'news => nfcRead, ' +
    'news => nfcReadConfirmation, ' +
    'news => nfcReadDone, ' +
    'news => nfcReadDrawSignature, ' +
    'news => nfcReadCan, ' +
    'news => nfcReadFound, ' +
    'news => nfcReadScan, ' +
    'news => scan, ' +
    'news => searchPlace, ' +
    'news => setPlaceAdmin, ' +
    'news => setProAccount, ' +
    'news => setProAccountScan, ' +
    'news => user, ' +
    'news => qrExplain, ' +
    'nfcRead => activation,' +
    'nfcRead => nfcReadPin, ' +
    'nfcReadConfirmation => nfcReadDrawSignature,' +
    'nfcReadDone => nfcReadConfirmation,' +
    'nfcReadDrawSignature => nfcReadFound,' +
    'nfcReadCan => activation, ' +
    'nfcReadCan => nfcRead,' +
    'nfcReadCan => scan, ' +
    'nfcReadFound => nfcReadScan,' +
    'nfcReadPin => activation, ' +
    'nfcReadDrawSignature => nfcReadPin,' +
    'nfcReadScan => nfcRead,' +
    'nfcReadScan => nfcReadCan, ' +
    'nfcReadScan => nfcReadPin, ' +
    'nfcReadScan => scan, ' +
    'passwordChanged => signIn, ' +
    'placeSettings => managePlaceWorker, ' +
    'placeActivity => managePlaceWorker, ' +
    'placeActivity => placeMember, ' +
    'placeMember => placeMembers, ' +
    'placeMembers => managePlaceWorker, ' +
    'scan => services, ' +
    'scan => changePassword, ' +
    'scan => chooseAccount, ' +
    'scan => manageUser, ' +
    'scan => nfcRead, ' +
    'scan => nfcReadCan, ' +
    'scan => nfcReadScan, ' +
    'scan => searchPlace, ' +
    'scan => setPlaceAdmin, ' +
    'scan => setProAccount, ' +
    'scan => setProAccountScan, ' +
    'scan => user, ' +
    'scanResult => scan, ' +
    'searchPlace => managePlace, ' +
    'searchUser => manageUser, ' +
    'secretCode => signIn, ' +
    'secretCode => user, ' +
    'secretCodeValid => forgotPassword, ' +
    'secretCodeValid => more, ' +
    'secretCodeValid => secretCode, ' +
    'secretCodeValid => userDriverCard, ' +
    'setPlaceAdmin => managePlace, ' +
    'setPlaceAdminScan => setPlaceAdmin, ' +
    'setPlaceAdminScan => user, ' +
    'setProAccount => managePlaceWorker, ' +
    'setProAccount => manageUser, ' +
    'setProAccountAction => setProAccount, ' +
    'setProAccountAction => setProAccountForm, ' +
    'setProAccountDone => setProAccount, ' +
    'setProAccountDone => setProAccountAction, ' +
    'setProAccountForm => setProAccount, ' +
    'setProAccountScan => setProAccount, ' +
    'signInPro => chooseAccount,' +
    'moreSecurity => user,' +
    'moreSecurity => services,' +
    'signUp => home, ' +
    'signin => recover, ' +
    'user => changePassword, ' +
    'user => chooseAccount, ' +
    'user => chooseAccount, ' +
    'user => nfcRead, ' +
    'user => nfcReadConfirmation, ' +
    'user => nfcReadDone, ' +
    'user => nfcReadDrawSignature, ' +
    'user => nfcReadCan, ' +
    'user => nfcReadFound, ' +
    'user => nfcReadScan, ' +
    'user => searchPlace, ' +
    'user => setPlaceAdmin, ' +
    'user => setProAccount, ' +
    'user => setProAccountScan, ' +
    'user => secretCodeValid, ' +
    'userPermissions => placeMember,' +
    'userPermissions => setPlaceAdminAction,' +
    'activationStepIntro => manageUser, ' +
    'qrScan => activationStepIntro, ' +
    'userCovid => *, ' +
    'userDriverCard => user, ' +
    'secretCodeValid => userDriverCard, ' +
    'userShare => userDriverCard, ' +
    'shareClaimDone => user, ' +
    'shareClaimDone => services, ' +
    'shareClaimDone => scan, ' +
    'shareClaimDone => more, ' +
    'shareClaimDone => news, ' +
    'userDriver => userDriverCard, ' +
    'userDriver => user, ' +
    'userHc => userHcCard, ' +
    'userHc => user, ' +
    'userHcCard => user, ' +
    'userShareMode => userDriverCard, ' +
    'userShare => userShareMode, ' +
    'userShare => user,' +
    'userId => userIdCard, ' +
    'recover => forgotPassword, ' +
    'chooseLang => passwordChanged, ' +
    'userIdCard => user, ' +
    'userShare => userIdCard, ' +
    'userPassport => *, ' +
    'userTransport => *, ' +
    'userPro => *, ' +
    'userTakePicture => userActivateForm, ' +
    'changePassword => moreSecurity, ' +
    'forgotPassword => signInPro, ' +
    'userShareMode => userIdCard, ' +
    'userShare => userShareMode, ' +
    'userShareMode => userDriver, ' +
    'activation => home, ' +
    'nfcReadPin => nfcReadCan, ' +
    'nfcReadDrawSignature => nfcReadCan, ' +
    'activateForm => qrScan, ' +
    'nfcRead => qrScan, ' +
    'userActivateCamera => nfcReadDrawSignature, ' +
    'nfcReadDrawSignature => activateForm, ' +
    'nfcReadScan => nfcReadDrawSignature, ' +
    'chooseLang => welcomeHello, ' +
    'welcomeExplain => welcomeHello, ' +
    'chooseLang => welcomeExplain, ' +
    'chooseLang => welcomeDone, ' +
    'welcomeDone => welcomeExplain, ' +
    'nfcReadPin => nfcReadCan, ' +
    'nfcReadDrawSignature => nfcReadCan, ' +
    'nfcReadScan => activation, ' +
    'setPlaceAdminForm => setPlaceAdminScan, ' +
    'setPlaceAdminAction => setPlaceAdminForm, ' +
    'setPlaceAdminAction => setPlaceAdmin, ' +
    'setProAccountScan => manageUser, ' +
    'setProAccountScan => user, ' +
    'setProAccountForm => user, ' +
    'setProAccountAction => user, ' +
    'setProAccountAction => setProAccountScan, ' +
    'devicePermissions => userShareMode, ' +
    'scanResult => user, ' +
    'scanResult => services, ' +
    'scanResult => more, ' +
    'welcomeBiometric => welcomeExplain, ' +
    'welcomeDone => welcomeBiometric, ' +
    'secretCodeValid => welcomeBiometric, ' +
    'secretCodeValid => moreSecurityBiometric, ' +
    'secretCodeValid => userIdCard, ' +
    'moreSecurityBiometric => moreSecurity, ' +
    'signInPro => more, ' +
    'scanResult => news, ' +
    'moreActivity => more, ' +
    'chooseAccount => home, ' +
    'more => chooseAccount, ' +
    'activationStepScanMrz => activationStepChoice, ' +
    'activationStepScanMrzScan => activationStepChoice, ' +
    'activationStepScanMrzScan => activation, ' +
    'activationStepDocExpDate => activationStepDocNumber, ' +
    'activationStepDocNumber => activationStepChoice, ' +
    'activationStepScanMrzNfc => activationStepDateOfBirth, ' +
    'activationStepDateOfBirth => activationStepDocExpDate, ' +
    'activationStepScanMrzChoice => activationStepIntro, ' +
    'activationStepChoice => activationStepScanMrzChoice, ' +
    'activationStepChoice => activationStepIntro, ' +
    'activationStepIntro => activation, ' +
    'devicePermissions => activation, ' +
    'activationStepDrawSignature => activationStepIntro, ' +
    'activationStepAskCan => activationStepDrawSignature, ' +
    'activationStepScanMrzNfc => activationStepScanMrzScan, ' +
    'activationStepAskNni => activationStepScanMrzNfc, ' +
    'activationStepDrawSignature => activationStepAskNni, ' +
    'activationStepTakeFacePicture => activationStepDrawSignature, ' +
    'activationStepConfirm => activationStepTakeFacePicture, ' +
    'activationStepTakeFacePicture => activationStepDone, ' +
    'activationStepDone => welcomeHello, ' +
    'activationStepAskPin => activationStepAskCan, ' +
    'chooseLang => activationStepIntro, ' +
    'chooseLang => activationStepDrawSignature, ' +
    'chooseLang => activationStepAskCan, ' +
    'chooseLang => activationStepNfcScanIdCard, ' +
    'chooseLang => activationStepAskPin, ' +
    'activationStepNfcScanIdCard => activationStepAskPin, ' +
    'activationStepNfcScanIdCard => activation, ' +
    'manageUser => user, ' +
    'activationStepIntro => manageUser, ' +
    'qrScan => activationStepIntro, ' +
    'activationStepChoice => qrScan, ' +
    'activationStepChoice => activationStepAskCan, ' +
    'activationStepNfcScanIdCard => activationStepAskCan, ' +
    'activationStepNfcScanIdCard => manageUser, ' +
    'activationStepNfcScanIdCard => activationStepDrawSignature, ' +
    'activationStepDone => user, ' +
    'activationStepFormUser => activationStepChoice, ' +
    'activationStepFormUser => qrScan, ' +
    'activationStepDrawSignature => activationStepFormUser, ' +
    'activationStepDrawSignature => activationStepAskCan, ' +
    'activationStepCamera => activationStepDrawSignature, ' +
    'activationStepConfirm => activationStepCamera, ' +
    'activationStepDrawSignature => activationStepChoice, ' +
    'chooseLang => activationStepDescTakeFacePicture, ' +
    'activationStepTakeFacePicture => activationStepDescTakeFacePicture, ' +
    'chooseLang => securityProvider, ' +
    'claimCard => user, ' +
    'secretCodeValid => claimCard, ' +
    'claimData => claimCard, ' +
    'shareClaim => claimCard, ' +
    'shareClaimMode => claimCard, ' +
    'shareClaim => shareClaimMode, ' +
    'activationStepTakeFacePicture => activationStepDrawSignature, ' +
    'moreSecurityOtpExplain => moreSecurity, ' +
    'moreSecurityOtp => moreSecurityOtpExplain, ' +
    'moreSecurityOtp => moreSecurity, ' +
    'secretCodeValid => moreSecurity, ' +
    'secretCodeValid => welcomeBiometric, ' +
    'changePassword => home, ' +
    'serviceIframe => news, ' +
    'passwordChanged => user, ' +
    'passwordChanged => chooseAccount, ' +
    'accountValid => signIn, ' +
    'secretCode => changePin, ' +
    'passwordChanged => signInPro, ' +
    'activationStepDrawSignature => activationStepAskDeviceName, ' +
    'activationStepAskDeviceName => activationStepChoice, ' +
    'chooseLang => activationStepAskDeviceName, ' +
    'shareClaimDone => claimCard, ' +
    'serviceIframe => user, ' +
    'shareClaim => user, ' +
    'signInPro => user, ' +
    'personnalData => user, ' +
    'personnalDataType => personnalData, ' +
    'personnalDataType => user, ' +
    'personnalDataType => news, ' +
    'personnalDataType => scan, ' +
    'personnalDataType => services, ' +
    'personnalDataExplain => personnalData, ' +
    'personnalDataSubType => personnalData, ' +
    'personnalData => personnalDataSubType, ' +
    'personnalDataAll => personnalData, ' +
    'personnalDataSubType => personnalDataType, ' +
    'personnalDataManage => manageUser, ' +
    'personnalDataManageId => personnalDataManage, ' +
    'personnalDataManageProof => personnalDataManageId, ' +
    'personnalDataManageRefusal => personnalDataManageId, ' +
    'personnalDataManageRefusal => personnalDataManage, ' +
    'personnalDataManageRefusal => user, ' +
    'personnalDataShare =>  personnalData, ' +
    'shareClaim => personnalDataAll, ' +
    'secretCodeValid => personnalDataAll, ' +
    'personnalDataExplain => personnalDataAll, ' +
    'personnalDataExplain => personnalDataSubType, ' +

    'activationStepAskPin => activationStepChoice, ' +
    'activationStepNfcScanIdCard => activationStepChoice, ' +
    'activationStepChoice => activationStepAskCan, ' +

    'secretCodeValid => shareClaimMode, ' +
    'shareClaim => secretCodeValid, ' +
    'signInPro => more, ' +

    'subscription => claimCard , ' +
    'subscription => more, ' +

    'more => subscription, ' +
    'news => subscription, ' +
    'services => subscription, ' +
    'scan => subscription, ' +
    'subscription => user, ' +
    'userPermissions => placeMembers, ' +
    'choosePlace => more, ' +

    'signIn => home', [
    style({position: 'relative'}),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], {optional: true}),
    query(':enter', [style({transform: 'translateX(-100vw)', opacity: 1})], {optional: true}),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate(animationTime+' ease', style({transform: 'translateX(100vw)', opacity: 0}))], {optional: true}),
      query(':enter', [animate(animationTime+' ease', style({transform: 'translateX(0)', opacity: 1}))], {optional: true})
    ]),
    query(':enter', animateChild(), {optional: true})
  ])
]);
