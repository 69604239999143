<div id="layout" class="sign_in f_col">
  <app-view-title bgImage="assets-countries/header_background.png">{{'userPermissions.title' | translate}}</app-view-title>
  <div class="scroll_wrapper">
    <div class="f_grow mt_20" [hidden]="!user">
      <app-paragraph class="mb_40" [innerhtml]="'userPermissions.desc' | translate : {user: this.user?.name || this.user?.identifier, place: this.api?.currentPlace?.longName}"></app-paragraph>
      <form *ngIf="show" id="form" [formGroup]="form" (ngSubmit)="submitForm()">
        <div class="mt_10">
          <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm1'" [text]="'place.newMember'" (onChange)="changePerm($event, 'perm1')"></app-checkbox-element>
        </div>
        <!-- <div class="mt_10"> -->
        <!--   <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm2'" [text]="'place.scanner'" (onChange)="changePerm($event, 'perm2')"></app-checkbox-element> -->
        <!-- </div> -->
        <div class="mt_10">
          <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm3'" [text]="'place.scanner'" (onChange)="changePerm($event, 'perm3')"></app-checkbox-element>
        </div>
        <div class="mt_10">
          <app-checkbox-element [formGrp]="form" [formCtrlName]="'perm4'" [text]="'place.member'" (onChange)="changePerm($event, 'perm4')"></app-checkbox-element>
        </div>
        <app-errors-list [dark]="true" *ngIf="submitted" [formErrors]="forms.getFormErrors(this.form)"></app-errors-list>
      </form>
    </div>
    <div class="f_align_end f_grow mb_above_nav">
      <app-back-btn (click)="goBack()"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button type="submit" formId="form" class="fullWidth">{{'global.confirm' | translate}}</app-button>
      </div>
    </div>
  </div>
</div>
