import {AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from '../../../shared/services/api.service';
import {DevicesService, LangService, LoaderService, NavigateService, PinData} from 'ngx-satoris';
import {SecureStorageService} from '../../../shared/services/secure-storage.service';
import {SyncService} from '../../../shared/services/sync.service';
import {BiometricService} from 'src/app/shared/services/biometric.service';
import {getStoredItem, setStoredItem} from 'src/app/shared/utils/storage';
import {TimeoutError} from 'rxjs';

declare const window: any;

@Component({
  selector: 'app-secret-code-valid',
  templateUrl: './secret-code-valid.component.html',
  styleUrls: ['./secret-code-valid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SecretCodeValidComponent implements OnInit, OnDestroy, AfterViewInit {
  inputPin: HTMLInputElement;
  codeOk: boolean;
  code: string;
  showError: boolean;
  isBiometricCurrentlySetup: boolean;
  showCorrect: boolean;
  pinTimeout: any;
  data: {state: PinData, queryParams: any};
  timeOut: boolean;
  checkCodeTimeout: NodeJS.Timeout;

  constructor(public nav: NavigateService,
    public api: ApiService,
    private router: Router,
    private zone: NgZone,
    private lang: LangService,
    private loader: LoaderService,
    private deviceService: DevicesService,
    private secure: SecureStorageService,
    private sync: SyncService,
    private biometric: BiometricService) {
    this.data = <any> this.router.getCurrentNavigation()?.extras;
  }
  ngAfterViewInit() {
    this.inputPin = document.getElementById('secret_code_input') as HTMLInputElement;
  }

  ngOnInit() {
    this.timeOut = false;
    this.api.currentlyUnlockingPin = false;
    this.api.retrySecretCodeBtn = false;
    if(this.data?.state?.resetPin) {
      this.api.resetPin = true;
    }
    this.secretCodeCheck();

    if(this.biometric.biometric.isRegistered){
      setTimeout(() => {
        this.checkBiometricAuth();
      }, 500);
    } else {
      this.submit();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.checkCodeTimeout);
    this.loader.loading(false);
  }


  secretCodeCheck() {
    if(this.api.userInfo && !this.api.userInfo?.pinHash && !this.data?.state?.setPin) {
      this.nav.to('secret-code', undefined, {state: {fromRoute: this.data?.state?.fromRoute}});
    }
  }


  submit() {
    this.showError = false;
    this.showCorrect = false;
    if(this.codeOk && !this.timeOut) {
      this.timeOut = true;
      if(this.data?.state?.setPin) {
        this.setPin();
      } else if(this.data?.state?.setBiometric && !this.isBiometricCurrentlySetup){
        this.isBiometricCurrentlySetup = true;
        this.api.unlockPin(this.code, false).then((res: any) => {
          if(res?.error?.additionalInfo === 'client.extended.wrongPin') {
            this.showError = true;
            this.timeOut = false;
            this.loader.loading(true, {type: 'error', message: this.lang.transform('pin.biometric.badPin')}).then(()=>{
              this.loader.loading(true);
              setTimeout(() => {
                this.api.currentlyUnlockingPin= false;
                this.isBiometricCurrentlySetup = false;
              }, 3000);
            });
          } else {
            this.biometric.setBiometric(this.code).then((status: any) => {
              if(status === 'Success' || status === 'biometric_success' || status === 'success') {
                window?.Fingerprint?.isAvailable(() => {
                  const finalBiometric = () => {
                    setStoredItem('biometric', true);
                    this.biometric.biometric.isRegistered = true;
                    this.biometric.biometric.isGranted = true;
                    this.unlockPin();
                    this.biometric.currentlyValidated = true;
                  };

                  if(this.deviceService.isDevices('cordova-android')){
                    finalBiometric();
                  } else {
                    this.biometric.checkAuth().then((secret) => {
                      this.code = secret;
                      finalBiometric();
                    }, ()=>{
                      this.biometric.biometric.isGranted = false;
                      this.nav.to(this.data?.state?.toRoute ? this.data?.state?.toRoute  : 'welcome-done');
                    });
                  }
                }).catch(() => {
                  this.biometric.biometric.isGranted = false;
                  this.nav.to(this.data?.state?.toRoute ? this.data?.state?.toRoute  : 'welcome-done');
                });
              } else {
                this.loader.loading(true, {type: 'error', message: this.lang.transform('biometric.faceID.setError')});
              }
            }).catch((err:any) => {
              const navigateAfterError = () => {
                const isWelcomeDone = getStoredItem('welcomeDone');
                if(isWelcomeDone){
                  this.nav.to('more-security');
                } else {
                  this.nav.to('welcome-biometric');
                }
              };
              if(err.code === window?.Fingerprint?.BIOMETRIC_DISMISSED) {
                this.loader.loading(true, {type: 'error', message: this.lang.transform('biometric.dismissed')}).then(()=>{
                  navigateAfterError();
                });
              } else if(err.code === window?.Fingerprint?.BIOMETRIC_UNKNOWN_ERROR){
                this.loader.loading(true, {type: 'error', message: this.lang.transform('biometric.maybeNoExist')}).then(()=>{
                  navigateAfterError();
                });
              } else if(err.code === window?.Fingerprint?.BIOMETRIC_UNAVAILABLE) {
                this.biometric.biometric.isGranted = false;
                this.loader.loading(true, {type: 'error', message: this.lang.transform('biometric.notAvailable')}).then(()=>{
                  navigateAfterError();
                });
              }
            });
          }
        }).catch(err => {
          this.timeOut = false;
          this.api.retrySecretCodeBtn = true;
          this.api.currentlyUnlockingPin= false;
          const errorMessage = (err instanceof TimeoutError)
            ? this.lang.transform('call.timeout') : String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.unlockPin.error');
          this.loader.loading(true, {type: 'error', message: errorMessage});

        });
      } else {
        this.unlockPin();
      }
    }
  }

  private setPin() {
    this.loader.loading(true);
    if(this.data?.state?.setPin === this.code) {
      this.api.setPin(this.code, this.data?.queryParams?.pin || undefined).then(() => {
        this.api.userInfo.pinHash = true;
        setTimeout(() => {
          this.unlockPin();
        }, 2000);
      }).catch(err => {
        this.loader.loading(true, {
          type: 'error',
          message: String(err).indexOf(': ') > -1 ? err : this.lang.transform('api.setPin.error')
        });
      });
    } else {
      setTimeout(() => {
        this.showError = true;
        this.loader.loading(true, {type: 'warn', message: 'setPin.noMatch'}).then(()=>{
          this.loader.loading(true);
          setTimeout(() => {
            this.api.currentlyUnlockingPin= false;
            this.loader.loading(false);
          }, 4000);
        });
      });
      this.inputPin.focus();
    }
  }

  private checkBiometricAuth(): void {
    this.biometric.checkAuth().then((result) => {
      this.api.currentlyUnlockingPin= true;
      this.loader.loading(true);
      this.code = result;
      this.unlockPin();
    }).catch((err: any) => {
      this.loader.loading(false);
      console.error(err);
    });
  }

  private unlockPin(): void {
    this.loader.loading(true);
    if(this.sync.isOnline) {
      const unlockPin = () => this.api.unlockPin(this.code, false).then(res => { //TODO: Check de pas false si on passe en offline
        if(this.data?.state?.setBiometric) this.isBiometricCurrentlySetup = false;
        if(res) {
          if(res?.error?.additionalInfo === 'client.extended.pinNotReady') {
            this.loader.loading(true, {type: 'warn', message: this.lang.transform(res.error?.additionalInfo)}).then(()=>{
              this.loader.loading(true);
            });
          }
          this.showError = true;
          this.loader.loading(true, {type: 'error', message: this.lang.transform('secret-code.codeOK.error')}).then(()=>{
            this.loader.loading(true);
            setTimeout(() => {
              this.loader.loading(false);
              this.api.currentlyUnlockingPin = false;
              this.inputPin.focus();
            }, 3000);
          });
          this.showCorrect = false;
          this.api.pinUnlocked = false;
        } else {
          if(!this.api.firstUnlockPin) this.api.firstUnlockPin = true;
          this.showCorrect = true;
          this.api.pinUnlocked = true;
          this.timeOut = false;
          if(this.deviceService.isDevices('cordova')) {
            this.secure.getEncryptSecureDatas('pin').then((pin: string) => {
              if(!pin) {
                this.secure.setEncryptSecureDatas('pin', this.code);
              }
            });
          }
          setTimeout(() => {
            this.zone.run(() => {
              if(this.data?.state?.finaliseResetPin) {
                setTimeout(() => {
                  this.loader.loading(true, {type: 'valid', message: this.lang.transform('pin.reset.success')});
                }, 500);
              }
              const state = {...this.data?.state, pin: this.data?.queryParams?.keepPin ? this.code : undefined};
              this.nav.to(this.data?.state?.toRoute ?? 'user', undefined, {state: state, queryParams: this.data?.queryParams});
              this.loader.loading(false);
            });
          }, 500); // wait for anim to finish
        }
      }).catch((err: any) => {
        if(err instanceof TimeoutError) {
          this.loader.loading(true, {type: 'error', message: this.lang.transform('call.timeout')});
        }
        this.loader.loading(false);
        if(this.deviceService.isDevices('cordova')){
          if(this.api.isCachedDatas()){
            this.secure.getEncryptSecureDatas('pin').then((pin: string) => {
              if(pin === this.code && !this.api.firstUnlockPin) {
                this.api.offlineManual();
                this.showCorrect = true;
                if(!this.api.firstUnlockPin) this.api.firstUnlockPin = true;
                this.api.pinUnlocked = true;
                setTimeout(() => {
                  this.zone.run(() => {
                    this.nav.to(this.data?.state?.toRoute ?? 'user', undefined, {state: this.data?.state, queryParams: this.data?.queryParams});
                  });
                }, 500); // wait for anim to finish
              } else {
                this.api.retrySecretCodeBtn = true;
                this.timeOut = false;
                this.showError = true;
                setTimeout(() => {
                  this.api.currentlyUnlockingPin= false;
                }, 1500);
              }
            });
          } else {
            this.api.retrySecretCodeBtn = true;
            this.showError = true;
            this.timeOut = false;
            setTimeout(() => {
              this.api.currentlyUnlockingPin= false;
            }, 1500);
          }
        } else {
          this.api.retrySecretCodeBtn = true;
          this.showError = true;
          this.timeOut = false;
          setTimeout(() => {
            this.api.currentlyUnlockingPin= false;
          }, 1500);
        }
      });
      unlockPin();
    } else if(this.deviceService.isDevices('cordova')) {
      this.secure.getEncryptSecureDatas('pin').then((pin: string) => {
        if(pin === this.code) {
          if(!this.api.firstUnlockPin) this.api.firstUnlockPin = true;
          this.showCorrect = true;
          this.api.pinUnlocked = true;

          setTimeout(() => {
            this.loader.loading(false);
            this.zone.run(() => {
              this.nav.to(this.data?.state?.toRoute ?? 'user', undefined, {state: this.data?.state, queryParams: this.data?.queryParams});
            });
          }, 500); // wait for anim to finish
        } else {
          this.showError = true;
          setTimeout(() => {
            this.loader.loading(false);
            this.api.currentlyUnlockingPin= false;
          }, 2000);
        }
      });
    } else {
      this.loader.loading(false);
      this.showError = true;
      this.showCorrect = false;
      this.api.pinUnlocked = false;
    }
  }

  checkCode(e: string[]) {
    this.codeOk = e.length >= 6;
    this.zone.run(() => {
      this.code = e.join('');
    });
    const codeNumeric = /^\d+$/.test(this.code);


    if(this.codeOk && !codeNumeric) {
      this.codeOk = false;
      setTimeout(() => {
        this.showError = true;
      });
      this.showCorrect = false;
      this.timeOut = false;
      setTimeout(() => {
        this.loader.loading(true, {type: 'error', message: this.lang.transform('pin.error.numeric')});
      }, 500);
    } else if(this.codeOk && !this.api.currentlyUnlockingPin) {
      this.showError = false;
      this.showCorrect = false;
      this.api.currentlyUnlockingPin= true;
      this.timeOut = false;
      this.submit();
    }
  }

  back() {
    if(this.data?.state?.setPin) {
      this.api.userInfo.pinHash = this.api.resetPin ? true : false;
    }
    this.nav.to(this.data?.state?.fromRoute || this.data?.queryParams?.fromRoute, undefined, {queryParams: this.data?.queryParams, state: {...this.data?.state, pin: this.data?.queryParams?.pin}});
  }

  getLabel() {
    return (this.data?.state?.fromRoute ? this.data?.state?.fromRoute.split('?')[0].replace('/', '') + '_' : '') + this.data?.state?.toRoute.split('?')[0].replace('/', '');
  }
}
