import {Component, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {EnrollService} from '../../../../shared/services/enroll.service';
import {DevicesService, DialogLoadingData, LangService, LoaderService, NavigateService} from 'ngx-satoris';
import {ScanService} from 'src/app/shared/services/scan.service';
import {NfcService} from 'src/app/shared/services/nfc.service';
import {TimeoutError} from 'rxjs';
import {CountryName} from '../../../../shared/models/countries';

declare const cordova: any;

@Component({
  selector: 'app-activation-step-take-face-picture',
  templateUrl: './activation-step-take-face-picture.component.html',
  styleUrls: ['./activation-step-take-face-picture.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivationStepTakeFacePictureComponent {
  optionsCamera = {
    x: 0,
    y: 0,
    width: window.screen.width,
    height: window.innerHeight - (this.api.userRole.isAdmin ? 180 : 100),
    camera: 'front',
    toBack: false,
    tapPhoto: this.device.isDevices('cordova') && cordova?.platformId?.toUpperCase() === 'IOS',
    tapFocus: true,
    previewDrag: false,
    storeToFile: false,
    disableExifHeaderStripping: true
  };

  cameraReady = false;
  pictureProcessing = false;
  picture: string;
  imageQuality = 1;

  constructor(public api: ApiService,
    public enroll: EnrollService,
    public device: DevicesService,
    private nfc: NfcService,
    private loader: LoaderService,
    private scan: ScanService,
    private nav: NavigateService,
    private lang: LangService) {
  }

  ngOnInit() {
    if(this.api.userRole.isAdmin && (!this.scan.userScanData || !this.scan.userScanData?.data?.sig)) {
      this.loader.loading(true, {type: 'error', message: this.lang.transform('api.createClaim.missingData')}).then(() => {
        this.nav.to('user');
      });
    }
  }

  setPicture(imageUrl: string) {
    this.picture = imageUrl;
  }

  onConfirm() {
    if(!this.nfc.pkiCert){
      if(this.api.env.country === CountryName.UTO) {
        this.enroll.form.controls[this.enroll.fc.FACE_PICTURE].setValue(this.picture);
      }
      this.enroll.navigate('next');
    } else if(this.picture) {
      this.enroll.form.controls[this.enroll.fc.FACE_PICTURE].setValue(this.picture);
      this.checkPin();
    } else {
      this.close();
    }
  }

  showPinDialog(cardPin: string) {
    const showPinOptions: DialogLoadingData = {
      type: 'info',
      custom: {
        disableClose: true,
        innerHtml: this.lang.transform('getPin.faceCheck', {pin: cardPin})
      }
    };
    this.loader.loading(true, showPinOptions).then(() => {
      this.loader.loading(true, {
        btnLabel: 'global.yes',
        type: 'question',
        message: 'question.getPin.confirm',
        custom: {
          closeBtnLabel: 'global.no',
          disableClose: true
        }
      }).then((done) => {
        if(done){
          this.loader.loading(true, {type: 'info', message: 'info.getPin.valid'}).then(() => this.enroll.navigate('next'));
        } else {
          this.showPinDialog(cardPin);
        }
      });
    });
  }

  checkPin() {
    this.loader.loading(true);
    this.api.getPin(this.nfc.icao.personalDetails.personalNumber, this.nfc.icao.mrz.documentNumber, this.enroll.form.controls[this.enroll.fc.FACE_PICTURE].value)
      .then((cardPin: string) => {
        this.enroll.form.controls[this.enroll.fc.PIN].setValue(cardPin);
        this.showPinDialog(cardPin);
      })
      .catch((err: TimeoutError | string) => {
        console.error(err);
        if(typeof err === 'string'){
          if(err?.includes('InvalidImage')) {
            this.loader.loading(true, {type: 'error', message: 'err.getPin.invalidImage', btnLabel: 'retry', custom: {closeBtnLabel: 'cancel'}})
              .then((done: boolean) => {
                if(done) {
                  this.imageQuality = this.imageQuality - 0.25 > 0.25 ? this.imageQuality - 0.25 : 0.25;
                  this.close();
                } else this.enroll.navigate('previous', 1);
              });
          } else {
            const message = (err.includes('client.extended.noFaceAuth') || err.includes('"Code":"2"')) ? 'err.getPin.noMatch' : this.lang.transform('error.basic') + '. ' + err;
            this.loader.loading(true, {type: 'error', message: message, btnLabel: 'global.yes', custom: {closeBtnLabel: 'global.no'}})
              .then((done: boolean) => done ? this.close() : this.enroll.navigate('previous', 1));
          }
        } else {
          console.error(`err with object: ${err.message}`);
          this.close();
        }
      });
  }

  close() {
    this.picture = '';
    this.enroll.form.controls[this.enroll.fc.FACE_PICTURE].setValue('');
  }

  uploadImage(event: any) {
    //! 0.75 ratio between width and height (480x640)
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 480;
        canvas.height = 640;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const resizedImageDataURL = canvas.toDataURL(file.type);

        this.picture = resizedImageDataURL;
        this.enroll.form.controls[this.enroll.fc.FACE_PICTURE].setValue(this.picture);
        event.target.value = '';
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  }

}
