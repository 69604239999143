<div id="layout" class="f_col"  [class.layout_sm]="!api.userRole.isCustomer">
  <app-view-title bgImage="assets-countries/header_background.png" fromRoute="activation-step-scan-mrz" toRoute="choose-lang" [chooseLangBtn]="api.userRole.isCustomer && true">
    {{'activation.step.scan_mrz_choice.title' | translate}}
  </app-view-title>
  <div class="scroll_wrapper">
    <app-paragraph class="mt_20 mb_10 text_center f_grow" innerhtml="{{'activation.step.scan_mrz_choice.desc' | translate}}"></app-paragraph>

    <div class="mrzType_list mb_40">
       <div *ngFor="let item of enroll.MRZTypes | keyvalue" (click)="enroll.MRZType = item.value;enroll.navigate('next')" class="mrzType_item" [class.selectedMRZ]="item.value === enroll.MRZType">
         <div class="mrzType_item_img">
          <img src="assets-countries/{{item.value}}.png" alt="document">
         </div>
         <span class="mt_20">{{('mrzType.DYN.' + item.value) | translate}}</span>
       </div>
    </div>

    <div class="f_align_end f_grow">
      <app-back-btn (btnClicked)="enroll.navigate('previous')"></app-back-btn>
      <div class="fullWidth ml_10">
        <app-button [disabled]="!enroll.MRZType" (btnClicked)="enroll.navigate('next')" class="fullWidth">
          {{'global.continue' | translate}}
        </app-button>
      </div>
    </div>
  </div>
</div>
